import { styles } from "@/components/quotePdf/styles";
import ThreeColumnTable from "@/components/quotePdf/Table";
import { addSpacingBetweenThousandsToNumber } from "@/utils";
import { Text } from "@react-pdf/renderer";

import type { SubscriptionDetailsForQuoteDto } from "@repos/mrp-dtos";

const WarrantySummary = ({ ...props }: SubscriptionDetailsForQuoteDto) => {
	const columns = [
		{ header: "GARANTIE", width: 25, grow: 1 },
		{ header: "SOUSCRITE", width: 15, grow: 1 },
		{ header: "NON SOUSCRITE", width: 15, grow: 1 },
		{ header: "MONTANT DE GARANTIE SITE PRINCIPAL", width: 15, grow: 1 },
	];
	if (props.insuredEstablishments.length > 1) {
		columns.push({
			header: "MONTANT DE GARANTIE SITE n°2",
			width: 15,
			grow: 1,
		});
	}
	if (props.insuredEstablishments.length > 2) {
		columns.push({
			header: "MONTANT DE GARANTIE SITE n°3",
			width: 15,
			grow: 1,
		});
	}
	return (
		<>
			<Text style={[styles.sectionHeader, styles.mt_10]}>
				SYNTHESE DES GARANTIES ET OPTIONS SOUSCRITES OU NON SOUSCRITES
			</Text>
			<ThreeColumnTable
				columns={columns}
				data={[
					{
						type: "section",
						data: "BASE",
					},
					{
						type: "row",
						data: [
							"Limite Contractuelle d'Indemnité au m²",
							"X",
							"",
							props.insuredEstablishments[0] &&
								addSpacingBetweenThousandsToNumber(
									props.insuredEstablishments[0].valuePerM2,
								) + " €",
							props.insuredEstablishments[1] &&
								addSpacingBetweenThousandsToNumber(
									props.insuredEstablishments[1].valuePerM2,
								) + " €",
							props.insuredEstablishments[2] &&
								addSpacingBetweenThousandsToNumber(
									props.insuredEstablishments[2].valuePerM2,
								) + " €",
						],
					},
					{
						type: "row",
						data: [
							"Limite Contractuelle d'Indemnité pour le bâtiment, embellissement et agencement",
							//					value = `${establishment.contractualIndemnityLimitPerSqm * establishment.surfaceArea}`;
							"X",
							"",
							props.insuredEstablishments[0] &&
								addSpacingBetweenThousandsToNumber(
									props.insuredEstablishments[0].valuePerM2 *
										props.insuredEstablishments[0].officesArea,
								) + " €",
							props.insuredEstablishments[1] &&
								addSpacingBetweenThousandsToNumber(
									props.insuredEstablishments[1].valuePerM2 *
										props.insuredEstablishments[1].officesArea,
								) + " €",
							props.insuredEstablishments[2] &&
								addSpacingBetweenThousandsToNumber(
									props.insuredEstablishments[2].valuePerM2 *
										props.insuredEstablishments[2].officesArea,
								) + " €",
						],
					},
					{
						type: "row",
						data: [
							"Incendie / Evènement climatique",
							"X",
							"",
							props.insuredEstablishments[0] &&
								addSpacingBetweenThousandsToNumber(
									props.insuredEstablishments[0].insuredStockContent +
										props.insuredEstablishments[0].professionalContent,
								) + " €",
							props.insuredEstablishments[1] &&
								addSpacingBetweenThousandsToNumber(
									props.insuredEstablishments[1].insuredStockContent +
										props.insuredEstablishments[1].professionalContent,
								) + " €",
							props.insuredEstablishments[2] &&
								addSpacingBetweenThousandsToNumber(
									props.insuredEstablishments[2].insuredStockContent +
										props.insuredEstablishments[2].professionalContent,
								) + " €",
						],
					},
					{
						type: "row",
						data: [
							"Catastrophe Naturelle et Technologique ",
							"X",
							"",
							"Voir conditions générales",
							"Voir conditions générales",
							"Voir conditions générales",
						],
					},
					{
						type: "row",
						data: [
							"Assistance",
							"X",
							"",
							"Voir conditions générales",
							"Voir conditions générales",
							"Voir conditions générales",
						],
					},
					{
						type: "section",
						data: "OPTIONS",
					},
					{
						type: "row",
						data: [
							"Dégâts des eaux",
							props.isDegatsDesEaux ? "X" : "",
							props.isDegatsDesEaux ? "" : "X",
							props.pricingDetails[0] &&
							props.relatedGuaranteesInputs[0] &&
							props.relatedGuaranteesInputs[0].waterDamage.subscribed ===
								true &&
							props.relatedGuaranteesInputs[0].waterDamage.insuredCapital
								? `${addSpacingBetweenThousandsToNumber(props.relatedGuaranteesInputs[0].waterDamage.insuredCapital)} €`
								: "-",
							props.relatedGuaranteesInputs[1] &&
							props.relatedGuaranteesInputs[1].waterDamage.subscribed ===
								true &&
							props.relatedGuaranteesInputs[1].waterDamage.insuredCapital
								? `${addSpacingBetweenThousandsToNumber(props.relatedGuaranteesInputs[1].waterDamage.insuredCapital)} €`
								: "-",
							props.relatedGuaranteesInputs[2] &&
							props.relatedGuaranteesInputs[2].waterDamage.subscribed ===
								true &&
							props.relatedGuaranteesInputs[2].waterDamage.insuredCapital
								? `${addSpacingBetweenThousandsToNumber(props.relatedGuaranteesInputs[2].waterDamage.insuredCapital)} €`
								: "-",
						],
					},
					{
						type: "row",
						data: [
							"Vol",
							//TODO: check if this is correct
							props.isVandalismeChosen ? "X" : "",
							props.isVandalismeChosen ? "" : "X",
							props.relatedGuaranteesInputs[0] &&
							props.relatedGuaranteesInputs[0].theft.subscribed === true &&
							props.relatedGuaranteesInputs[0].theft.insuredCapital
								? `${addSpacingBetweenThousandsToNumber(props.relatedGuaranteesInputs[0].theft.insuredCapital)} €`
								: "-",
							props.relatedGuaranteesInputs[1] &&
							props.relatedGuaranteesInputs[1].theft.subscribed === true &&
							props.relatedGuaranteesInputs[1].theft.insuredCapital
								? `${addSpacingBetweenThousandsToNumber(props.relatedGuaranteesInputs[1].theft.insuredCapital)} €`
								: "-",
							props.relatedGuaranteesInputs[2] &&
							props.relatedGuaranteesInputs[2].theft.subscribed === true &&
							props.relatedGuaranteesInputs[2].theft.insuredCapital
								? `${addSpacingBetweenThousandsToNumber(props.relatedGuaranteesInputs[2].theft.insuredCapital)} €`
								: "-",
						],
					},
					{
						type: "row",
						data: [
							"Bris de glace",
							props.isBrisDesGlaceChosen ? "X" : "",
							props.isBrisDesGlaceChosen ? "" : "X",
							props.relatedGuaranteesInputs[0] &&
							props.relatedGuaranteesInputs[0].glassBreakage.subscribed ===
								true &&
							props.relatedGuaranteesInputs[0].glassBreakage.insuredCapital
								? `${addSpacingBetweenThousandsToNumber(props.relatedGuaranteesInputs[0].glassBreakage.insuredCapital)} €`
								: "-",

							props.relatedGuaranteesInputs[1] &&
							props.relatedGuaranteesInputs[1].glassBreakage.subscribed ===
								true &&
							props.relatedGuaranteesInputs[1].glassBreakage.insuredCapital
								? `${addSpacingBetweenThousandsToNumber(props.relatedGuaranteesInputs[1].glassBreakage.insuredCapital)} €`
								: "-",

							props.relatedGuaranteesInputs[2] &&
							props.relatedGuaranteesInputs[2].glassBreakage.subscribed ===
								true &&
							props.relatedGuaranteesInputs[2].glassBreakage.insuredCapital
								? `${addSpacingBetweenThousandsToNumber(props.relatedGuaranteesInputs[2].glassBreakage.insuredCapital)} €`
								: "-",
						],
					},
					{
						type: "row",
						data: [
							"Dommage Electrique",
							props.isDommagesElectriquesChosen ? "X" : "",
							props.isDommagesElectriquesChosen ? "" : "X",
							props.relatedGuaranteesInputs[0] &&
							props.relatedGuaranteesInputs[0].electricalDamage.subscribed ===
								true &&
							props.relatedGuaranteesInputs[0].electricalDamage.insuredCapital
								? `${addSpacingBetweenThousandsToNumber(props.relatedGuaranteesInputs[0].electricalDamage.insuredCapital)} €`
								: "-",

							props.relatedGuaranteesInputs[1] &&
							props.relatedGuaranteesInputs[1].electricalDamage.subscribed ===
								true &&
							props.relatedGuaranteesInputs[1].electricalDamage.insuredCapital
								? `${addSpacingBetweenThousandsToNumber(props.relatedGuaranteesInputs[1].electricalDamage.insuredCapital)} €`
								: "-",
							props.relatedGuaranteesInputs[2] &&
							props.relatedGuaranteesInputs[2].electricalDamage.subscribed ===
								true &&
							props.relatedGuaranteesInputs[2].electricalDamage.insuredCapital
								? `${addSpacingBetweenThousandsToNumber(props.relatedGuaranteesInputs[2].electricalDamage.insuredCapital)} €`
								: "-",
						],
					},
					{
						type: "row",
						data: [
							"Bris de Machine",
							props.isBrisDeMachine ? "X" : "",
							props.isBrisDeMachine ? "" : "X",
							props.relatedGuaranteesInputs[0] &&
							props.relatedGuaranteesInputs[0].machineBreakage.subscribed ===
								true &&
							props.relatedGuaranteesInputs[0].machineBreakage.insuredCapital
								? `${addSpacingBetweenThousandsToNumber(props.relatedGuaranteesInputs[0].machineBreakage.insuredCapital)} €`
								: "-",
							props.relatedGuaranteesInputs[1] &&
							props.relatedGuaranteesInputs[1].machineBreakage.subscribed ===
								true &&
							props.relatedGuaranteesInputs[1].machineBreakage.insuredCapital
								? `${addSpacingBetweenThousandsToNumber(props.relatedGuaranteesInputs[1].machineBreakage.insuredCapital)} €`
								: "-",
							props.relatedGuaranteesInputs[2] &&
							props.relatedGuaranteesInputs[2].machineBreakage.subscribed ===
								true &&
							props.relatedGuaranteesInputs[2].machineBreakage.insuredCapital
								? `${addSpacingBetweenThousandsToNumber(props.relatedGuaranteesInputs[2].machineBreakage.insuredCapital)} €`
								: "-",
						],
					},
					{
						type: "row",
						data: [
							"Marchandises en Installation Frigorifique",
							props.isInstallationFrigorifiqueChosen ? "X" : "",
							props.isInstallationFrigorifiqueChosen ? "" : "X",
							props.relatedGuaranteesInputs[0] &&
							props.relatedGuaranteesInputs[0].refrigeratedGoods.subscribed ===
								true &&
							props.relatedGuaranteesInputs[0].refrigeratedGoods.insuredCapital
								? `${addSpacingBetweenThousandsToNumber(props.relatedGuaranteesInputs[0].refrigeratedGoods.insuredCapital)} €`
								: "-",

							props.relatedGuaranteesInputs[1] &&
							props.relatedGuaranteesInputs[1].refrigeratedGoods.subscribed ===
								true &&
							props.relatedGuaranteesInputs[1].refrigeratedGoods.insuredCapital
								? `${addSpacingBetweenThousandsToNumber(props.relatedGuaranteesInputs[1].refrigeratedGoods.insuredCapital)} €`
								: "-",
							props.relatedGuaranteesInputs[2] &&
							props.relatedGuaranteesInputs[2].refrigeratedGoods.subscribed ===
								true &&
							props.relatedGuaranteesInputs[2].refrigeratedGoods.insuredCapital
								? `${addSpacingBetweenThousandsToNumber(props.relatedGuaranteesInputs[2].refrigeratedGoods.insuredCapital)} €`
								: "-",
						],
					},
					{
						type: "row",
						data: [
							"Marchandises transportées",
							props.isMMTChosen ? "X" : "",
							props.isMMTChosen ? "" : "X",
							props.relatedGuaranteesInputs[0] &&
							props.relatedGuaranteesInputs[0].transportedGoods.subscribed ===
								true &&
							props.relatedGuaranteesInputs[0].transportedGoods.insuredCapital
								? `${addSpacingBetweenThousandsToNumber(props.relatedGuaranteesInputs[0].transportedGoods.insuredCapital)} €`
								: "-",
							props.relatedGuaranteesInputs[1] &&
							props.relatedGuaranteesInputs[1].transportedGoods.subscribed ===
								true &&
							props.relatedGuaranteesInputs[1].transportedGoods.insuredCapital
								? `${addSpacingBetweenThousandsToNumber(props.relatedGuaranteesInputs[1].transportedGoods.insuredCapital)} €`
								: "-",
							props.relatedGuaranteesInputs[2] &&
							props.relatedGuaranteesInputs[2].transportedGoods.subscribed ===
								true &&
							props.relatedGuaranteesInputs[2].transportedGoods.insuredCapital
								? `${addSpacingBetweenThousandsToNumber(props.relatedGuaranteesInputs[2].transportedGoods.insuredCapital)} €`
								: "-",
						],
					},
					{
						type: "row",
						data: [
							"Installation extérieure",
							props.isInstallationsExterieursChosen ? "X" : "",
							props.isInstallationsExterieursChosen ? "" : "X",

							props.relatedGuaranteesInputs[0] &&
							props.relatedGuaranteesInputs[0].externalInstallation
								.subscribed === true &&
							props.relatedGuaranteesInputs[0].externalInstallation
								.insuredCapital
								? `${addSpacingBetweenThousandsToNumber(props.relatedGuaranteesInputs[0].externalInstallation.insuredCapital)} €`
								: "-",

							props.relatedGuaranteesInputs[1] &&
							props.relatedGuaranteesInputs[1].externalInstallation
								.subscribed === true &&
							props.relatedGuaranteesInputs[1].externalInstallation
								.insuredCapital
								? `${addSpacingBetweenThousandsToNumber(props.relatedGuaranteesInputs[1].externalInstallation.insuredCapital)} €`
								: "-",

							props.relatedGuaranteesInputs[2] &&
							props.relatedGuaranteesInputs[2].externalInstallation
								.subscribed === true &&
							props.relatedGuaranteesInputs[2].externalInstallation
								.insuredCapital
								? `${addSpacingBetweenThousandsToNumber(props.relatedGuaranteesInputs[2].externalInstallation.insuredCapital)} €`
								: "-",
						],
					},
					{
						type: "row",
						data: [
							"Salon / foires / Manifestations",
							props.isSFMChosen ? "X" : "",
							props.isSFMChosen ? "" : "X",
							props.relatedGuaranteesInputs[0] &&
							props.relatedGuaranteesInputs[0].fairsAndEvents.subscribed ===
								true
								? "6 fois l'indice *"
								: "-",
							props.relatedGuaranteesInputs[1] &&
							props.relatedGuaranteesInputs[1].fairsAndEvents.subscribed ===
								true
								? "6 fois l'indice *"
								: "-",
							props.relatedGuaranteesInputs[2] &&
							props.relatedGuaranteesInputs[2].fairsAndEvents.subscribed ===
								true
								? "6 fois l'indice *"
								: "-",
						],
					},
					{
						type: "row",
						data: [
							"Effondrement",
							props.isEffondrementChosen ? "X" : "",
							props.isEffondrementChosen ? "" : "X",
							props.relatedGuaranteesInputs[0] &&
							props.relatedGuaranteesInputs[0].collapse.subscribed === true
								? "1 500 000 € Maximum"
								: "Néant",
							props.relatedGuaranteesInputs[1] &&
							props.relatedGuaranteesInputs[1].collapse.subscribed === true
								? "1 500 000 € Maximum"
								: "Néant",
							props.relatedGuaranteesInputs[2] &&
							props.relatedGuaranteesInputs[2].collapse.subscribed === true
								? "1 500 000 € Maximum"
								: "Néant",
						],
					},
					{
						type: "section",
						data: "OPTIONS PERTES D’ACTIVITE",
					},
					{
						type: "row",
						data: [
							"Perte exploitation incendie : évènement climatique ",
							props.isEvenementClimatiquesChosen ? "X" : "",
							props.isEvenementClimatiquesChosen ? "" : "X",
							props.relatedGuaranteesInputs[0] &&
							props.relatedGuaranteesInputs[0].businessInterruptionFire
								.subscribed === true
								? "24 mois et 120 % du C.A maximum"
								: "Néant",
							props.relatedGuaranteesInputs[1] &&
							props.relatedGuaranteesInputs[1].businessInterruptionFire
								.subscribed === true
								? "24 mois et 120 % du C.A maximum"
								: "Néant",
							props.relatedGuaranteesInputs[2] &&
							props.relatedGuaranteesInputs[2].businessInterruptionFire
								.subscribed === true
								? "24 mois et 120 % du C.A maximum"
								: "Néant",
						],
					},
					{
						type: "row",
						data: [
							"Perte exploitation vol",
							props.isPerteExpVolChosen ? "X" : "",
							props.isPerteExpVolChosen ? "" : "X",
							props.relatedGuaranteesInputs[0] &&
							props.relatedGuaranteesInputs[0].businessInterruptionTheft
								.subscribed === true
								? "24 mois et 120 % du C.A maximum"
								: "Néant",
							props.relatedGuaranteesInputs[1] &&
							props.relatedGuaranteesInputs[1].businessInterruptionTheft
								.subscribed === true
								? "24 mois et 120 % du C.A maximum"
								: "Néant",
							props.relatedGuaranteesInputs[2] &&
							props.relatedGuaranteesInputs[2].businessInterruptionTheft
								.subscribed === true
								? "24 mois et 120 % du C.A maximum"
								: "Néant",
						],
					},
					{
						type: "section",
						data: [],
					},
					{
						type: "row",
						data: [
							"Perte de valeur vénale du fonds de commerce",
							props.isPerteValeurChosen ? "X" : "",
							props.isPerteValeurChosen ? "" : "X",
							props.relatedGuaranteesInputs[0] &&
							props.relatedGuaranteesInputs[0].lossOfCommercialValue
								.subscribed === true
								? "120 % du C.A du dernier exercice maximum"
								: "Néant",
							props.relatedGuaranteesInputs[1] &&
							props.relatedGuaranteesInputs[1].lossOfCommercialValue
								.subscribed === true
								? "120 % du C.A du dernier exercice maximum"
								: "Néant",
							props.relatedGuaranteesInputs[2] &&
							props.relatedGuaranteesInputs[2].lossOfCommercialValue
								.subscribed === true
								? "120 % du C.A du dernier exercice maximum"
								: "Néant",
						],
					},
					{
						type: "section",
						data: "OPTIONS RESPONSABILITE CIVILE – DEFENSE – PROTECTION JURIDIQUE",
					},
					{
						type: "row",
						data: [
							"Responsabilité Civile Exploitation",
							props.isRCEChosen ? "X" : "",
							props.isRCEChosen ? "" : "X",
							props.relatedGuaranteesInputs[0] &&
							props.relatedGuaranteesInputs[0].civilLiabilityOperational
								.subscribed === true
								? "Voir conditions générales"
								: "Néant",
							props.relatedGuaranteesInputs[1] &&
							props.relatedGuaranteesInputs[1].civilLiabilityOperational
								.subscribed === true
								? "Voir conditions générales"
								: "Néant",
							props.relatedGuaranteesInputs[2] &&
							props.relatedGuaranteesInputs[2].civilLiabilityOperational
								.subscribed === true
								? "Voir conditions générales"
								: "Néant",
						],
					},
					{
						type: "row",
						data: [
							"Responsabilité Civile Professionnelle",
							props.isRCPChosen ? "X" : "",
							props.isRCPChosen ? "" : "X",
							props.relatedGuaranteesInputs[0] &&
							props.relatedGuaranteesInputs[0].civilLiabilityProfessional
								.subscribed === true
								? "Voir conditions générales"
								: "Néant",
							props.relatedGuaranteesInputs[1] &&
							props.relatedGuaranteesInputs[1].civilLiabilityProfessional
								.subscribed === true
								? "Voir conditions générales"
								: "Néant",
							props.relatedGuaranteesInputs[2] &&
							props.relatedGuaranteesInputs[2].civilLiabilityProfessional
								.subscribed === true
								? "Voir conditions générales"
								: "Néant",
						],
					},
					//TODO: change this
					props.isRCPChosen || props.isRCEChosen
						? {
								type: "row",
								data: [
									"Défense Pénale et Recours",
									props.isRCPChosen || props.isRCEChosen ? "X" : "",
									props.isRCPChosen || props.isRCEChosen ? "" : "X",
									props.relatedGuaranteesInputs[0] &&
									(props.relatedGuaranteesInputs[0].civilLiabilityProfessional
										.subscribed ||
										props.relatedGuaranteesInputs[0].civilLiabilityOperational
											.subscribed)
										? "Voir conditions générales"
										: "Néant",
									props.relatedGuaranteesInputs[1] &&
									(props.relatedGuaranteesInputs[1].civilLiabilityProfessional
										.subscribed ||
										props.relatedGuaranteesInputs[1].civilLiabilityOperational
											.subscribed)
										? "Voir conditions générales"
										: "Néant",
									props.relatedGuaranteesInputs[2] &&
									(props.relatedGuaranteesInputs[2].civilLiabilityProfessional
										.subscribed ||
										props.relatedGuaranteesInputs[2].civilLiabilityOperational
											.subscribed)
										? "Voir conditions générales"
										: "Néant",
								],
							}
						: {
								type: "row",
								data: [],
							},
					{
						type: "row",
						data: [
							"Protection Juridique",
							props.isPJChosen ? "X" : "",
							props.isPJChosen ? "" : "X",
							props.relatedGuaranteesInputs[0] &&
							props.relatedGuaranteesInputs[0].legalProtection.subscribed ===
								true
								? "Voir conditions générales Protection Juridique"
								: "Néant",
							props.relatedGuaranteesInputs[1] &&
							props.relatedGuaranteesInputs[1].legalProtection.subscribed ===
								true
								? "Voir conditions générales Protection Juridique"
								: "Néant",
							props.relatedGuaranteesInputs[2] &&
							props.relatedGuaranteesInputs[2].legalProtection.subscribed ===
								true
								? "Voir conditions générales Protection Juridique"
								: "Néant",
						],
					},
				]}
			/>
		</>
	);
};

export default WarrantySummary;
