import type { TableData } from "@/components/quotePdf/Table";
import { useMemo } from "react";
import { styles } from "@/components/quotePdf/styles";
import ThreeColumnTable from "@/components/quotePdf/Table";
import {
	addSpacingBetweenThousandsToNumberWithDecimals,
	addSpacingBetweenThousandsToString,
} from "@/utils";
import { Text } from "@react-pdf/renderer";
import { addMonths, formatDate, subDays } from "date-fns";

import type { SubscriptionDetailsForQuoteDto } from "@repos/mrp-dtos";

const Subscription = ({ ...props }: SubscriptionDetailsForQuoteDto) => {
	// const splittingOptionDivisor = useMemo(() => {
	// 	switch (props.splittingOption) {
	// 		case "Monthly":
	// 			return 12;
	// 		case "Quarterly":
	// 			return 4;
	// 		case "HalfYearly":
	// 			return 2;
	// 		case "Yearly":
	// 			return 1;
	// 		default:
	// 			return 12;
	// 	}
	// }, [props.splittingOption]);
	const splittingOptionTableData: TableData = useMemo(() => {
		switch (props.splittingOption) {
			case "Yearly":
				return [
					{
						type: "row",
						data: [
							`${formatDate(props.effectiveDate, "dd/MM/yyyy")} - ${formatDate(
								subDays(props.dueDate, 1),
								"dd/MM/yyyy",
							)}`,
							formatDate(props.effectiveDate, "dd/MM/yyyy"),
							`${addSpacingBetweenThousandsToNumberWithDecimals(props.firstWithdrawal)} €`,
						],
					},
				];
			case "Monthly":
				return Array.from({ length: 12 }, (_, i) => {
					const effectiveDate = new Date(props.effectiveDate);
					const startDate = addMonths(effectiveDate, i);
					const dueDate = addMonths(effectiveDate, i + 1);
					const price =
						i === 0 ? props.firstWithdrawal : props.otherWithdrawals;
					return {
						type: "row",
						data: [
							`${formatDate(startDate, "dd/MM/yyyy")} - ${formatDate(subDays(dueDate, 1), "dd/MM/yyyy")}`,
							formatDate(startDate, "dd/MM/yyyy"),
							`${price.toFixed(2)} €`,
						],
					};
				});
			case "Quarterly":
				return Array.from({ length: 4 }, (_, i) => {
					const effectiveDate = new Date(props.effectiveDate);
					const startDate = addMonths(effectiveDate, i * 3);
					const dueDate = addMonths(effectiveDate, (i + 1) * 3);
					const price =
						i === 0 ? props.firstWithdrawal : props.otherWithdrawals;
					return {
						type: "row",
						data: [
							`${formatDate(startDate, "dd/MM/yyyy")} - ${formatDate(subDays(dueDate, 1), "dd/MM/yyyy")}`,
							formatDate(startDate, "dd/MM/yyyy"),
							`${price.toFixed(2)} €`,
						],
					};
				});
			case "HalfYearly":
				return Array.from({ length: 2 }, (_, i) => {
					const effectiveDate = new Date(props.effectiveDate);
					const startDate = addMonths(effectiveDate, i * 6);
					const dueDate = addMonths(effectiveDate, (i + 1) * 6);
					const price =
						i === 0 ? props.firstWithdrawal : props.otherWithdrawals;
					return {
						type: "row",
						data: [
							`${formatDate(startDate, "dd/MM/yyyy")} - ${formatDate(subDays(dueDate, 1), "dd/MM/yyyy")}`,
							formatDate(startDate, "dd/MM/yyyy"),
							`${price.toFixed(2)} €`,
						],
					};
				});
		}
	}, []);
	return (
		<>
			<Text style={[styles.sectionHeader, styles.mt_10]}>VOTRE COTISATION</Text>
			<Text style={[styles.bodyText, styles.mb_10]}>
				Indice FFB au jour de la souscription :{" "}
				{addSpacingBetweenThousandsToNumberWithDecimals(props.ffbIndex)}
			</Text>
			<Text style={[styles.bodyText, styles.mb_10]}>
				La base annuelle de votre cotisation est fixée à partir des déclarations
				contractuelles reprises ci-dessus :
			</Text>
			<Text style={[styles.bodyText, styles.mb_10]}>
				Cotisation due pour la période du{" "}
				{formatDate(props.effectiveDate, "dd/MM/yyyy")} au{" "}
				{formatDate(subDays(props.dueDate, 1), "dd/MM/yyyy")}
			</Text>
			<Text style={[styles.bodyText, styles.mb_10]}>
				- Cotisation Annuelle :{" "}
				{addSpacingBetweenThousandsToNumberWithDecimals(
					props.totalInsurancePremium +
						props.managementFees +
						props.releaseFees,
				)}{" "}
				€ TTC
			</Text>
			{props.splittingOption !== "Yearly" && (
				<Text style={[styles.bodyText, styles.mb_10]}>
					- Cotisation{" "}
					{props.splittingOption === "Monthly"
						? "Mensuelle"
						: props.splittingOption === "Quarterly"
							? "Trimestrielle"
							: props.splittingOption === "HalfYearly"
								? "Semestrielle"
								: "Annuelle"}
					:{" "}
					{addSpacingBetweenThousandsToNumberWithDecimals(
						props.otherWithdrawals,
					)}{" "}
					€ TTC
				</Text>
			)}
			{props.fileFees !== 0 && (
				<Text style={[styles.bodyText, styles.mb_10]}>
					Les frais de dossier souscription courtier de{" "}
					{addSpacingBetweenThousandsToString(props.fileFees.toFixed(2))} €
					seront portés dans leur intégralité sur votre premier prélèvement.
				</Text>
			)}

			<ThreeColumnTable
				columns={[
					{ header: "Cotisations", width: 70, grow: 1, style: styles.bgGray },
					{ header: "Annuel", width: 30, grow: 1, style: styles.bgGray },
				]}
				data={[
					{
						type: "row",
						data: [
							"PRIME HT",
							<Text key="5-3">
								{addSpacingBetweenThousandsToString(
									props.beforeTaxPremium.toFixed(2),
								)}{" "}
								€
							</Text>,
						],
						style: styles.bgGray,
					},
					{
						type: "row",
						data: [
							"PRIME TTC",
							<Text key="5-3">
								{addSpacingBetweenThousandsToNumberWithDecimals(
									props.totalInsurancePremium +
										props.managementFees +
										props.releaseFees,
								)}{" "}
								€
							</Text>,
						],
						style: styles.bgGray,
					},

					{
						type: "row",
						data: [
							"Dont CATASTROPHE NATURELLE",
							<Text key="5-3">
								{addSpacingBetweenThousandsToString(
									props.catastropheNaturellePremium.toFixed(2),
								)}{" "}
								€
							</Text>,
						],
						style: styles.bgGray,
					},
					{
						type: "row",
						data: [
							"Dont ASSISTANCE",
							<Text key="5-3">
								{addSpacingBetweenThousandsToString(
									props.assistancePremium.toFixed(2),
								)}{" "}
								€
							</Text>,
						],
						style: styles.bgGray,
					},
					{
						type: "row",
						data: [
							"Dont TAXE ASSISTANCE",
							<Text key="5-3">
								{addSpacingBetweenThousandsToString(
									props.assistanceTaxes.toFixed(2),
								)}{" "}
								€
							</Text>,
						],
						style: styles.bgGray,
					},
					{
						type: "row",
						data: [
							"Dont PROTECTION JURIDIQUE",
							<Text key="5-3">
								{addSpacingBetweenThousandsToString(props.pjPremium.toFixed(2))}{" "}
								€
							</Text>,
						],
						style: styles.bgGray,
					},
					{
						type: "row",
						data: [
							"Dont TAXE PROTECTION JURIDIQUE",
							<Text key="5-3">
								{addSpacingBetweenThousandsToString(props.pjTaxes.toFixed(2))} €
							</Text>,
						],
						style: styles.bgGray,
					},
					{
						type: "row",
						data: [
							"Dont FRAIS DE QUITTANCEMENT",
							<Text key="5-3">
								{addSpacingBetweenThousandsToString(
									props.releaseFees.toFixed(2),
								)}{" "}
								€
							</Text>,
						],
						style: styles.bgGray,
					},
					{
						type: "row",
						data: [
							"Dont FRAIS DE GESTION",
							<Text key="5-3">
								{addSpacingBetweenThousandsToString(
									props.managementFees.toFixed(2),
								)}{" "}
								€
							</Text>,
						],
						style: styles.bgGray,
					},
					{
						type: "row",
						data: [
							"Dont Taxes",
							<Text key="5-3">
								{addSpacingBetweenThousandsToString(props.taxes.toFixed(2))} €
							</Text>,
						],
						style: styles.bgGray,
					},
					{
						type: "row",
						data: [
							"Dont CONTRIBUTION ATTENTAT",
							<Text key="5-3">
								{addSpacingBetweenThousandsToString(
									props.attackPackage.toFixed(2),
								)}{" "}
								€
							</Text>,
						],
						style: styles.bgGray,
					},
					{
						type: "row",
						data: [
							"FRAIS DE DOSSIER SOUSCRIPTION COURTIER",
							<Text key="5-3">
								{addSpacingBetweenThousandsToString(props.fileFees.toFixed(2))}{" "}
								€
							</Text>,
						],
						style: styles.bgGray,
					},
				]}
			/>
			<Text style={[styles.bodyText, styles.mb_10]} break>
				<Text style={styles.bold}>Montant de votre 1ère échéance: </Text>
				{addSpacingBetweenThousandsToString(props.firstWithdrawal.toFixed(2))} €
			</Text>

			<Text style={[styles.bodyText, styles.mb_10]}>
				<Text style={styles.bold}>Montant des échéances :</Text>{" "}
			</Text>
			<ThreeColumnTable
				columns={[
					{
						header: "Périodes d'assurance",
						width: 25,
						grow: 1,
						style: styles.bgGray,
					},
					{
						header: "Dates des Prélèvements",
						width: 25,
						grow: 1,
						style: styles.bgGray,
					},
					{
						header: "Montant des prélèvements",
						width: 25,
						grow: 1,
						style: styles.bgGray,
					},
				]}
				data={splittingOptionTableData}
			/>
			<Text style={[styles.bodyText, styles.mb_10]}>
				<Text style={styles.bold}>Echéance principale :</Text>{" "}
				{formatDate(props.dueDate, "dd/MM")} de chaque année.
			</Text>
			<Text style={[styles.bodyText, styles.mb_10]}>
				La cotisation doit être réglée conformément à l’échéancier joint et doit
				être payée dans les dix jours suivant son échéance.{" "}
				<Text style={styles.bold}>
					En cas de non-paiement, il est fait application des dispositions de
					l’article L 113-3 du Code des Assurances et procédé au
				</Text>{" "}
				recouvrement contentieux de la cotisation et des frais engagés du fait
				du non-paiement.
			</Text>
		</>
	);
};

export default Subscription;
