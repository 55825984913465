import type { ReactNode } from "react";
import type { FieldValues, UseControllerProps } from "react-hook-form";
import {
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "@/components/ui/form.tsx";
import { Input } from "@/components/ui/input.tsx";
import { cn } from "@/utils";
import { useFormContext } from "react-hook-form";

interface TextInputProps<T extends FieldValues> extends UseControllerProps<T> {
	label: string;
	className?: string;
	inputClassName?: string;
	type?: HTMLInputElement["type"];
	endAdornment?: ReactNode;
	required?: boolean;
	onChange?: (value: string) => void;
	placeholder?: string;
	hidden?: boolean;
}

export function TextInput<T extends FieldValues>({
	label,
	className,
	inputClassName,
	type = "text",
	name,
	endAdornment,
	disabled,
	required,
	onChange,
	placeholder,
	hidden = false,
	...props
}: TextInputProps<T>) {
	const { control } = useFormContext<T>();
	return (
		<FormField
			name={name}
			control={control}
			{...props}
			render={({ field }) => (
				<FormItem
					className={cn("flex w-full flex-col", hidden && "hidden", className)}
				>
					<FormLabel className="flex items-end text-sm font-medium text-[#333333]">
						<span>{label}</span>{" "}
						{required && (
							<span className="cursor-pointer text-red-500">&nbsp;*</span>
						)}
					</FormLabel>
					<FormControl className="flex justify-between">
						<Input
							disabled={disabled}
							{...field}
							type={type}
							className={cn(
								!field.value && "text-muted-foreground",
								inputClassName,
							)}
							onChange={(e) => {
								type === "number"
									? field.onChange(parseFloat(e.target.value))
									: field.onChange(e.target.value);
								onChange?.(e.target.value);
							}}
							endAdornment={endAdornment}
							placeholder={placeholder}
						/>
					</FormControl>
					<FormMessage />
				</FormItem>
			)}
		/>
	);
}
