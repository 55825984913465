import type { ClaimPageDto } from "@/pages/form/schemas";
import { useState } from "react";
import { ContactModal } from "@/components/ContactModal.tsx";
import { useCustomFormNavigate } from "@/hooks/customNavigate.ts";
import { useClearForm } from "@/hooks/useClearForm";
import { claimsPageSchema } from "@/pages/form/schemas";
import { formStore } from "@/pages/form/stores/formStore.ts";
import { onKeyDownPreventDefaultIfEnter } from "@/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormProvider, useForm } from "react-hook-form";
import { redirect } from "react-router-dom";

import type { NumberOfClaimsInLast3YearsDto } from "@repos/mrp-dtos";

import { ClaimComponent } from "../../components/ClaimComponent";

const mapNumberOfClaims = (numberOfClaims: NumberOfClaimsInLast3YearsDto) => {
	switch (numberOfClaims) {
		case "ONE":
			return 1;
		case "TWO":
			return 2;
		case "THREE":
			return 3;
		default:
			return redirect("/");
	}
};

export const ClaimsPage = () => {
	const [isDialogOpen, setIsDialogOpen] = useState(false);
	const [pendingNavigation, setPendingNavigation] = useState(false);

	const { claimPage, setClaimPage, numberOfClaimsPage } = formStore(
		(state) => ({
			claimPage: state.claimPage,
			setClaimPage: state.setClaimPage,
			numberOfClaimsPage: state.numberOfClaimsPage,
		}),
	);
	// const { setNextButtonDisabled } = useContext(LayoutButtonsContext);
	const navigate = useCustomFormNavigate();
	const { clearForm } = useClearForm();
	const methods = useForm<ClaimPageDto>({
		resolver: zodResolver(claimsPageSchema),
		defaultValues: {
			...claimPage,
		},
	});

	const checkCases = (claims: ClaimPageDto["claimsArray"]) => {
		if (numberOfClaimsPage.numberOfClaims === "THREE") {
			// Case 1: 3 claims and the same establishment
			const isSameEstablishmentForAllClaims = claims.every(
				(claim) =>
					claim.establishmentLinkedToClaim ===
					claims[0]?.establishmentLinkedToClaim,
			);

			if (isSameEstablishmentForAllClaims) {
				setIsDialogOpen(true);
				setPendingNavigation(true);
				return;
			}
			// Case 2: Same nature for all claims and same establishment
			const isAllClaimsHaveSameNature =
				new Set(claims.map((claim) => claim.natureOfClaim)).size === 1;

			if (isAllClaimsHaveSameNature) {
				setIsDialogOpen(true);
				setPendingNavigation(true);
				return;
			}
			navigate("OldDeclarationsPage");
		} else {
			navigate("OldDeclarationsPage");
		}
	};

	const handleModalConfirm = () => {
		setIsDialogOpen(false);
		if (pendingNavigation) {
			clearForm();
			navigate("SirenInputPage");
			setPendingNavigation(false);
		}
	};

	const onSubmit = (data: ClaimPageDto) => {
		setClaimPage(data);
		// navigate("OldDeclarationsPage");
		checkCases(data.claimsArray);
	};

	const numberOfClaims = numberOfClaimsPage.numberOfClaims
		? mapNumberOfClaims(numberOfClaimsPage.numberOfClaims)
		: 0;

	return (
		<FormProvider {...methods}>
			<form
				onSubmit={methods.handleSubmit(onSubmit)}
				className="flex w-full flex-col gap-12"
				id="form"
				onKeyDown={onKeyDownPreventDefaultIfEnter}
			>
				<div className="flex items-center justify-between">
					<h1>Déclaration des sinistres</h1>
				</div>
				<div className="relative h-fit w-full gap-8">
					{Array.from({ length: numberOfClaims as number }, (_, index) => (
						<ClaimComponent key={index} index={index} />
					))}
				</div>

				<ContactModal
					isOpen={isDialogOpen}
					onClose={() => setIsDialogOpen(false)}
					description="Nous ne sommes malheureusement pas en mesure de vous proposer une couverture adaptée aux besoins de votre client. Veuillez envoyer votre demande à notre adresse e-mail."
					onConfirm={handleModalConfirm}
				/>
			</form>
		</FormProvider>
	);
};
