import type { FieldError } from "react-hook-form";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { CustomSpinner } from "@/components/CustomSpinner";
import { SelectInput } from "@/components/inputs/SelectInput";
import { TextInput } from "@/components/inputs/TextInput";
import { Button } from "@/components/ui/button";
import { useCustomFormNavigate } from "@/hooks/customNavigate";
import { formStore } from "@/pages/form/stores/formStore";
import { onKeyDownPreventDefaultIfEnter, trpc } from "@/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormProvider, useForm } from "react-hook-form";
import { z } from "zod";

import type { LocalStatusDto } from "@repos/mrp-dtos";

export const OLD_DECLARATION_OPTIONS_ELECTRIC: {
	label: string;
	value: LocalStatusDto;
}[] = [
	{ label: "Qualifié", value: "qualified" },
	{
		label: "Certifié avec délivrance Q18",
		value: "certifiedWithDeliveryOfCertificate",
	},
	{ label: "Aucune vérification", value: "notCertified" },
];

export const OLD_DECLARATION_OPTIONS_EXTINCTEUR: {
	label: string;
	value: LocalStatusDto;
}[] = [
	{ label: "Qualifié", value: "qualified" },
	{
		label: "Certifié avec délivrance Q4",
		value: "certifiedWithDeliveryOfCertificate",
	},
	{ label: "Aucune vérification", value: "notCertified" },
];

export const OldDeclarationsPage = () => {
	const {
		oldDeclarations,
		setOldDeclarations,
		insuredEstablishments,
		mainEstablishments,
	} = formStore((state) => ({
		oldDeclarations: state.oldDeclarations,
		setOldDeclarations: state.setOldDeclarations,
		insuredEstablishments: state.establishments.insuredEstablishments,
		mainEstablishments: state.establishments.mainEstablishment,
	}));

	const navigate = useCustomFormNavigate();
	const [isInsured, setIsInsured] = useState<boolean | null>(false);

	const postalCodes = mainEstablishments?.postalCode
		? [mainEstablishments.postalCode]
		: [];

	const { data: declarationsData, isLoading } =
		trpc.config.listDeclarations.useQuery({
			postalCodes,
		});

	const isInsuredDeclaration = useMemo(
		() =>
			declarationsData?.RecentSubscription?.find(
				(d) => d.label === "L’entreprise est-elle déjà assurée?",
			),
		[declarationsData],
	);

	const companyNameDeclaration = useMemo(
		() =>
			declarationsData?.RecentSubscription?.find(
				(d) => d.label === "Nom de la compagnie",
			),
		[declarationsData],
	);

	const contractNumberDeclaration = useMemo(
		() =>
			declarationsData?.RecentSubscription?.find(
				(d) => d.label === "Numéro du contrat",
			),
		[declarationsData],
	);

	const firePreventionDeclarations = useMemo(
		() =>
			declarationsData?.RecentSubscription?.filter(
				(d) => d.label.includes("installation") && d.label.includes("vérifiée"),
			) || [],
		[declarationsData],
	);

	const createValidationSchema = useCallback(
		(isInsured: boolean | null) => {
			const baseSchema = z.object({
				declarationsArray: z
					.object({
						insuranceStatus: z.boolean({
							required_error: "Veuillez sélectionner une option.",
						}),
						...firePreventionDeclarations.reduce((acc, curr) => {
							acc[curr.id] = z.string({
								required_error: `Veuillez sélectionner une option pour "${curr.label}."`,
							});
							return acc;
						}, {}),
					})
					.catchall(z.any()),
			});

			if (isInsured === true) {
				// When "Oui" is selected, all fields are required
				return z.object({
					declarationsArray: z
						.object({
							insuranceStatus: z.boolean({
								required_error: "Veuillez sélectionner une option.",
							}),
							[companyNameDeclaration?.id || ""]: z
								.string({
									required_error: "Veuillez saisir le nom de la compagnie.",
								})
								.min(2, "Veuillez saisir un nom valide.")
								//should not contain only numbers
								.regex(/^(?!\d+$).*/, {
									message: "Veuillez saisir un nom valide.",
								})
								//should not contain special characters
								.regex(
									/^[a-zA-Z0-9àáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžæÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u,
									"Veuillez saisir un nom valide.",
								)
								//company name should contain more than 2 characters excluding spaces
								.refine(
									(val) => {
										return val.replace(/\s/g, "").length >= 2;
									},
									{
										message: "Veuillez saisir un nom valide.",
									},
								),
							[contractNumberDeclaration?.id || ""]: z
								.string({
									required_error:
										"Veuillez saisir le numéro du contrat de l'ancienne souscription.",
								})
								.min(
									1,
									"Veuillez saisir le numéro du contrat de l'ancienne souscription.",
								)
								.regex(/^[a-zA-Z0-9]*$/, {
									message: "Veuillez saisir un numéro de contrat valide.",
								}),

							...firePreventionDeclarations.reduce((acc, curr) => {
								acc[curr.id] = z.string({
									required_error: `Veuillez sélectionner une option pour ${curr.label}`,
								});
								return acc;
							}, {}),
						})
						.catchall(
							z
								.string({
									required_error: "Ce champ est obligatoire.",
								})
								.min(1, "Ce champ est obligatoire."),
						),
				});
			} else {
				// When "Non" is selected, no fields are required
				return baseSchema;
			}
		},
		[
			companyNameDeclaration,
			contractNumberDeclaration,
			firePreventionDeclarations,
		],
	);
	const validationSchema = React.useMemo(
		() => createValidationSchema(isInsured),
		[createValidationSchema, isInsured],
	);

	const methods = useForm<(typeof validationSchema)["_input"]>({
		resolver: zodResolver(validationSchema),
		defaultValues: React.useMemo(
			() => ({
				declarationsArray: {
					...oldDeclarations.declarationsArray?.reduce(
						(acc, curr) => ({
							...acc,
							[curr.declarationId]: curr.answer,
						}),
						{},
					),
					insuranceStatus: isInsured,
				},
			}),
			[oldDeclarations, isInsured],
		),
	});

	useEffect(() => {
		if (!insuredEstablishments) {
			navigate("SirenInputPage");
		}
	}, [insuredEstablishments, navigate]);

	useEffect(() => {
		if (oldDeclarations) {
			if (isInsuredDeclaration) {
				const insuranceDeclaration = oldDeclarations.declarationsArray?.find(
					(d) => d.declarationId === "insuranceStatus",
				);

				if (insuranceDeclaration) {
					const storedValue = Boolean(insuranceDeclaration.answer);
					setIsInsured(storedValue);
					methods.setValue("declarationsArray.insuranceStatus", storedValue);
				} else {
					setIsInsured(false);
				}
			} else {
				setIsInsured(false);
			}
		} else {
			setIsInsured(false);
		}
	}, [oldDeclarations, isInsuredDeclaration, methods]);

	const handleInsuranceChange = (value: boolean) => {
		setIsInsured(value);
		methods.setValue("declarationsArray.insuranceStatus", value);

		if (!value) {
			// Clear other fields when selecting "Non"
			methods.reset({
				declarationsArray: {
					insuranceStatus: value,
				},
			});
		}
	};

	const onSubmit = (data) => {
		const transformedData = {
			declarationsArray: [
				{
					declarationId: "insuranceStatus",
					answer: data.declarationsArray.insuranceStatus,
				},
				...Object.entries(data.declarationsArray)
					.filter(([key, value]) => key !== "insuranceStatus" && value !== "")
					.map(([key, value]) => ({
						declarationId: key,
						answer: value,
					})),
			],
		};

		setOldDeclarations(transformedData);
		navigate("DeclarationsPage");
	};

	if (isLoading) {
		return <CustomSpinner />;
	}

	return (
		<FormProvider {...methods}>
			<form
				onSubmit={methods.handleSubmit(onSubmit)}
				className="flex w-full flex-col gap-12"
				id="form"
				onKeyDown={onKeyDownPreventDefaultIfEnter}
			>
				<div className="flex items-center justify-between">
					<h1>Déclarations</h1>
				</div>

				<section className="mb-4 w-full overflow-hidden rounded-lg border border-gray-200">
					<div className="flex items-center justify-between border-b border-[#E4E4E4] bg-gray-50 p-4">
						<h2 className="text-xl font-semibold">Anciennes souscriptions</h2>
					</div>

					<div className="space-y-4 border-b border-[#E4E4E4] p-4">
						<div className="flex flex-row items-center justify-between">
							<p className="text-gray-700">{isInsuredDeclaration?.label}</p>
							<div className="flex gap-2">
								<Button
									type="button"
									onClick={() => handleInsuranceChange(true)}
									variant={isInsured === true ? "default" : "secondary"}
									className="w-20"
								>
									Oui
								</Button>
								<Button
									type="button"
									onClick={() => handleInsuranceChange(false)}
									variant={isInsured === false ? "default" : "secondary"}
									className="w-20"
								>
									Non
								</Button>
							</div>
						</div>
						{methods.formState.errors.declarationsArray?.insuranceStatus && (
							<p className="text-sm text-red-500">
								{
									(
										methods.formState.errors.declarationsArray
											.insuranceStatus as unknown as FieldError
									).message
								}
							</p>
						)}
					</div>

					{isInsured && (
						<div className="space-y-4 p-4">
							<div className="grid grid-cols-1 gap-4 md:grid-cols-2">
								{companyNameDeclaration && (
									<TextInput<(typeof validationSchema)["_input"]>
										label={companyNameDeclaration.label}
										name={`declarationsArray.${companyNameDeclaration.id}`}
										required={isInsured as boolean}
									/>
								)}
								{contractNumberDeclaration && (
									<TextInput<(typeof validationSchema)["_input"]>
										label={contractNumberDeclaration.label}
										name={`declarationsArray.${contractNumberDeclaration.id}`}
										required={isInsured as boolean}
									/>
								)}
							</div>
						</div>
					)}
				</section>

				<section className="mb-4 w-full overflow-hidden rounded-lg border border-gray-200">
					<div className="flex items-center justify-between border-b border-[#E4E4E4] bg-gray-50 p-4">
						<h2 className="text-xl font-semibold">Prévention incendie</h2>
					</div>
					<div className="space-y-4 p-4">
						<div className="grid grid-cols-1 gap-4 md:grid-cols-2">
							{firePreventionDeclarations.map((declaration) => (
								<SelectInput<
									(typeof validationSchema)["_input"]["declarationsArray"],
									{
										value: LocalStatusDto;
										label: string;
									}
								>
									key={declaration.id}
									label={declaration.label}
									name={`declarationsArray.${declaration.id}`}
									options={
										declaration.label ===
										"L’installation d'extincteur est-elle vérifiée par un professionnel ?"
											? OLD_DECLARATION_OPTIONS_EXTINCTEUR
											: OLD_DECLARATION_OPTIONS_ELECTRIC
									}
									required={false}
								/>
							))}
						</div>
					</div>
				</section>
			</form>
		</FormProvider>
	);
};

export default OldDeclarationsPage;
