import { styles } from "@/components/quotePdf/styles";
import ThreeColumnTable from "@/components/quotePdf/Table";
import { addSpacingBetweenThousandsToString } from "@/utils";
import { StyleSheet, Text, View } from "@react-pdf/renderer";

import type { SubscriptionDetailsForQuoteDto } from "@repos/mrp-dtos";

const localStyles = StyleSheet.create({
	tableHeader: {
		color: "red",
		fontSize: 14,
		textAlign: "center",
		border: "1px solid black",
		padding: 5,
		borderBottomWidth: 0,
	},
});
const LiabilityInsurances = ({ ...props }: SubscriptionDetailsForQuoteDto) => {
	if (!props.isRCEChosen && !props.isRCPChosen) {
		return null;
	}
	return (
		<View break>
			<Text style={[styles.bold, localStyles.tableHeader]}>
				Assurances de la responsabilité civile
			</Text>
			<ThreeColumnTable
				columns={[
					{ header: "Garanties", width: 15, grow: 1 },
					{
						header: "Limites d’indemnisation",
						width: 55,
						grow: 2,
					},
					{ header: "Franchise", width: 30, grow: 1 },
				]}
				data={[
					{
						type: "row",
						data: [
							"Toutes garanties responsabilité civile",
							<View
								key="1-3"
								style={{ display: "flex", flexDirection: "column", gap: 10 }}
							>
								<Text>POUR TOUS DOMMAGES SANS POUVOIR EXCÉDER</Text>
								<Text>
									Sur dommages corporels*, matériels* et immatériels* confondus:
									5 000 000 € NON-INDEXÉS par sinistre toutes responsabilités
									confondues
								</Text>
							</View>,
							<View
								key="1-3"
								style={{ display: "flex", flexDirection: "column", gap: 10 }}
							>
								<View>
									<Text style={styles.bold}>FRANCHISE GÉNÉRALE : </Text>
									<Text>
										{addSpacingBetweenThousandsToString(
											Number(props.deductible).toString(),
										)}
										{" €"}
									</Text>
								</View>
							</View>,
						],
					},
					{
						type: "row",
						data: [
							"Toutes garanties responsabilité civile",
							<View
								key="1-3"
								style={{ display: "flex", flexDirection: "column", gap: 10 }}
							>
								<Text>
									Pour dommages matériels et immatériels confondus : 2 500 fois
									l’indice* par sinistre
								</Text>
								<View break />
								<Text>
									Pour dommages immatériels non consécutifs* : 310 fois
									l’indice* par sinistre.{" "}
								</Text>
							</View>,
							<View
								key="2-3"
								style={{ display: "flex", flexDirection: "column", gap: 10 }}
							>
								<View>
									<Text style={styles.bold}>FRANCHISE SPÉCIFIQUE :</Text>
									<Text>10 % des dommages </Text>
									<Text>- mini : franchise générale</Text>
									<Text>- maxi : 1,5 fois l’indice.</Text>
								</View>
							</View>,
						],
					},
					{
						type: "row",
						data: [
							"Dommages à des biens confiés",
							<View
								key="1-3"
								style={{ display: "flex", flexDirection: "column", gap: 10 }}
							>
								<Text>
									Dommages matériels et immatériels confondus : 310 fois
									l’indice* par sinistre.
								</Text>
							</View>,
							<View
								key="1-3"
								style={{ display: "flex", flexDirection: "column", gap: 10 }}
							>
								<View>
									<Text style={styles.bold}>FRANCHISE SPÉCIFIQUE :</Text>
									<Text>10 % des dommages </Text>
									<Text>- mini : franchise générale</Text>
									<Text>- maxi : 1,5 fois l’indice.</Text>
								</View>
							</View>,
						],
					},
					{
						type: "row",
						data: [
							"Intoxication alimentaire et dommages après livraison du produit ou réception de travaux",
							<View
								key="1-3"
								style={{ display: "flex", flexDirection: "column", gap: 10 }}
							>
								<Text>
									Tous dommages corporels, matériels et immatériels confondus :
									3 390 fois l’indice* par année d’assurance, dont 310 pour les
									dommages immatériels non consécutifs.
								</Text>
							</View>,
							<View
								key="1-3"
								style={{ display: "flex", flexDirection: "column", gap: 10 }}
							>
								<View>
									<Text style={styles.bold}>FRANCHISE GÉNÉRALE : </Text>
									<Text>
										{addSpacingBetweenThousandsToString(
											Number(props.deductible).toString(),
										)}
										{" €"}
									</Text>
								</View>
							</View>,
						],
					},
					{
						type: "row",
						data: [
							"Vol par préposé ",
							<View
								key="1-3"
								style={{ display: "flex", flexDirection: "column", gap: 10 }}
							>
								<Text>
									Dommages matériels et immatériels confondus : 310 fois
									l’indice par sinistre.
								</Text>
							</View>,
							<View
								key="1-3"
								style={{ display: "flex", flexDirection: "column", gap: 10 }}
							>
								<View>
									<Text style={styles.bold}>FRANCHISE GÉNÉRALE : </Text>
									<Text>
										{addSpacingBetweenThousandsToString(
											Number(props.deductible).toString(),
										)}
										{" €"}
									</Text>
								</View>
							</View>,
						],
					},
					{
						type: "row",
						data: [
							"Faute inexcusable",
							<View
								key="1-3"
								style={{ display: "flex", flexDirection: "column", gap: 10 }}
							>
								<Text>
									Tous dommages corporels, matériels et immatériels confondus :
									1 000 000 € par sinistre et 2 000 000 € par année d’assurance.
								</Text>
							</View>,
							<View
								key="1-3"
								style={{ display: "flex", flexDirection: "column", gap: 10 }}
							>
								<View>
									<Text style={styles.bold}>FRANCHISE GÉNÉRALE : </Text>
									<Text>
										{addSpacingBetweenThousandsToString(
											Number(props.deductible).toString(),
										)}
										{" €"}
									</Text>
								</View>
							</View>,
						],
					},
					{
						type: "row",
						data: [
							"Dommages aux biens des préposés",
							<View
								key="1-3"
								style={{ display: "flex", flexDirection: "column", gap: 10 }}
							>
								<Text>
									Dommages matériels et immatériels confondus : 310 fois
									l’indice* par sinistre.
								</Text>
							</View>,
							<View
								key="1-3"
								style={{ display: "flex", flexDirection: "column", gap: 10 }}
							>
								<View>
									<Text style={styles.bold}>FRANCHISE GÉNÉRALE : </Text>
									<Text>
										{addSpacingBetweenThousandsToString(
											Number(props.deductible).toString(),
										)}
										{" €"}
									</Text>
								</View>
							</View>,
						],
					},
					{
						type: "row",
						data: [
							"Atteintes à l’environnement accidentelles",
							<View
								key="1-3"
								style={{ display: "flex", flexDirection: "column", gap: 10 }}
							>
								<Text>
									Tous dommages corporels, matériels et immatériels confondus :
									610 fois l’indice* par année d’assurance.
								</Text>
							</View>,
							<View
								key="1-3"
								style={{ display: "flex", flexDirection: "column", gap: 10 }}
							>
								<View>
									<Text style={styles.bold}>FRANCHISE GÉNÉRALE : </Text>
									<Text>
										{addSpacingBetweenThousandsToString(
											Number(props.deductible).toString(),
										)}
										{" €"}
									</Text>
								</View>
							</View>,
						],
					},
					{
						type: "row",
						data: [
							"Responsabilité environnementale ",
							<View
								key="1-3"
								style={{ display: "flex", flexDirection: "column", gap: 10 }}
							>
								<Text>35 000 € NON INDEXÉS par année d’assurance.</Text>
							</View>,
							<View
								key="1-3"
								style={{ display: "flex", flexDirection: "column", gap: 10 }}
							>
								<View>
									<Text style={styles.bold}>FRANCHISE SPÉCIFIQUE :</Text>
									<Text>1 500 € NON INDEXÉS</Text>
								</View>
							</View>,
						],
					},
					{
						type: "row",
						data: [
							"Responsabilité des dirigeants",
							<View
								key="1-3"
								style={{ display: "flex", flexDirection: "column", gap: 10 }}
							>
								<Text>50 000 € NON INDEXÉS par année d’assurance.</Text>
							</View>,
							<View
								key="1-3"
								style={{ display: "flex", flexDirection: "column", gap: 10 }}
							>
								<View>
									<Text style={styles.bold}>FRANCHISE GÉNÉRALE : </Text>
									<Text>
										{addSpacingBetweenThousandsToString(
											Number(props.deductible).toString(),
										)}
										{" €"}
									</Text>
								</View>
							</View>,
						],
					},
				]}
			/>
			<View break />
			<Text style={[styles.bold, localStyles.tableHeader]}>
				Assurance de défense recours
			</Text>
			<ThreeColumnTable
				columns={[
					{
						header:
							"DÉFENSE RECOURS : Plafond global de garantie : 31 fois l’indice* par sinistre",
						width: 70,
						grow: 1,
					},
					{
						header: "Nombre de fois l’indice*",
						width: 30,
						grow: 2,
					},
				]}
				data={[
					{
						type: "section",
						data: "Montant des remboursements des honoraires et frais non taxables d’avocats.",
					},
					{
						type: "row",
						data: [
							"- Assistance à expertise, assistance à mesure d’instruction",
							<View
								key="1-3"
								style={{ display: "flex", flexDirection: "column", gap: 10 }}
							>
								<Text>0,38 € Par intervention</Text>
							</View>,
						],
					},
					{
						type: "row",
						data: [
							"- Recours précontentieux en matière administrative",
							<View
								key="1-3"
								style={{ display: "flex", flexDirection: "column", gap: 10 }}
							>
								<Text>0,33</Text>
							</View>,
						],
					},
					{
						type: "row",
						data: [
							"- Représentation devant une commission administrative, civile ou disciplinaire",
							<View
								key="1-3"
								style={{ display: "flex", flexDirection: "column", gap: 10 }}
							>
								<Text>0,57 Par affaire*</Text>
							</View>,
						],
					},
					{
						type: "row",
						data: [
							"- Intervention amiable non aboutie",
							<View
								key="1-3"
								style={{ display: "flex", flexDirection: "column", gap: 10 }}
							>
								<Text>0,33</Text>
							</View>,
						],
					},
					{
						type: "row",
						data: [
							"- Intervention amiable aboutie avec protocole d’accord signé par les parties",
							<View
								key="1-3"
								style={{ display: "flex", flexDirection: "column", gap: 10 }}
							>
								<Text>0,57 Par affaire*</Text>
							</View>,
						],
					},
					{
						type: "row",
						data: [
							"- Médiation ou conciliation ayant abouti et constatée par le juge",
							<View
								key="1-3"
								style={{ display: "flex", flexDirection: "column", gap: 10 }}
							>
								<Text>0,57</Text>
							</View>,
						],
					},
					{
						type: "row",
						data: [
							"- Ordonnance, quelle que soit la juridiction, de référé",
							<View
								key="1-3"
								style={{ display: "flex", flexDirection: "column", gap: 10 }}
							>
								<Text>0,64 Par ordonnance</Text>
							</View>,
						],
					},
					{
						type: "row",
						data: [
							"- Tribunal de police sans constitution de partie civile de l’assuré",
							<View
								key="1-3"
								style={{ display: "flex", flexDirection: "column", gap: 10 }}
							>
								<Text>0,52 Par affaire*</Text>
							</View>,
						],
					},
					{
						type: "row",
						data: [
							"- Tribunal de grande instance, Tribunal de commerce, Conseil de prud’hommes, Tribunal administratif",
							<View
								key="1-3"
								style={{ display: "flex", flexDirection: "column", gap: 10 }}
							>
								<Text>1,41</Text>
							</View>,
						],
					},
					{
						type: "row",
						data: [
							"- Juge de l’exécution",
							<View
								key="1-3"
								style={{ display: "flex", flexDirection: "column", gap: 10 }}
							>
								<Text>0,64</Text>
							</View>,
						],
					},
					{
						type: "row",
						data: [
							"- Toutes autres juridictions de première instance ",
							<View
								key="1-3"
								style={{ display: "flex", flexDirection: "column", gap: 10 }}
							>
								<Text>1,28</Text>
							</View>,
						],
					},
					{
						type: "row",
						data: [
							"- Appel en matière pénale",
							<View
								key="1-3"
								style={{ display: "flex", flexDirection: "column", gap: 10 }}
							>
								<Text>1,15</Text>
							</View>,
						],
					},
					{
						type: "row",
						data: [
							"- Appel dans toutes autres matières",
							<View
								key="1-3"
								style={{ display: "flex", flexDirection: "column", gap: 10 }}
							>
								<Text>1,54</Text>
							</View>,
						],
					},
					{
						type: "row",
						data: [
							"- Cour d’assises, Cour de cassation, Conseil d’État, Cour de justice de l’Union européenne, Cour européenne des droits de l’homme",
							<View
								key="1-3"
								style={{ display: "flex", flexDirection: "column", gap: 10 }}
							>
								<Text>2,56 Par affaire* (y compris les consultations)</Text>
							</View>,
						],
					},
				]}
			/>
		</View>
	);
};

export default LiabilityInsurances;
