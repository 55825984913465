import { z } from "zod";

export const reportSchema = z.object({
	file: z.string().min(1),
	filename: z.string().min(1),
	mimeType: z.enum([
		"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
	]),
});

export type ReportDto = z.infer<typeof reportSchema>;
