import { z } from "zod";

export const AddressApiSchema = z.object({
	features: z.array(
		z.object({
			mainAddress: z.string(),
			postalCode: z.string(),
			cityCode: z.string(),
			longitude: z.number(),
			latitude: z.number(),
			city: z.string(),
			country: z.string(),
		}),
	),
});
export type AddressApiSchemaType = z.infer<typeof AddressApiSchema>;

export const GlobalAddressApiSchema = z.array(
	z.object({
		lat: z.string(),
		lon: z.string(),
		display_name: z.string(),
		address: z.object({
			city: z.string().optional(),
			postcode: z.string(),
			country: z.string(),
			country_code: z.string(),
		}),
	}),
);

export const extractCityFromDisplayName = (
	displayName: string,
	country: string,
): string | undefined => {
	const parts = displayName
		.split(",")
		.map((part) => part.trim())
		.filter((part) => part.length > 0);

	// Remove the country and postal code from the end
	const relevantParts = parts
		.filter((part) => !part.includes(country))
		.filter((part) => !/^\d+$/.test(part));

	if (relevantParts.length >= 2) {
		return relevantParts[relevantParts.length - 2];
	}

	return relevantParts[relevantParts.length - 1] || "   ";
};

export const transformGlobalAddresses = (
	input: z.infer<typeof GlobalAddressApiSchema>,
): z.infer<typeof AddressApiSchema> => {
	return {
		features: input.map((item) => ({
			mainAddress: item.display_name,
			postalCode: item.address.postcode,
			cityCode: item.address.country_code.toUpperCase(),
			longitude: parseFloat(item.lon),
			latitude: parseFloat(item.lat),
			city:
				item.address.city ||
				extractCityFromDisplayName(item.display_name, item.address.country) ||
				"   ",
			country: item.address.country,
		})),
	};
};

export const CitiesApiSchema = z.object({
	features: z.array(
		z.object({
			city: z.string(),
		}),
	),
});
