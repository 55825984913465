import { useContext, useEffect } from "react";
import LiabilityIcon from "@/assets/LiabilityIcon.svg";
import LosesIcon from "@/assets/LosesIcon.svg";
import PropertyDamageIcon from "@/assets/PropertyDamageIcon.svg";
import TarifIcon from "@/assets/TarifIcon.svg";
import {
	Accordion,
	AccordionContent,
	AccordionItem,
	AccordionTrigger,
} from "@/components/ui/accordion";
import { Card, CardContent, CardHeader } from "@/components/ui/card";
import { toast } from "@/components/ui/use-toast.ts";
import { useCustomFormNavigate } from "@/hooks/customNavigate";
import { LayoutButtonsContext } from "@/layouts/Layout";
import { GuaranteeTypeNameFromPremium } from "@/lib/GuaranteeTypeNameFromPremium";
import { DisplayInputField } from "@/pages/form/1/components/3/DisplayInputField";
import { formStore } from "@/pages/form/stores/formStore";
import { formatSubscriptionInputData } from "@/pages/form/utils/formatSubscriptionInputData.ts";
import { useAuthStore } from "@/stores/authStore";
import {
	addSpacingBetweenThousandsToString,
	formatSiret,
	onKeyDownPreventDefaultIfEnter,
	trpc,
} from "@/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import { captureException } from "@sentry/react";
import { FormProvider, useForm } from "react-hook-form";
import { FaBuilding } from "react-icons/fa6";

import type {
	ActivityInputDto,
	ActivityPricingDetailsDto,
	PricingResponseDto,
} from "@repos/mrp-dtos";
import { PricingResponseSchema } from "@repos/mrp-dtos";

interface Guarantee {
	guaranteeType: string;
	guaranteeValue: number;
	category: "basic" | "propertyDamage" | "loses" | "liability";
}

export const OfferSummaryPage = () => {
	const formStoreData = formStore((state) => state);
	const {
		fileFees,
		quote,
		setSubscriptionId,
		insuredEstablishments,
		activityPage,
	} = {
		fileFees: formStoreData.quote.fileFees,
		quote: formStoreData.quote,
		setSubscriptionId: formStoreData.setSubscriptionId,
		insuredEstablishments: formStoreData.establishments.insuredEstablishments,
		activityPage: formStoreData.activityPage,
	};

	const { setNextButtonDisabled, setNextButtonLoading } =
		useContext(LayoutButtonsContext);

	const subscriptionMutation = trpc.quote.create.useMutation({
		onSuccess: (data) => {
			setNextButtonLoading(false);
			setNextButtonDisabled(false);
			setSubscriptionId(data);
			navigate("SendingQuotesPage");
		},
		onError: () => {
			setNextButtonLoading(false);
			setNextButtonDisabled(false);
			toast({
				title: "Erreur",
				description: "Une erreur est survenue lors de la souscription",
				variant: "destructive",
			});
		},
	});

	useEffect(() => {
		setNextButtonLoading(subscriptionMutation.isLoading);
		setNextButtonDisabled(subscriptionMutation.isLoading);
		return () => {
			setNextButtonLoading(false);
			setNextButtonDisabled(false);
		};
	}, [
		subscriptionMutation.isLoading,
		setNextButtonLoading,
		setNextButtonDisabled,
	]);

	const allActivities: ActivityInputDto[] = [
		activityPage.principalActivity!,
		...(activityPage.secondaryActivities as ActivityInputDto[]),
	];

	const navigate = useCustomFormNavigate();
	if (!quote.pricingData) {
		captureException("pricingData is undefined in OfferSummaryPage", {
			data: {
				quote,
				formStoreData,
			},
		});
		navigate("QuotesPage");
	}
	const methods = useForm<PricingResponseDto>({
		defaultValues: quote.pricingData,
		resolver: zodResolver(PricingResponseSchema),
	});
	const partnershipId = useAuthStore((state) => state.partnershipId);

	const onSubmit = async () => {
		const searchParams = new URLSearchParams(window.location.search);
		const fallBackCourierData = searchParams.get("encrypt");

		const subscriptionInputData = formatSubscriptionInputData({
			partnershipId: partnershipId || fallBackCourierData || "",
			pricingData: quote.pricingData!,
			formStoreData,
		});
		subscriptionMutation.mutate(subscriptionInputData);
	};

	// Function to extract and categorize guarantees from pricing details
	const extractGuarantees = (pricingDetails: ActivityPricingDetailsDto) => {
		const guarantees: Guarantee[] = [];

		// Basic guarantees
		const basicGuarantees = pricingDetails.basic;
		if (basicGuarantees) {
			Object.entries(basicGuarantees).forEach(([key, value]) => {
				if (value !== 0) {
					guarantees.push({
						guaranteeType: key,
						guaranteeValue: value,
						category: "basic",
					});
				}
			});
		}

		// Property Damage guarantees
		const propertyDamageGuarantees = pricingDetails.dommageAuxBiens;
		if (propertyDamageGuarantees) {
			Object.entries(propertyDamageGuarantees).forEach(([key, value]) => {
				guarantees.push({
					guaranteeType: key,
					guaranteeValue: value ?? 0,
					category: "propertyDamage",
				});
			});
		}

		// Losses guarantees
		const losesGuarantees = pricingDetails.pertesActivite;
		if (losesGuarantees) {
			Object.entries(losesGuarantees).forEach(([key, value]) => {
				guarantees.push({
					guaranteeType: key,
					guaranteeValue: value ?? 0,
					category: "loses",
				});
			});
		}

		// Liability and Legal Defense guarantees
		const liabilityGuarantees = pricingDetails.rcDefensePj;
		if (liabilityGuarantees) {
			Object.entries(liabilityGuarantees).forEach(([key, value]) => {
				guarantees.push({
					guaranteeType: key,
					guaranteeValue: value ?? 0,
					category: "liability",
				});
			});
		}

		return guarantees;
	};

	if (fileFees === undefined) {
		captureException("fileFees is undefined in OfferSummaryPage", {
			data: {
				quote,
				formStoreData,
			},
		});
		navigate("QuotesPage");
	}
	const pricingDataInsuredEstablishments = [
		quote.pricingData?.primaryActivityPricingDetails,
		quote.pricingData?.secondaryActivityPricingDetails,
		quote.pricingData?.tertiaryActivityPricingDetails,
	].slice(0, insuredEstablishments?.length);
	return (
		<FormProvider {...methods}>
			<form
				id="form"
				onKeyDown={onKeyDownPreventDefaultIfEnter}
				onSubmit={methods.handleSubmit(onSubmit)}
			>
				<div className="mb-4 flex flex-col items-start justify-between gap-2 text-left">
					<h1>Résumé de l&apos;offre</h1>
					<p>Aperçu de l&apos;engagement</p>
				</div>
				<div className="">
					<h2 className="my-4 text-lg font-semibold text-[#333]">
						Établissement(s) choisi(s) à assurer
					</h2>
					<Accordion
						type="single"
						collapsible
						className="w-full p-0"
						defaultValue="item-1"
					>
						{insuredEstablishments?.map((establishment, index) => {
							const activity = allActivities.find(
								(activity) => activity.id === establishment.hightestActivityId,
							);

							return (
								<AccordionItem
									value={`item-${index + 1}`}
									key={establishment.siret}
									className="border-none"
								>
									<Card
										className="mb-4 flex w-full flex-col gap-4 rounded-2xl border-gray-200 bg-white"
										key={establishment.siret}
									>
										<AccordionTrigger className="flex flex-row items-center justify-between rounded-2xl rounded-b-none bg-[#FFFAFA] p-0">
											<CardHeader className="flex flex-row items-center justify-between rounded-2xl rounded-b-none bg-[#FFFAFA]">
												<div className="company-infos flex items-center gap-4">
													<div className="flex h-12 w-12 items-center justify-center rounded-full bg-[#023048]">
														<FaBuilding className="size-8 fill-[#FFF]" />
													</div>
													<div className="flex flex-col gap-1">
														<p className="text-md font-bold text-[#023048] lg:text-lg">
															Établissement {index + 1} |{" "}
															{formatSiret(establishment.siret || "")}
														</p>
													</div>
												</div>
											</CardHeader>
										</AccordionTrigger>
										<AccordionContent>
											<CardContent className="grid grid-cols-2 gap-x-6 gap-y-2 px-8">
												<DisplayInputField
													label="Adresse"
													value={establishment.streetLabel}
												/>
												<DisplayInputField
													label="Activité"
													value={activity?.label}
												/>
											</CardContent>
										</AccordionContent>
									</Card>
								</AccordionItem>
							);
						})}
					</Accordion>

					<h2 className="my-4 text-lg font-semibold text-[#333]">Tarifs TTC</h2>
					{pricingDataInsuredEstablishments.map(
						(activityPricingDetails, index) => {
							if (!activityPricingDetails) return null;

							const activity = allActivities.find(
								(activity) => activity.id === activityPricingDetails.activityId,
							);
							const relatedEstablishmentSiret = activityPricingDetails.siret;
							const guarantees = extractGuarantees(activityPricingDetails);

							const propertyDamage = guarantees.filter(
								(g) => g.category === "propertyDamage",
							);
							const liability = guarantees.filter(
								(g) => g.category === "liability",
							);

							const loses = guarantees.filter((g) => g.category === "loses");
							const basic = guarantees.filter((g) => g.category === "basic");

							const BASE_GUARANTEE_COST = basic.reduce(
								(total, g) => total + g.guaranteeValue,
								0,
							);

							const hasOptionalGuarantees =
								guarantees.filter((g) => g.category !== "basic").length > 0;

							return (
								<div key={index} className="mb-4">
									<Card className="flex w-full flex-col rounded-2xl border-gray-200 bg-white">
										<CardHeader className="flex flex-row items-center justify-between rounded-2xl rounded-b-none bg-[#FFFAFA]">
											<div className="company-infos flex w-9/12 items-center gap-4">
												<div className="flex flex-row items-center justify-start gap-2">
													<div>
														<div className="flex h-12 w-12 items-center justify-center rounded-full bg-[#023048]">
															<img src={TarifIcon} />
														</div>
													</div>
													<div className="flex flex-col gap-1">
														<p className="text-md font-bold text-[#333] lg:text-lg">
															Tarif TTC des garanties souscrites |{" "}
															{formatSiret(
																relatedEstablishmentSiret.toString(),
															)}{" "}
															<br />
															Activité : {activity?.label}
														</p>
													</div>
												</div>
											</div>
											<p className="w-3/12 text-right text-lg font-bold text-[#23272E]">
												{addSpacingBetweenThousandsToString(
													(
														BASE_GUARANTEE_COST +
														guarantees
															.filter((g) => g.category !== "basic")
															.reduce((total, g) => total + g.guaranteeValue, 0)
													).toFixed(2),
												)}{" "}
												€
											</p>
										</CardHeader>
										<CardContent className="p-0">
											<div className="flex justify-between gap-x-6 gap-y-2 border-b border-[#E4E4E4] px-8 py-4">
												<div>
													<p className="text-base font-bold text-[#23272E]">
														Garanties de base
													</p>
												</div>
												<span className="text-lg font-bold text-[#333]">
													{addSpacingBetweenThousandsToString(
														BASE_GUARANTEE_COST.toFixed(2),
													)}{" "}
													€
												</span>
											</div>
											{hasOptionalGuarantees ? (
												<div className="flex justify-between gap-x-6 gap-y-2 border-b border-[#E4E4E4] px-8 py-4">
													<div>
														<p className="text-base font-bold text-[#23272E]">
															Garanties optionnelles
														</p>
													</div>
													<p className="text-lg font-bold text-[#333]">
														{addSpacingBetweenThousandsToString(
															guarantees
																.filter((g) => g.category !== "basic")
																.reduce(
																	(total, g) => total + g.guaranteeValue,
																	0,
																)
																.toFixed(2),
														)}{" "}
														€
													</p>
												</div>
											) : null}
											<div className="scroll">
												{(propertyDamage.length > 0 ||
													loses.length > 0 ||
													liability.length > 0) && (
														<div className="overflow-y-scroll">
															{propertyDamage.length > 0 && (
																<>
																	<div className="flex w-full flex-row items-center justify-between gap-3 border-b border-[#E4E4E4] bg-[#FCFCFC] py-6 pl-12 pr-4">
																		<div className="flex flex-row items-center gap-3">
																			<img src={PropertyDamageIcon} alt="" />
																			<p className="text-lg font-semibold">
																				Dommages aux biens
																			</p>
																		</div>
																		<p className="text-lg font-medium">
																			{addSpacingBetweenThousandsToString(
																				propertyDamage
																					.reduce(
																						(total, guarantee) =>
																							total + guarantee.guaranteeValue,
																						0,
																					)
																					.toFixed(2),
																			)}{" "}
																			€
																		</p>
																	</div>
																	<div className="flex w-full flex-wrap bg-white py-6 pl-12 pr-4">
																		{propertyDamage.map((g) => (
																			<div
																				key={g.guaranteeType}
																				className="mb-4 flex w-full items-center justify-between space-x-2"
																			>
																				<span>
																					{GuaranteeTypeNameFromPremium(
																						g.guaranteeType,
																					)}
																				</span>
																				{addSpacingBetweenThousandsToString(
																					g.guaranteeValue.toFixed(2),
																				)}{" "}
																				€
																			</div>
																		))}
																	</div>
																</>
															)}
															{loses.length > 0 && (
																<>
																	<div className="flex w-full flex-row items-center justify-between gap-3 border-b border-[#E4E4E4] bg-[#FCFCFC] py-6 pl-12 pr-4">
																		<div className="flex flex-row items-center gap-3">
																			<img src={LosesIcon} alt="" />
																			<p className="text-lg font-semibold">
																				Pertes d&apos;activités
																			</p>
																		</div>
																		<p className="text-lg font-medium">
																			{addSpacingBetweenThousandsToString(
																				loses
																					.reduce(
																						(total, guarantee) =>
																							total + guarantee.guaranteeValue,
																						0,
																					)
																					.toFixed(2),
																			)}{" "}
																			€
																		</p>
																	</div>
																	<div className="flex w-full flex-wrap bg-white py-6 pl-12 pr-4">
																		{loses.map((g) => (
																			<div
																				key={g.guaranteeType}
																				className="mb-4 flex w-full items-center justify-between space-x-2"
																			>
																				<span>
																					{GuaranteeTypeNameFromPremium(
																						g.guaranteeType,
																					)}
																				</span>
																				{addSpacingBetweenThousandsToString(
																					g.guaranteeValue.toFixed(2),
																				)}{" "}
																				€
																			</div>
																		))}
																	</div>
																</>
															)}
															{liability.length > 0 && (
																<>
																	<div className="flex w-full flex-row items-center justify-between gap-3 border-b border-[#E4E4E4] bg-[#FCFCFC] py-6 pl-12 pr-4">
																		<div className="flex flex-row items-center gap-3">
																			<img src={LiabilityIcon} alt="" />
																			<p className="text-lg font-semibold">
																				Responsabilité civile – Défense et
																				Protection Juridique
																			</p>
																		</div>
																		<p className="text-lg font-medium">
																			{addSpacingBetweenThousandsToString(
																				liability
																					.reduce(
																						(total, guarantee) =>
																							total + guarantee.guaranteeValue,
																						0,
																					)
																					.toFixed(2),
																			)}{" "}
																			€
																		</p>
																	</div>
																	<div className="flex w-full flex-wrap bg-white py-6 pl-12 pr-4">
																		{liability.map((g) => (
																			<div
																				key={g.guaranteeType}
																				className="mb-4 flex w-full items-center justify-between space-x-2"
																			>
																				<span>
																					{GuaranteeTypeNameFromPremium(
																						g.guaranteeType,
																					)}
																				</span>
																				{addSpacingBetweenThousandsToString(
																					g.guaranteeValue.toFixed(2),
																				)}{" "}
																				€
																			</div>
																		))}
																	</div>
																</>
															)}
														</div>
													)}
											</div>
										</CardContent>
									</Card>
								</div>
							);
						},
					)}

					<h2 className="my-4 text-lg font-semibold text-[#333]">
						Détails de l’offre
					</h2>
					{/* Keep the existing Franchise and Prime totale sections */}
					<div className="rounded-lg bg-[#023048]">
						<div className="flex w-full items-center justify-between border-b border-white px-8 py-4">
							<p className="text-base font-bold text-white">Franchise</p>
							<span className="text-right text-base font-bold text-white">
								{addSpacingBetweenThousandsToString(
									Number(quote.valeurFranchise || 0).toFixed(2),
								)}{" "}
								€
							</span>
						</div>
						<div className="flex w-full items-center justify-between px-8 py-4">
							<p className="text-base font-bold text-white">
								Prime Totale Contrat
							</p>
							<span className="text-right text-base font-bold text-white">
								{addSpacingBetweenThousandsToString(
									(quote.pricingData
										? quote.pricingData.totalInsurancePremium +
										quote.pricingData.managementFees +
										quote.pricingData.releaseFees
										: 0
									).toFixed(2),
								)}
								{" €"}
							</span>
						</div>
					</div>
				</div>
			</form>
		</FormProvider>
	);
};
