import { styles } from "@/components/quotePdf/styles";
import ThreeColumnTable from "@/components/quotePdf/Table";
import { Text, View } from "@react-pdf/renderer";

import type { SubscriptionDetailsForQuoteDto } from "@repos/mrp-dtos";

const Declarations = ({ ...props }: SubscriptionDetailsForQuoteDto) => (
	<View style={{ display: "flex", flexDirection: "column", gap: 10 }}>
		<Text style={styles.sectionHeader}>VOS DÉCLARATIONS</Text>
		<Text style={[styles.bodyText, styles.max2Lines]}>
			<Text>
				{
					"Aux questions qui lui ont été posées lors de l’établissement de l’étude de besoins, le(s) représentant(s) légal(aux), "
				}
				<Text style={styles.bold}>
					{props.companyInfo.legalRepresentativeName}
				</Text>
				{" , agissant en qualité de "}
				<Text style={styles.bold}>
					{props.companyInfo.legalRepresentativeTitle}
				</Text>
				{" de l’entreprise, reconnaît avoir répondu :"}
			</Text>
		</Text>

		{/* ANTÉCÉDENTS D’ASSURANCE */}
		<Text style={[styles.subSectionText, styles.mt_10]}>
			ANTÉCÉDENTS D’ASSURANCE
		</Text>
		<Text style={[styles.bodyText, styles.max2Lines]}>Vous déclarez :</Text>
		<View style={{ paddingLeft: 15 }}>
			<Text style={styles.bodyText}>
				• Combien avez-vous déclaré ou occasionné de sinistres au cours des 36
				derniers mois :{" "}
				{props.numberOfClaims === "ZERO"
					? "Aucun"
					: `${
							props.numberOfClaims === "ONE"
								? "un"
								: props.numberOfClaims === "TWO"
									? "deux"
									: props.numberOfClaims === "THREE"
										? "trois"
										: "plus que trois"
						}, dont ${props.numberOfTheftClaims} sinistres vols.`}
			</Text>
			<Text style={styles.bodyText}>
				• Avez-vous fait l’objet d’une résiliation pour non-paiement de
				cotisation par un précédent assureur au cours des 36 derniers mois :{" "}
				{props.nonPaymentTermination ? "Oui" : "Non"}
			</Text>
			<Text style={styles.bodyText}>
				• Avez-vous fait l’objet d’une résiliation pour fausse déclaration (ou
				nullité du contrat) par un précédent assureur au cours des 36 derniers
				mois : {props.falseStatementTermination ? "Oui" : "Non"}
			</Text>
			<Text style={styles.bodyText}>
				• Êtes-vous sous le coup d’une liquidation ou d’un redressement
				judiciaire au cours des 36 derniers mois :{" "}
				{props.judicialLiquidation ? "Oui" : "Non"}
			</Text>
		</View>

		{/* SITUATION DES LOCAUX PROFESSIONNELS */}
		<Text style={[styles.subSectionText, styles.mt_10]}>
			SITUATION DES LOCAUX PROFESSIONNELS
		</Text>
		<Text style={styles.bodyText}>
			Vous déclarez que les locaux professionnels :
		</Text>
		<View style={{ paddingLeft: 15 }}>
			<Text style={styles.bodyText}>• Ne sont pas situés :</Text>
			<View style={{ paddingLeft: 15 }}>
				<Text style={styles.bodyText}>
					- À plus de 500 m d’une habitation régulièrement habitée.
				</Text>
				<Text style={styles.bodyText}>
					- Dans un bâtiment construit et couvert à moins de 75% en matériaux
					durs.
				</Text>
				<Text style={styles.bodyText}>
					- Dans un bâtiment considéré comme inoccupé ou désaffecté.
				</Text>
				<Text style={styles.bodyText}>
					- Dans un bâtiment classé ou inscrit au titre des Monuments
					historiques dans le cas d’un statut/qualité de propriétaire ou d’une
					demande d’assurance pour le compte du propriétaire rédigée dans le
					bail.
				</Text>
				<Text style={styles.bodyText}>
					- Dans un bâtiment construit sur le terrain d’autrui.
				</Text>
				<Text style={styles.bodyText}>
					- Dans un bâtiment renfermant les activités suivantes : discothèque,
					boîte de nuit, bowling, cabaret, bar de nuit, ou tout autre
					établissement uniquement ouvert la nuit.
				</Text>
			</View>
			<Text style={styles.bodyText}>
				• Le local n’est pas frappé d’alignement.
			</Text>
			<Text style={styles.bodyText}>
				• Ne sont pas utilisés principalement comme dépôt entrepôt ou réserve
				pour le compte de tiers.
			</Text>
			<Text style={styles.bodyText}>• Ne comportent pas :</Text>
			<View style={{ paddingLeft: 15 }}>
				<Text style={styles.bodyText}>
					- Plus de 500 Litres de liquides inflammables ou équivalent en gaz
					liquéfiés.
				</Text>
				<Text style={styles.bodyText}>
					- De chambres froides dont le volume (en une ou plusieurs chambres)
					est supérieur à 1500 m³.
				</Text>
				<Text style={styles.bodyText}>
					- Un sommet de marchandises supérieur à 7,20 mètres à partir du sol.
				</Text>
				<Text style={styles.bodyText}>
					- De stock d’emballages vides de matière plastiques alvéolaires
					supérieur à 5m³ et/ou d’une valeur supérieure à 15 000 € (30 000 €
					pour le commerce de gros).
				</Text>
			</View>
		</View>

		{/* Clauses spécifiques pour DROM-COM */}
		{props.isDROMCOM && (
			<View style={{ marginTop: 15 }}>
				<Text style={[styles.bodyText, styles.bold]}>
					Clauses spécifiques à la Guadeloupe, Martinique, Guyane, Réunion,
					Saint-Pierre et Miquelon et Saint-Martin :
				</Text>
				<Text style={styles.bodyText}>Vous déclarez que vos locaux sont :</Text>
				<View style={{ paddingLeft: 15 }}>
					<Text style={styles.bodyText}>
						• Situés à plus de 200 mètres de la zone côtière,
					</Text>
					<Text style={styles.bodyText}>
						• Situés à plus de 20 mètres d’un cours d’eau ou d’une ravine.
					</Text>
					<Text style={styles.bodyText}>
						• Construits sur un terrain avec une pente supérieure à 10%.
					</Text>
					<Text style={styles.bodyText}>
						• Avec une construction qui correspond aux normes anticycloniques
						et/ou antisismiques à la date de construction.
					</Text>
				</View>
			</View>
		)}
		<Text style={[styles.subSectionText, styles.mt_10, styles.underline]} break>
			MOYENS DE PROTECTION ET PRÉVENTION
		</Text>
		<Text style={[styles.subSectionText]}>Vous déclarez que :</Text>
		<Text style={[styles.subSectionText]}>En incendie :</Text>
		<Text style={[styles.bodyText]}>
			• Toutes les installations électriques des locaux professionnels sont
			conformes aux prescriptions réglementaires et contrôlées annuellement par
			un électricien :{" "}
			{props.electricInstallationsInLocals === "qualified"
				? "Qualifié"
				: props.electricInstallationsInLocals ===
					  "certifiedWithDeliveryOfCertificate"
					? "Certifié avec délivrance Q18"
					: "Aucune vérification"}
			.
		</Text>
		<Text style={[styles.bodyText]}>
			En cas de sinistre, il sera demandé la facture avec le rapport et ou le
			certificat de la dernière vérification ainsi que les justificatifs de
			travaux préconisés par l’électricien.
		</Text>
		<Text style={[styles.bodyText]}>
			• Tous les extincteurs mobiles des locaux professionnels sont conformes
			aux prescriptions réglementaires et contrôlés annuellement par un
			professionnel :{" "}
			{props.mobileExtinguishersInLocals === "qualified"
				? "Qualifié"
				: props.mobileExtinguishersInLocals ===
					  "certifiedWithDeliveryOfCertificate"
					? "Certifié avec délivrance Q4"
					: "Aucune vérification"}
			.
		</Text>
		{(props.mobileExtinguishersInLocals !== "notCertified" ||
			props.electricInstallationsInLocals !== "notCertified") && (
			<>
				<Text style={[styles.bodyText]}>
					En cas de sinistre, il sera demandé la facture avec le rapport et ou
					le certificat de la dernière vérification ainsi que les justificatifs
					de travaux préconisés par le professionnel.
				</Text>
				<Text style={[styles.bodyText, styles.bold]}>
					As Solutions tolèrera un délai de 3 mois pour la mise en conformité
					des systèmes électriques et incendie. A défaut de mise en conformité
					dans les délais, le contrat sera résilié de plein droit par As
					Solutions. Le client devra fournir les justificatifs dans ce délai.
				</Text>
			</>
		)}
		<Text style={[styles.subSectionText, styles.mt_10]}>En vol :</Text>
		<Text style={[styles.bodyText]}>
			• Les locaux professionnels sont équipés des moyens de protection contre
			le vol décrits ci-dessous par rapport au montant de garantie vol demandé
			(ces niveaux sont cumulables) :
		</Text>

		<ThreeColumnTable
			columns={[
				{ header: "Niveau garantie", width: 10, grow: 2, style: styles.bgGray },
				{
					header: "Montant plafond garantie vol",
					width: 25,
					grow: 1,
					style: styles.bgGray,
				},
				{
					header: (
						<Text>
							Mesures de Prévention
							<Text style={styles.bold}> SOUS PEINE DE NON-GARANTIE</Text>
							{`, vos
							locaux professionnels doivent comporter au minimum les moyens de
							protection suivants :`}
						</Text>
					),
					width: 65,
					grow: 2,
					style: styles.bgGray,
				},
			]}
			data={[
				{
					type: "row",
					data: [
						"1",
						"< 40 000 €",
						`• Pour les portes d’accès : 2 systèmes de fermeture dont un au
moins de sûreté`,
					],
				},
				{
					type: "row",
					data: [
						"2",
						"> 40 001€ < 80 000 € ",
						<View
							key={"abc"}
							style={{ display: "flex", flexDirection: "column", gap: 10 }}
						>
							<Text
								style={{ borderBottom: "1px solid black", paddingBottom: 10 }}
							>
								<Text style={[styles.bold]}>• Pour les parties vitrées </Text>{" "}
								(autre que les vitrines et devantures), soupiraux ou autres
								ouvertures accessibles : des barreaux ou grilles métalliques
								fixés dans la maçonnerie et espacés au maximum de 12 cm
							</Text>
							<Text>
								<Text style={[styles.bold]}>
									• Pour les vitrines ou devantures :
								</Text>{" "}
								rideaux ou grilles métalliques ou glaces antieffraction
							</Text>
						</View>,
					],
				},
				{
					type: "row",
					data: [
						"3",
						"> 80 001 €",
						`• Une installation d’alarme figure parmi ces moyens de
protection, elle doit être enclenchée et sous bon fonctionnement`,
					],
				},
				{
					type: "row",
					data: ["4", "Option si valeur > 1 500 €", "• Un coffre-fort fixe"],
				},
			]}
		/>
	</View>
);
export default Declarations;
