import { HeaderFooter } from "@/components/quotePdf/HeaderFooter.tsx";
import { styles } from "@/components/quotePdf/styles.ts";
import { Page, Text, View } from "@react-pdf/renderer";
import { formatDate } from "date-fns";

import type { SubscriptionDetailsForQuoteDto } from "@repos/mrp-dtos";

export const Page0 = ({ ...props }: SubscriptionDetailsForQuoteDto) => {
	return (
		<Page size="A4" style={styles.page}>
			<HeaderFooter />
			<View style={styles.container}>
				{/* Header Section */}
				<View style={[styles.justifyEnd, styles.halfWidth]}>
					<Text style={[styles.bodyText, styles.max2Lines]}>
						{props.companyInfo.companyName}
					</Text>
					<Text style={[styles.bodyText, styles.max2Lines]}>
						Représentée par : {props.companyInfo.legalRepresentativeName}
					</Text>
					<Text style={[styles.bodyText, styles.max2Lines]}>
						{props.companyInfo.address.fullAddress}
					</Text>
					<Text style={[styles.bodyText, styles.max2Lines]}>
						{props.companyInfo.address.postalCode}{" "}
						{props.companyInfo.address.city}
					</Text>
				</View>

				{/* Votre courtier conseil Section */}
				<View style={styles.m_20}>
					<Text style={[styles.bodyText, styles.bold]}>
						Votre courtier conseil
					</Text>
					<Text style={[styles.bodyText, styles.max2Lines]}>
						<Text style={styles.bold}>Cabinet : </Text>
						{props.cabinet.name}
					</Text>
					<Text style={[styles.bodyText, styles.max2Lines]}>
						<Text style={styles.bold}>N° ORIAS : </Text>
						{props.cabinet.orias}
					</Text>
					<Text style={[styles.bodyText, styles.max2Lines]}>
						<Text style={styles.bold}>Référence cabinet : </Text>
						{props.cabinet.orias}
					</Text>
					<Text style={[styles.bodyText, styles.max2Lines]}>
						<Text style={styles.bold}>Nom du vendeur : </Text>
						{props.cabinet.vendorName}
					</Text>
					<View style={{ display: "flex", flexDirection: "row" }}>
						<View>
							<Text style={[styles.bold, styles.bodyText]}>Adresse : </Text>
						</View>
						<View>
							<Text style={[styles.bodyText, styles.max2Lines]}>
								{props.cabinet.address.fullAddress}
							</Text>
							<Text style={[styles.bodyText]}>
								{props.cabinet.address.postalCode} {props.cabinet.address.city}
							</Text>
						</View>
					</View>

					<Text style={[styles.justifyEnd, styles.bodyText]}>
						Fait à {props.cabinet.address.city}, le{" "}
						{formatDate(new Date(), "dd/MM/yyyy")}
					</Text>
				</View>
				{/* Cher client Section */}
				<View style={styles.m_20}>
					<View style={{ display: "flex", flexDirection: "column", gap: 10 }}>
						<Text style={[styles.bodyText, styles.max2Lines]}>
							Cher(e) Client(e),
						</Text>
						<Text style={[styles.bodyText, styles.max2Lines]}>
							Vous trouverez ci-joint le devis référencé {props.quoteNumber}{" "}
							relatif à l’assurance multirisque professionnelle {"\n"}ACCELERANT
							pour votre société {props.companyInfo.companyName}, valable 2
							mois.
						</Text>
						<Text style={[styles.bodyText, styles.max2Lines]}>
							Afin de vous délivrer votre futur contrat d’assurance, nous vous
							remercions de bien vouloir nous adresser par retour le(s)
							document(s) ci-dessous :
						</Text>
						<View style={{ paddingLeft: 30 }}>
							<Text style={[styles.bodyText, styles.max2Lines]}>
								• Copie de la carte d’identité du dirigeant / gérant du commerce
								souscripteur du contrat
							</Text>
							<Text style={[styles.bodyText, styles.max2Lines]}>
								• Contrat de bail
							</Text>
							<Text style={[styles.bodyText, styles.max2Lines]}>
								• Kbis de moins de 3 mois
							</Text>

							<Text style={[styles.bodyText, styles.max2Lines]}>
								• Registre de bénéficiaire effectif
							</Text>

							<Text style={[styles.bodyText, styles.max2Lines]}>
								• Questionnaire d’évaluation du risque tamponné et signé
							</Text>

							<Text style={[styles.bodyText, styles.max2Lines]}>
								{`• Relevé d'Identité Bancaire (RIB)`}
							</Text>
						</View>
						<Text style={[styles.bodyText, styles.max2Lines]}>
							Ainsi que le présent devis tamponné et signé
						</Text>
						<Text style={[styles.bodyText, styles.max2Lines]}>
							Dans cette attente, et restant à votre écoute pour vous
							accompagner et vous conseiller dans vos futurs projets, nous vous
							prions d’agréer, Cher(e) Client(e), l’expression de nos
							salutations distinguées.
						</Text>
					</View>
				</View>
			</View>
		</Page>
	);
};
