import { SelectInput } from "@/components/inputs/SelectInput";
import { formatSiret } from "@/utils";
import { NatureOfClaim } from "@prisma/client";

import { formStore } from "../../stores/formStore";

type ClaimComponentType = {
	index: number;
};

const natureOfClaimTranslations: Record<NatureOfClaim, string> = {
	FIRE: "Incendie",
	THEFT: "Vol",
	WATER_DAMAGE: "Dégâts des eaux",
	NATURAL_DISASTER: "Catastrophe Naturelle",
	PROFESSIONAL_LIABILITY: "Responsabilité Civile Professionnelle",
	OPERATIONAL_LIABILITY: "Responsabilité Civile Exploitation",
};

export const ClaimComponent = ({ index }: ClaimComponentType) => {
	const { insuredEstablishments } = formStore((state) => state.establishments);

	if (!insuredEstablishments) return;

	const establishmentOptions = insuredEstablishments.map((establishment) => ({
		value: establishment.siret,
		label: `${establishment.postalCode} ${establishment.city} | ${formatSiret(establishment.siret)}`,
	}));

	const natureOfClaimOptions = [
		...Object.entries(NatureOfClaim).map(([value, key]) => ({
			value,
			label: natureOfClaimTranslations[key as NatureOfClaim],
		})),
	];

	return (
		<div className="mb-4 w-full overflow-hidden rounded-md border border-gray-200">
			<h2 className="border-b border-[#E4E4E4] bg-gray-50 p-4 text-xl font-semibold">
				Sinistre n°{String(index + 1).padStart(2, "0")}
			</h2>
			<div className="space-y-4 p-4">
				<div className="grid grid-cols-1 gap-4 md:grid-cols-2">
					<SelectInput
						label="Précisez la nature du sinistre"
						name={`claimsArray.${index}.natureOfClaim`}
						options={natureOfClaimOptions}
						required
					/>
					<SelectInput
						label="Établissement lié à ce sinistre"
						name={`claimsArray.${index}.establishmentLinkedToClaim`}
						options={establishmentOptions}
						required
					/>
				</div>
			</div>
		</div>
	);
};
