import type { TableData } from "@/components/quotePdf/Table";
import { styles } from "@/components/quotePdf/styles";
import ThreeColumnTable from "@/components/quotePdf/Table";
import {
	addSpacingBetweenThousandsToNumber,
	addSpacingBetweenThousandsToString,
} from "@/utils";
import { StyleSheet, Text, View } from "@react-pdf/renderer";

import type {
	EstablishmentDto,
	RelatedActivityGuarantyDto,
	SubscriptionDetailsForQuoteDto,
} from "@repos/mrp-dtos";

const localStyles = StyleSheet.create({
	tableHeader: {
		color: "red",
		fontSize: 14,
		textAlign: "center",
		border: "1px solid black",
		padding: 5,
		borderBottomWidth: 0,
	},
});

const EstablishmentsContentDescription = ({
	establishments,
	label,
	customCalculation,
	guaranteeType,
	...props
}: {
	establishments: EstablishmentDto[];
	label: string;
	customCalculation?: (establishment: EstablishmentDto) => number;
	guaranteeType?: keyof RelatedActivityGuarantyDto;
} & SubscriptionDetailsForQuoteDto) => {
	return (
		<View style={{ display: "flex", flexDirection: "column", gap: 10 }}>
			{establishments.map((establishment, index) => (
				<View key={index}>
					<Text style={[styles.bodyText, styles.medium, styles.underline]}>
						Local {index === 0 ? "principal" : `n°${index + 1}`} :
					</Text>
					<Text>
						{label} :{" "}
						{addSpacingBetweenThousandsToNumber(
							customCalculation
								? customCalculation(establishment)
								: props.relatedGuaranteesInputs[index] &&
									  guaranteeType &&
									  //eslint-disable-next-line
									  //@ts-ignore
									  props.relatedGuaranteesInputs[index][guaranteeType] &&
									  //eslint-disable-next-line
									  // @ts-ignore
									  props.relatedGuaranteesInputs[index][guaranteeType]
											.insuredCapital
									? //eslint-disable-next-line
										props.relatedGuaranteesInputs[index]![guaranteeType]
											.insuredCapital!
									: 0,
						)}{" "}
						€
					</Text>
				</View>
			))}
		</View>
	);
};

const EstablishmentsLocalsDescription = ({
	establishments,
	label,
	isClimatique,
	isEffondrement,
	// ...props
}: {
	establishments: EstablishmentDto[];
	label: string;
	isClimatique: boolean;
	isEffondrement?: boolean;
} & SubscriptionDetailsForQuoteDto) => {
	return (
		<View style={{ display: "flex", flexDirection: "column", gap: 10 }}>
			{establishments.map((establishment, index) => (
				<View key={index}>
					<Text style={[styles.bodyText, styles.medium, styles.underline]}>
						Local {index === 0 ? "principal" : `n°${index + 1}`} :
					</Text>
					<Text>
						{label} :{" "}
						{addSpacingBetweenThousandsToNumber(
							isClimatique
								? establishment.officesArea * establishment.valuePerM2
								: isEffondrement
									? // && props.relatedGuaranteesInputs &&
										// props.relatedGuaranteesInputs[index] !== undefined &&
										// //eslint-disable-next-line
										// // @ts-ignore
										// props.relatedGuaranteesInputs[index].collapse.subscribed ===
										// 	true
										// props.relatedGuaranteesInputs[index].collapse
										// 	.insuredCapital!
										establishment.insuredStockContent +
										establishment.professionalContent
									: establishment.officesArea * establishment.valuePerM2,
						)}{" "}
						€
					</Text>
				</View>
			))}
		</View>
	);
};

const PropertyInsurances = ({ ...props }: SubscriptionDetailsForQuoteDto) => {
	const selectedOptionsData: TableData = [];
	if (props.isRisqueDiversChosen) {
		selectedOptionsData.push({
			type: "section",
			data: "Incendie, Explosion, Evènements climatiques Risques divers",
		});
		selectedOptionsData.push({
			type: "row",
			data: [
				"LOCAUX",
				<View
					key="1-3"
					style={{
						display: "flex",
						flexDirection: "column",
						gap: 10,
					}}
				>
					<EstablishmentsLocalsDescription
						establishments={props.insuredEstablishments}
						label={"Limite Contractuelle D’indemnisation"}
						isClimatique={false}
						{...props}
					/>
					<Text>Avec les sous-limites :</Text>
					<View style={[{ display: "flex", flexDirection: "column", gap: 5 }]}>
						<Text>
							- Panneaux solaires et photovoltaïques : 30 000 € par sinistre et
							par année d’assurance
						</Text>
						<Text>- Stores et bannes : 10 fois l’indice*</Text>
					</View>
				</View>,
				<View
					key="1-3"
					style={{
						display: "flex",
						flexDirection: "column",
						gap: 10,
					}}
				>
					<View>
						<Text style={styles.bold}>FRANCHISE GÉNÉRALE : </Text>
						<Text>
							{addSpacingBetweenThousandsToString(props.deductible)} €
						</Text>
					</View>
					<View>
						<Text style={styles.bold}>
							FRANCHISES PANNEAUX SOLAIRES ET PHOTOVOLTAÏQUES :
						</Text>
						<Text>1 500 €</Text>
					</View>
					<View>
						<Text style={styles.bold}>FRANCHISE SPÉCIFIQUE (1) :</Text>
						<Text>10 % des dommages</Text>
						<Text>- mini : 0,75 fois l’indice *</Text>
						<Text>- maxi : 3 fois l’indice*</Text>
					</View>
				</View>,
			],
		});
		selectedOptionsData.push({
			type: "row",
			data: [
				"CONTENU",
				<View
					key="1-3"
					style={{
						display: "flex",
						flexDirection: "column",
						gap: 10,
					}}
				>
					<EstablishmentsContentDescription
						{...props}
						establishments={props.insuredEstablishments}
						label={"Montant garantie du contenu"}
						customCalculation={(establishment) =>
							establishment.professionalContent +
							establishment.insuredStockContent
						}
					/>
					<Text>Avec les sous-limites :</Text>
					<View style={[{ display: "flex", flexDirection: "column", gap: 5 }]}>
						<Text>- Objets d’art et de décoration : 20 % de ce montant</Text>
						<Text>
							- Machines et instruments professionnels et marchandises chez des
							tiers ou sur chantier : 5 % de ce montant
						</Text>
						<Text>- Mobilier personnel : 16 fois l’indice*</Text>
						<Text>- Espèces, titres et valeurs : 8 fois l’indice*</Text>
						<Text>- Frais annexes de démolition et de déblai :</Text>
						<Text>
							• Si les locaux sont reconstruits ou réparés : pas de montant
							maximum.
						</Text>
						<Text>
							•Si les locaux ne sont pas reconstruits ou réparés : 10 % de
							l’indemnité pour dommages aux locaux.
						</Text>

						<Text>
							- Frais annexes de reconstitution d’archives : 10% des dommages
							dans la limite de 10 000 €
						</Text>
						<Text>
							- Frais consécutifs : 20 % de l’indemnité pour dommages aux biens
							(locaux et/ou contenu)
						</Text>
						<Text>
							- Honoraires et frais d’expert d’assurés : 5 % du montant des
							dommages
						</Text>
					</View>
				</View>,
				<View
					key="2-3"
					style={{
						display: "flex",
						flexDirection: "column",
						gap: 10,
					}}
				>
					{/* FRANCHISE GÉNÉRALE */}
					<View>
						<Text style={styles.bold}>FRANCHISE GÉNÉRALE : </Text>
						<Text>
							{addSpacingBetweenThousandsToString(props.deductible)} €
						</Text>
					</View>

					{/* FRANCHISE SPÉCIFIQUE (1) */}
					<View>
						<Text style={styles.bold}>FRANCHISE SPÉCIFIQUE (1) :</Text>
						<Text>10 % des dommages</Text>
						<Text>- mini : 0,75 fois l’indice *</Text>
						<Text>- maxi : 3 fois l’indice*</Text>
					</View>
				</View>,
			],
		});
	}
	//TODO:change this
	//eslint-disable-next-line
	if (true) {
		selectedOptionsData.push({
			type: "section",
			data: "",
		});

		selectedOptionsData.push({
			type: "section",
			data: "Événements climatiques",
		});
		selectedOptionsData.push({
			type: "row",
			data: [
				"LOCAUX",
				<View
					key="1-3"
					style={{
						display: "flex",
						flexDirection: "column",
						gap: 10,
					}}
				>
					<EstablishmentsLocalsDescription
						establishments={props.insuredEstablishments}
						label="Limite Contractuelle D’indemnisation"
						isClimatique={true}
						{...props}
					/>
					<Text>Avec les sous-limites :</Text>
					<View style={[{ display: "flex", flexDirection: "column", gap: 5 }]}>
						<Text>
							- Panneaux solaires et photovoltaïques : 30 000 € par sinistre et
							par année d’assurance
						</Text>
						<Text>- Stores et bannes : 10 fois l’indice*</Text>
					</View>
				</View>,
				<View
					key="4-3"
					style={{
						display: "flex",
						flexDirection: "column",
						gap: 10,
					}}
				>
					<Text>
						<Text style={styles.bold}>FRANCHISE INONDATION :</Text>10% des
						dommages
					</Text>
					<Text>- mini : 1 fois l’indice*</Text>
					<Text>
						<Text style={styles.bold}>
							FRANCHISES PANNEAUX SOLAIRES ET PHOTOVOLTAÏQUES :
						</Text>{" "}
						<Text>1 500 €</Text>
					</Text>
					<View
						style={{
							display: "flex",
							flexDirection: "column",
							gap: 5,
						}}
					>
						<Text>
							<Text style={styles.bold}>FRANCHISE SPÉCIFIQUE :</Text>
							10 % des dommages
						</Text>
						<Text>- mini : 0,75 fois l’indice *</Text>
						<Text>- maxi : 3 fois l’indice*</Text>
					</View>
				</View>,
			],
		});
		selectedOptionsData.push({
			type: "row",
			data: [
				"CONTENU",
				<View
					key="1-3"
					style={{
						display: "flex",
						flexDirection: "column",
						gap: 10,
					}}
				>
					<EstablishmentsContentDescription
						{...props}
						establishments={props.insuredEstablishments}
						label="Montant garantie du contenu"
						customCalculation={(establishment) =>
							establishment.insuredStockContent +
							establishment.professionalContent
						}
					/>
					<Text>Avec les sous-limites :</Text>
					<View style={[{ display: "flex", flexDirection: "column", gap: 5 }]}>
						<Text>- Objets d’art et de décoration : 20 % de ce montant</Text>
						<Text>- Mobilier personnel : 16 fois l’indice*</Text>
						<Text>
							- Espèces, titres et valeurs : 8 fois l’indice*, et sans pouvoir
							excéder 31 fois l’indice* en cas de dommages dus aux effets du gel{" "}
						</Text>
						<Text>Frais annexes de démolition et de déblai :</Text>
						<Text>
							• Si les locaux sont reconstruits ou réparés : pas de montant
							maximum.
						</Text>
						<Text>
							• Si les locaux ne sont pas reconstruits ou réparés : 10 % de
							l’indemnité pour dommages aux locaux.
						</Text>
						<Text>
							- Frais annexes de reconstitution d’archives : 10% des dommages
							dans la limite de 10 000 €
						</Text>
						<Text>
							- Frais consécutifs : 20 % de l’indemnité pour dommages aux biens
							(locaux et/ou contenu)
						</Text>
						<Text>
							- Honoraires et frais d’expert d’assurés: 5 % du montant des
							dommages
						</Text>
					</View>
				</View>,
				<View
					key="2-3"
					style={{
						display: "flex",
						flexDirection: "column",
						gap: 10,
					}}
				>
					{/* FRANCHISE GÉNÉRALE */}
					<View>
						<Text style={styles.bold}>FRANCHISE GÉNÉRALE : </Text>
						<Text>
							{addSpacingBetweenThousandsToString(props.deductible)} €
						</Text>
					</View>

					{/* FRANCHISE SPÉCIFIQUE (1) */}
					<View>
						<Text style={styles.bold}>FRANCHISE SPÉCIFIQUE (1) :</Text>
						<Text>10 % des dommages</Text>
						<Text>- mini : 0,75 fois l’indice *</Text>
						<Text>- maxi : 3 fois l’indice*</Text>
					</View>
				</View>,
			],
		});
	}
	if (props.isEffondrementChosen) {
		selectedOptionsData.push({ type: "section", data: "Effondrement" });
		selectedOptionsData.push({
			type: "row",
			data: [
				"LOCAUX",
				"Montant défini dans la limite de 1 500 000 €",
				<Text key="5-3">
					<Text style={styles.bold}>FRANCHISE SPÉCIFIQUE :</Text> 10% des
					dommages
				</Text>,
			],
		});
		selectedOptionsData.push({
			type: "row",
			data: [
				"CONTENU",
				<View
					key="1-3"
					style={{
						display: "flex",
						flexDirection: "column",
						gap: 10,
					}}
				>
					<EstablishmentsLocalsDescription
						establishments={props.insuredEstablishments}
						label="Montant garantie du contenu"
						isClimatique={false}
						isEffondrement={true}
						{...props}
					/>
					<Text>Avec les sous-limites :</Text>
					<View style={[{ display: "flex", flexDirection: "column", gap: 5 }]}>
						<Text>- Objets d’art et de décoration : 20 % de ce montant</Text>
						<Text>- Mobilier personnel : 16 fois l’indice*</Text>
						<Text>
							- Espèces, titres et valeurs : 8 fois l’indice*, et sans pouvoir
							excéder 31 fois l’indice* en cas de dommages dus aux effets du gel{" "}
						</Text>
						<Text>Frais annexes de démolition et de déblai :</Text>
						<Text>
							• Si les locaux sont reconstruits ou réparés : pas de montant
							maximum.
						</Text>
						<Text>
							• Si les locaux ne sont pas reconstruits ou réparés : 10 % de
							l’indemnité pour dommages aux locaux.
						</Text>
						<Text>
							- Frais annexes de reconstitution d’archives : 10% des dommages
							dans la limite de 10 000 €
						</Text>
						<Text>
							- Frais consécutifs : 20 % de l’indemnité pour dommages aux biens
							(locaux et/ou contenu)
						</Text>
						<Text>
							- Honoraires et frais d’expert d’assurés: 5 % du montant des
							dommages
						</Text>
					</View>
				</View>,
				<View key="5-3">
					<Text>
						<Text style={styles.bold}>FRANCHISE SPÉCIFIQUE :</Text>
					</Text>
					<Text>Mini : 0,75 fois l’indice* </Text>
					<Text>Max : 3 fois l’indice*</Text>
				</View>,
			],
		});
	}
	if (props.isDommagesElectriquesChosen) {
		selectedOptionsData.push({
			type: "section",
			data: "Dommages électriques",
		});
		selectedOptionsData.push({
			type: "row",
			data: [
				"ÉQUIPEMENT, MACHINES ET INSTRUMENTS PROFESSIONNELS",
				<View
					key="1-3"
					style={{
						display: "flex",
						flexDirection: "column",
						gap: 10,
					}}
				>
					<EstablishmentsContentDescription
						{...props}
						establishments={props.insuredEstablishments}
						label="Montant garantie du contenu"
						guaranteeType="electricalDamage"
					/>
					<Text>Avec les sous-limites :</Text>
					<View style={[{ display: "flex", flexDirection: "column", gap: 5 }]}>
						<Text>
							- Panneaux solaires et photovoltaïques : 30 000 € par sinistre et
							par année d’assurance
						</Text>
					</View>
				</View>,
				<View
					key="7-3"
					style={{
						display: "flex",
						flexDirection: "column",
						gap: 10,
					}}
				>
					<Text>
						<Text style={styles.bold}>FRANCHISE GÉNÉRALE : </Text>
						<Text>
							{addSpacingBetweenThousandsToString(props.deductible)} €
						</Text>
					</Text>
					<Text>
						<Text style={styles.bold}>
							FRANCHISES PANNEAUX SOLAIRES ET PHOTOVOLTAÏQUES :
						</Text>
						<Text>1 500 €</Text>
					</Text>
				</View>,
			],
		});
	}
	if (props.isDegatsDesEaux) {
		selectedOptionsData.push({
			type: "section",
			data: "Dégâts des eaux",
		});
		selectedOptionsData.push({
			type: "row",
			data: [
				"LOCAUX",
				`Pas de montant maximum, sauf :
       - Panneaux solaires et photovoltaïques : 30 000 € par sinistre et par année d’assurance`,
				<View
					key="8-3"
					style={{
						display: "flex",
						flexDirection: "column",
						gap: 10,
					}}
				>
					<Text>
						<Text style={styles.bold}>FRANCHISE GÉNÉRALE : </Text>
						<Text>
							{addSpacingBetweenThousandsToString(props.deductible)} €
						</Text>
					</Text>
					<Text>
						<Text style={styles.bold}>
							FRANCHISES PANNEAUX SOLAIRES ET PHOTOVOLTAÏQUES :
						</Text>
						<Text>1 500 €</Text>
					</Text>
				</View>,
			],
		});
		selectedOptionsData.push({
			type: "row",
			data: [
				"CONTENU",
				<View
					key="1-3"
					style={{
						display: "flex",
						flexDirection: "column",
						gap: 10,
					}}
				>
					<EstablishmentsContentDescription
						{...props}
						establishments={props.insuredEstablishments}
						label="Montant garantie du contenu"
						guaranteeType="waterDamage"
					/>
					<br></br>
					<Text>Avec les sous-limites :</Text>
					<View style={[{ display: "flex", flexDirection: "column", gap: 5 }]}>
						<Text>- Objets d’art et de décoration : 20 % de ce montant</Text>
						<Text>
							- Machines et instruments professionnels et marchandises chez des
							tiers ou sur chantier : 5 % de ce montant
						</Text>
						<Text>- Mobilier personnel : 16 fois l’indice*</Text>
						<Text>
							- Espèces, titres et valeurs : 8 fois l’indice*, et sans pouvoir
							excéder :
						</Text>
						<Text>
							• 16 fois l’indice* en cas de dégâts par canalisation enterrée
						</Text>
						<Text>• 23 fois l’indice* en cas de dégâts par égout</Text>
						<Text>
							- Frais annexes de recherche de fuite sur canalisations
							intérieures : 8 fois l’indice*
						</Text>
						<Text>
							- Frais annexes de reconstitution d’archives : 10% des dommages
							dans la limite de 10 000 €
						</Text>
						<Text>
							- Frais consécutifs : 20 % de l’indemnité pour dommages aux biens
							(locaux et/ou contenu)
						</Text>
						<Text>
							- Honoraires et frais d’expert d’assurés: 5 % du montant des
							dommages
						</Text>
					</View>
				</View>,
				<Text key="9-3">
					<Text style={styles.bold}>FRANCHISE GÉNÉRALE : </Text>
					<Text>{addSpacingBetweenThousandsToString(props.deductible)} €</Text>
				</Text>,
			],
		});
	}
	if (props.isBrisDesGlaceChosen) {
		selectedOptionsData.push({ type: "section", data: "Bris de glace" });
		selectedOptionsData.push({
			type: "row",
			data: [
				`DEVANTURE,
FENÊTRES,
JOURNAUX,
LUMINEUX,
PANNEAUX
PUBLICITAIRES,
PORTES D’ACCÈS,
TOITURE VITRÉES,
VÉRANDAS,
ENSEIGNES,
PRODUITS
VERRIERS
INTÉRIEURS`,
				<View
					key="1-3"
					style={{
						display: "flex",
						flexDirection: "column",
						gap: 10,
					}}
				>
					<EstablishmentsContentDescription
						{...props}
						establishments={props.insuredEstablishments}
						label="Montant garantie du contenu"
						guaranteeType="glassBreakage"
					/>
					<br></br>
					<Text>Avec les sous-limites :</Text>
					<View style={[{ display: "flex", flexDirection: "column", gap: 5 }]}>
						<Text>
							- Panneaux solaires et photovoltaïques : 30 000 € par sinistre et
							par année d’assurance
						</Text>
						<Text>
							- Frais annexes de clôture et de gardiennage : 4 fois l’indice*
						</Text>
						<Text>
							- Frais annexes de peinture ou d’applications diverses : 4 fois
							l’indice*
						</Text>
						<Text>
							- Détériorations consécutives des locaux et du contenu : 8 fois
							l’indice*
						</Text>
						<Text>
							- Emeute : 50 % du montant de la garantie contenu Bris de glaces
						</Text>
					</View>
				</View>,
				<Text key="9-3">
					<Text style={styles.bold}>FRANCHISE GÉNÉRALE : </Text>
					<Text>{addSpacingBetweenThousandsToString(props.deductible)} €</Text>
				</Text>,
			],
		});
	}
	if (props.isVandalismeChosen) {
		selectedOptionsData.push({
			type: "section",
			data: "Vol et vandalisme",
		});

		selectedOptionsData.push({
			type: "row",
			data: [
				"LOCAUX",
				`Détériorations :
- À la suite de vol : montant de la garantie contenu Vol
- À la suite de vandalisme : 50 % montant de la garantie contenu
Vol dans la limite de 50 000 € maximum.

Avec les sous limites :
      - Panneaux solaires et photovoltaïques 30 000 € par sinistre et par année d’assurance
      - Frais annexes de clôture et de gardiennage : 4 fois l’indice*
      - Frais de remplacement des serrures en cas de vol des clés : 0.5 fois l’indice`,

				<View
					key="10-3"
					style={{
						display: "flex",
						flexDirection: "column",
						gap: 10,
					}}
				>
					<Text>
						<Text style={styles.bold}>FRANCHISE GÉNÉRALE : </Text>
						<Text>
							{addSpacingBetweenThousandsToString(props.deductible)} €
						</Text>
					</Text>
					<Text>
						<Text style={styles.bold}>
							FRANCHISES PANNEAUX SOLAIRES ET PHOTOVOLTAÏQUES :
						</Text>
						<Text>1 500 €</Text>
					</Text>
				</View>,
			],
		});
		selectedOptionsData.push({
			type: "row",
			data: [
				"CONTENU",
				<View
					key="1-3"
					style={{
						display: "flex",
						flexDirection: "column",
						gap: 10,
					}}
				>
					<EstablishmentsContentDescription
						{...props}
						establishments={props.insuredEstablishments}
						label="Montant garantie du contenu"
						guaranteeType="theft"
					/>
					<Text>Avec les sous-limites :</Text>
					<View style={[{ display: "flex", flexDirection: "column", gap: 5 }]}>
						<Text>- Objets d’art et de décoration : 20 % de ce montant</Text>
						<Text>- Objets précieux portés : 3 fois l’indice*</Text>
						<Text>
							- Mobilier personnel : 16 fois l’indice* et sans pouvoir excéder
							10 % de ce montant en cas de vol de biens en vitrine par
							effraction sans pénétration dans les locaux.
						</Text>
						<Text>
							- Vandalisme sur mobiliers et immobilier intérieur : 50 % montant
							de la garantie contenu Vol dans la limite de 50 000 €.
						</Text>
						<Text>
							- Vandalisme sur mobilier extérieur : 20 % montant de la garantie
							contenu Vol dans la limite de 50 000 €.
						</Text>
						<Text>- Emeute : 50 % du montant de la garantie contenu Vol</Text>
						<Text>
							- Frais de remplacement des serrures des véhicules professionnels
							en cas de vol des clés dans le local : 0.5 fois l’indice* par
							véhicule.
						</Text>
						<Text>
							- Frais annexes de reconstitution d’archives : 10% des dommages
							dans la limite de 10 000 €
						</Text>
					</View>
				</View>,
				<View
					key="10-3"
					style={{
						display: "flex",
						flexDirection: "column",
						gap: 10,
					}}
				>
					<Text>
						<Text style={styles.bold}>FRANCHISE GÉNÉRALE : </Text>
						<Text>
							{addSpacingBetweenThousandsToString(props.deductible)} €
						</Text>
					</Text>
				</View>,
			],
		});
		selectedOptionsData.push({
			type: "row",
			data: [
				"ESPÈCES, TITRES ET VALEURS",
				<View
					key="1-3"
					style={{
						display: "flex",
						flexDirection: "column",
						gap: 10,
					}}
				>
					<View style={[{ display: "flex", flexDirection: "column", gap: 5 }]}>
						<Text>
							En meuble(s) fermé(s) à clé ou en tiroir(s)-caisse(s) dans la
							limite de 1 500 €.
						</Text>
						<br></br>
						<Text>
							En cas d’effraction sans agression pendant la fermeture des locaux
							ou à votre domicile : 1 500 €.
						</Text>
						<br></br>
						<Text>
							En coffres-forts en cas d’effraction ou d’agression : dans la
							limite de 8 000 €.
						</Text>
						<br></br>
						<Text>
							Lors des transports de fonds en cas d’effraction ou d’agression :
							1 500 €.
						</Text>
					</View>
				</View>,
				<View
					key="10-3"
					style={{
						display: "flex",
						flexDirection: "column",
						gap: 10,
					}}
				>
					<Text>
						<Text style={styles.bold}>FRANCHISE GÉNÉRALE : </Text>
						<Text>
							{addSpacingBetweenThousandsToString(props.deductible)} €
						</Text>
					</Text>
				</View>,
			],
		});
	}
	if (props.isBrisDeMachine) {
		selectedOptionsData.push({
			type: "section",
			data: "Bris de machine",
		});
		selectedOptionsData.push({
			type: "row",
			data: [
				"CONTENU",
				<View
					key="1-3"
					style={{
						display: "flex",
						flexDirection: "column",
						gap: 10,
					}}
				>
					<EstablishmentsContentDescription
						{...props}
						establishments={props.insuredEstablishments}
						label="Machines, équipements professionnels et informatiques"
						guaranteeType="machineBreakage"
					/>
					<Text>Avec les sous-limites :</Text>
					<View style={[{ display: "flex", flexDirection: "column", gap: 5 }]}>
						<Text>
							- Supports informatiques d’information : 10 % du montant de la
							garantie bris de machine.
						</Text>
						<Text>
							- Panneaux solaires et photovoltaïques : 30 000 € par sinistre et
							par année d’assurance
						</Text>
						<Text>
							- Frais annexes de reconstitution d’archives : 10 % de la garantie
							bris de machine.
						</Text>
						<Text>
							- Honoraires et frais d’expert d’assurés : 5 % du montant des
							dommages.
						</Text>
					</View>
				</View>,
				<View
					key="10-3"
					style={{
						display: "flex",
						flexDirection: "column",
						gap: 10,
					}}
				>
					<Text>
						<Text style={styles.bold}>FRANCHISE GÉNÉRALE : </Text>
						<Text>
							{addSpacingBetweenThousandsToString(props.deductible)} €
						</Text>
					</Text>
					<Text>
						<Text style={styles.bold}>
							FRANCHISE PANNEAUX SOLAIRES ET PHOTOVOLTAÏQUES :
						</Text>
						1 500 €
					</Text>
				</View>,
			],
		});
	}
	if (props.isInstallationFrigorifiqueChosen) {
		selectedOptionsData.push({
			type: "section",
			data: "Perte de marchandises en installation frigorifique",
		});
		selectedOptionsData.push({
			type: "row",
			data: [
				"CONTENU",
				<View
					key="1-3"
					style={{
						display: "flex",
						flexDirection: "column",
						gap: 10,
					}}
				>
					<EstablishmentsContentDescription
						{...props}
						label={"Marchandises"}
						establishments={props.insuredEstablishments}
						guaranteeType="refrigeratedGoods"
					/>
					<Text>Avec les sous-limites :</Text>
					<View style={[{ display: "flex", flexDirection: "column", gap: 5 }]}>
						<Text>
							- Frais annexes de sauvetage : 20 % de l’indemnité pour la perte
							des marchandises.
						</Text>
						<br></br>
						<br></br>
						<br></br>
						<br></br>
						<br></br>
					</View>
				</View>,
				<View
					key="10-3"
					style={{
						display: "flex",
						flexDirection: "column",
						gap: 10,
					}}
				>
					<Text>
						<Text style={styles.bold}>FRANCHISE SPÉCIFIQUE :</Text>
						10% des dommages
					</Text>
					<Text>
						<Text style={styles.bold}>- mini :</Text>
						franchise générale
					</Text>
				</View>,
			],
		});
	}
	if (props.isMMTChosen) {
		selectedOptionsData.push({
			type: "section",
			data: "Marchandises et matériels transportés",
		});
		selectedOptionsData.push({
			type: "row",
			data: [
				"CONTENU",
				<View
					key="1-3"
					style={{
						display: "flex",
						flexDirection: "column",
						gap: 10,
					}}
				>
					<EstablishmentsContentDescription
						{...props}
						establishments={props.insuredEstablishments}
						label="Marchandises, machines et instruments professionnels transportés"
						guaranteeType="transportedGoods"
					/>
				</View>,
				<View
					key="10-3"
					style={{
						display: "flex",
						flexDirection: "column",
						gap: 10,
					}}
				>
					<Text>
						<Text style={styles.bold}>FRANCHISE SPÉCIFIQUE :</Text>
						10% des dommages
					</Text>
					<Text>
						<Text style={styles.bold}>- mini :</Text>
						franchise générale
					</Text>
				</View>,
			],
		});
	}
	if (props.isInstallationsExterieursChosen) {
		selectedOptionsData.push({
			type: "section",
			data: "Installations Extérieures",
		});
		selectedOptionsData.push({
			type: "row",
			data: [
				"CONTENU",
				<View
					key="1-3"
					style={{
						display: "flex",
						flexDirection: "column",
						gap: 10,
					}}
				>
					<EstablishmentsContentDescription
						{...props}
						establishments={props.insuredEstablishments}
						label="Contenu professionnel, équipements, structures et installations
situés à l'extérieur des bâtiments"
						guaranteeType="externalInstallation"
					/>
				</View>,
				<View
					key="10-3"
					style={{
						display: "flex",
						flexDirection: "column",
						gap: 10,
					}}
				>
					<Text>
						<Text style={styles.bold}>FRANCHISE SPÉCIFIQUE :</Text>
						10% des dommages
					</Text>
					<Text>
						<Text style={styles.bold}>- mini :</Text>2 fois l’indice*
					</Text>
				</View>,
			],
		});
	}
	if (props.isSFMChosen) {
		selectedOptionsData.push({
			type: "section",
			data: "Salons, foires et manifestations",
		});
		selectedOptionsData.push({
			type: "row",
			data: [
				"CONTENU",
				<View
					key="1-3"
					style={{
						display: "flex",
						flexDirection: "column",
						gap: 10,
					}}
				>
					<Text>
						Biens et effets personnels en salon, foires et manifestation : 6
						fois l’indice* sauf ESPÈCES, TITRES ET VALEURS
					</Text>
				</View>,
				<View
					key="10-3"
					style={{
						display: "flex",
						flexDirection: "column",
						gap: 10,
					}}
				>
					<Text>
						<Text style={styles.bold}>FRANCHISE SPÉCIFIQUE :</Text>
						<Text>
							{addSpacingBetweenThousandsToString(props.deductible)} €
						</Text>
					</Text>
				</View>,
			],
		});
	}
	if (selectedOptionsData.length === 0) {
		selectedOptionsData.push({
			type: "section",
			data: "Aucune garantie n'a été souscrite",
		});
	}
	return (
		<>
			<Text style={[styles.sectionHeader, styles.mt_10]}>
				LES GARANTIES ET OPTION(S) RETENUES
			</Text>
			<Text style={[styles.bodyText, styles.mb_10]}>
				{`Seules les garanties mentionnées dans le tableau ci-dessous sont souscrites, dans la limite des plafonds indiqués et
sous réserve des limites particulières indiquées aux Conditions Générales référencées ${props.generalProvisionsReference}`}
			</Text>
			<Text style={[styles.bold, localStyles.tableHeader]}>
				Assurances des biens
			</Text>
			<ThreeColumnTable
				columns={[
					{ header: "Garanties", width: 15, grow: 1 },
					{
						header: "Limites d’indemnisation par sinistre",
						width: 55,
						grow: 2,
					},
					{ header: "Franchise par sinistre", width: 30, grow: 1 },
				]}
				data={selectedOptionsData}
			/>
		</>
	);
};

export default PropertyInsurances;
