import { styles } from "@/components/quotePdf/styles";
import { Text, View } from "@react-pdf/renderer";

import type { EstablishmentDto } from "@repos/mrp-dtos";

import { formatSiret } from "../../utils/utils";

const EstablishmentInfo = ({ ...establishment }: EstablishmentDto) => (
	<View>
		<Text style={[styles.bodyText, styles.max1Line]}>
			<Text style={[styles.bodyText, styles.bold]}>• SIRET :</Text>{" "}
			{formatSiret(establishment.siret)}
		</Text>
		<Text style={[styles.bodyText, styles.max1Line]}>
			<Text style={[styles.bodyText, styles.bold]}>• Adresse :</Text>{" "}
			{`${establishment.address.fullAddress} ${establishment.address.postalCode} ${establishment.address.city} `}
		</Text>
		<Text style={[styles.bodyText, styles.max1Line]}>
			<Text style={[styles.bodyText, styles.bold]}>• Activités :</Text>
		</Text>
		{establishment.activities.map((activity, index) => (
			<View style={{ paddingLeft: 20 }} key={index}>
				<Text style={[styles.subSectionText, styles.max1Line]}>
					<Text style={[styles.bodyText, styles.bold]}>
						- Activité n°{index + 1} :
					</Text>{" "}
				</Text>
				<View style={{ paddingLeft: 20 }}>
					<Text style={[styles.bodyText, styles.max1Line]}>
						<Text style={[styles.bodyText, styles.bold]}>- Code Naf :</Text>{" "}
						{activity.nafCode} | {activity.fieldLabel}
					</Text>
					<Text style={[styles.bodyText, styles.max1Line]}>
						<Text style={[styles.bodyText, styles.bold]}>
							- Domaine d’activité :
						</Text>{" "}
						{activity.familyLabel}
					</Text>
					<Text style={[styles.bodyText, styles.max1Line]}>
						<Text style={[styles.bodyText, styles.bold]}>
							- Activité principale déclarée :
						</Text>{" "}
						{activity.nafCodeLabel}
					</Text>
				</View>
			</View>
		))}
	</View>
);

export default EstablishmentInfo;
