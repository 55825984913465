import { z } from "zod";

const addressSchema = z.object({
	postalCode: z.string(),
	city: z.string(),
	country: z.string(),
	streetLabel: z.string(),
});

const paiementDetailsSchema = z.object({
	paymentMethod: z.string(),
	fractionPaiment: z.string(),
	fileFees: z.number(),
	totalAnnualPremiumPIT: z.number(),
	catastropheNaturellePremium: z.number(),
});

const courtierSchema = z.object({
	email: z.string().email(),
	siren: z.number(),
	orias: z.number(),
	businessName: z.string(),
	address: addressSchema,
	firstName: z.string(),
	lastName: z.string(),
});

const quoteDetailsSchema = z.object({
	reference: z.string(),
	effectiveDate: z.string().datetime(),
	status: z.string(),
});

export const sendQuoteToPyaloadSchema = z.object({
	data: z.object({
		insuredCompanies: z.array(
			z.object({
				companyName: z.string(),
				email: z.string(),
				phoneNumber: z.string(),
				siren: z.number(),
				siret: z.number(),
				fullAddress: z.string(),
				postalCode: z.string(),
				city: z.string(),
				country: z.string(),
				establishments: z.array(z.any()),
			}),
		),
		insuredUsers: z.array(
			z.object({
				Mr: z.boolean(),
				Mrs: z.boolean(),
				firstName: z.string().min(1, "First name is required"),
				lastName: z.string().min(1, "Last name is required"),
				emailAddress: z.string().email("Invalid email address format"),
				phoneNumber: z.string(),
			}),
		),
		paiementDetails: paiementDetailsSchema,
		courtier: courtierSchema,
		quoteDetails: quoteDetailsSchema,
		submission: z.record(z.any()),
	}),
});

export type SendQuoteToPyaload = z.infer<typeof sendQuoteToPyaloadSchema>;
