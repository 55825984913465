import { useState } from "react";
import { HeaderFooter } from "@/components/quotePdf/HeaderFooter.tsx";
import LegalMentions from "@/components/quotePdf/LegalMentions.tsx";
import OtherDispositions from "@/components/quotePdf/OtherDispositions.tsx";
import { styles } from "@/components/quotePdf/styles.ts";
import Subscription from "@/components/quotePdf/Subscription.tsx";
import WarrantySummary from "@/components/quotePdf/WarrantySummary.tsx";
import { Page0 } from "@/pages/devis/0";
import { Page1 } from "@/pages/devis/1";
import { InsuredEstablishmentsAndActivitiesPdfPage } from "@/pages/devis/2/InsuredEstablishmentsAndActivitiesPdfPage.tsx";
import { InsuredEstablishmentsPdfPage } from "@/pages/devis/4/InsuredEstablishmentsPdfPage.tsx";
import { DeclarationsPdfPage } from "@/pages/devis/5/DeclarationsPdfPage.tsx";
import { GuaranteesAndOptionsPdfPage } from "@/pages/devis/6/GuaranteesAndOptionsPdfPage.tsx";
import { addSpacingBetweenThousandsToString, trpc } from "@/utils";
import {
	Document,
	Font,
	Page,
	PDFViewer,
	Text,
	View,
} from "@react-pdf/renderer";
import { useNavigate } from "react-router-dom";

import type { SubscriptionDetailsForQuoteDto } from "@repos/mrp-dtos";

import RobotoFontBold from "/fonts/Roboto-Bold.ttf";
import RobotoFontMedium from "/fonts/Roboto-Medium.ttf";
import RobotoFont from "/fonts/Roboto-Regular.ttf";

interface ClauseDefinition {
	codes: string[];
	reference: string;
	text: string;
}

Font.register({
	family: "Roboto",
	fonts: [
		{
			src: RobotoFont,
			fontWeight: 400,
		},
		{
			src: RobotoFontMedium,
			fontWeight: 500,
		},
		{
			src: RobotoFontBold,
			fontWeight: 700,
		},
	],
});

const MainDocument = ({ ...props }: SubscriptionDetailsForQuoteDto) => {
	const [numPages, setNumPages] = useState(0);

	const allNafCodes = [
		props.companyInfo.principalActivity.nafCode,
		...props.insuredEstablishments.flatMap((establishment) =>
			establishment.activities.map((activity) => activity.nafCode),
		),
	].filter(Boolean);

	const allActivities = [...new Set(allNafCodes)];

	const CLAUSES_CONTRAT: ClauseDefinition[] = [
		{
			codes: ["47.75Z"],
			reference: "101",
			text: "Parfumerie (référence 101)\nSi le commerce a pour activité la vente de parfum, les moyens de protection demandés seront de niveau 3 et 4 obligatoire dès le 1er euro en garantie vol.",
		},
		{
			codes: ["47.25Z", "56.30Z", "46.17B", "46.35Z", "47.26Z"],
			reference: "102",
			text: "Vente de tabac (référence 102)\nSi le commerce a pour activité la vente de tabac, les moyens de protection demandés seront de niveau 3 obligatoire dès le 1er euro pour la garantie vol.",
		},
		{
			codes: [
				"10.72Z",
				"10.73Z",
				"10.82Z",
				"10.83Z",
				"11.01Z",
				"11.05Z",
				"25.71Z",
				"33.12Z",
				"33.20A",
				"33.20B",
				"33.20D",
				"41.10A",
				"41.10B",
				"41.10C",
				"43.21A",
				"43.21B",
				"43.22A",
				"43.22B",
				"43.29A",
				"43.31Z",
				"43.32A",
				"43.32B",
				"43.32C",
				"43.33Z",
				"43.34Z",
				"43.39Z",
				"43.91A",
				"43.91B",
				"43.99A",
				"43.99C",
				"43.99E",
				"63.91Z",
				"64.99Z",
				"65.11Z",
				"65.12Z",
				"65.20Z",
				"65.30Z",
				"66.11Z",
				"66.12Z",
				"66.19A",
				"66.19B",
				"66.21Z",
				"66.22Z",
				"66.30Z",
				"69.10Z",
				"69.20Z",
				"70.10Z",
				"70.21Z",
				"70.22Z",
				"71.11Z",
				"71.12A",
				"71.12B",
				"73.11Z",
				"73.12Z",
				"73.20Z",
				"74.10Z",
				"74.30Z",
				"74.90A",
				"74.90B",
				"78.10Z",
				"78.30Z",
				"90.03B",
				"58.12Z",
				"62.20Z",
				"64.20Z",
				"80.20Z",
				"80.30Z",
				"81.21Z",
				"81.22Z",
				"82.11Z",
				"82.19Z",
				"82.20Z",
				"82.91Z",
				"82.99Z",
				"85.59B",
				"85.59A",
				"85.60Z",
				"88.10A",
				"88.10C",
				"97.00Z",
				"98.10Z",
				"98.20Z",
				"41.10A",
				"41.10B",
				"41.10C",
				"68.10Z",
				"68.20A",
				"68.31Z",
				"68.32A",
				"68.32B",
			],
			reference: "201",
			text: `Clause 201 :\nToutes activités relevant de l'activité décennale ne seront pas garanties en responsabilité civile professionnelle et décennale.`,
		},
		{
			codes: [
				"10.72Z",
				"10.73Z",
				"10.82Z",
				"10.83Z",
				"11.01Z",
				"11.05Z",
				"25.71Z",
				"33.12Z",
				"33.20A",
				"33.20B",
				"33.20D",
				"41.10A",
				"41.10B",
				"41.10C",
				"43.21A",
				"43.21B",
				"43.22A",
				"43.22B",
				"43.29A",
				"43.31Z",
				"43.32A",
				"43.32B",
				"43.32C",
				"43.33Z",
				"43.34Z",
				"43.39Z",
				"43.91A",
				"43.91B",
				"43.99A",
				"43.99C",
				"43.99E",
				"63.91Z",
				"64.99Z",
				"65.11Z",
				"65.12Z",
				"65.20Z",
				"65.30Z",
				"66.11Z",
				"66.12Z",
				"66.19A",
				"66.19B",
				"66.21Z",
				"66.22Z",
				"66.30Z",
				"69.10Z",
				"69.20Z",
				"70.10Z",
				"70.21Z",
				"70.22Z",
				"71.11Z",
				"71.12A",
				"71.12B",
				"73.11Z",
				"73.12Z",
				"73.20Z",
				"74.10Z",
				"74.30Z",
				"74.90A",
				"74.90B",
				"78.10Z",
				"78.30Z",
				"90.03B",
				"58.12Z",
				"62.20Z",
				"64.20Z",
				"80.20Z",
				"80.30Z",
				"81.21Z",
				"81.22Z",
				"82.11Z",
				"82.19Z",
				"82.20Z",
				"82.91Z",
				"82.99Z",
				"85.59B",
				"85.59A",
				"85.60Z",
				"88.10A",
				"88.10C",
				"97.00Z",
				"98.10Z",
				"98.20Z",
				"41.10A",
				"41.10B",
				"41.10C",
				"68.10Z",
				"68.20A",
				"68.31Z",
				"68.32A",
				"68.32B",
			],
			reference: "202",
			text: `Clause 202 :\nL'usage et distribution d'E.P.E.R.S sousmise à cette même obligation ne seront pas garanties.`,
		},
		{
			codes: [
				"55.10Z",
				"55.20Z",
				"55.30Z",
				"55.90Z",
				"56.10A",
				"56.10B",
				"56.10C",
				"56.29B",
				"56.30Z",
			],
			reference: "402",
			text: `Clause 402 :\nEn incendie :\n- Toutes les installations électriques des locaux professionnels sont conformes aux prescriptions réglementaires et contrôlées annuellement par un électricien [réponse apportée : qualifié / certifié avec délivrance d'un certificat Q18 / Aucun contrôle].\nEn cas de sinistre, il sera demandé la facture avec le rapport et ou le certificat de la dernière vérification ainsi que les justificatifs de travaux préconisés par l'électricien.\n- Tous les extincteurs mobiles des locaux professionnels sont conformes aux prescriptions réglementaires et contrôlés annuellement par un professionnel [réponse apportée : qualifié / certifié avec délivrance d'un certificat Q4 / Aucun Contrôle].\nEn cas de sinistre, il sera demandé la facture avec le rapport et ou le certificat de la dernière vérification ainsi que les justificatifs de travaux préconisés par le professionnel. \nAs Solutions tolèrera un délai de 3 mois pour la mise en conformité des systèmes électriques et incendie. \nA défaut de mise en conformité dans les délais, le contrat sera résilié de plein droit par As Solutions. \nLe client devra fournir les justificatifs dans ce délai. `,
		},
	];

	const applicableClauses = CLAUSES_CONTRAT.filter((clause) =>
		clause.codes.some((code) => allActivities.includes(code)),
	);

	return (
		<Document
			onRender={(data) => {
				// @ts-expect-error property does exist
				setNumPages(data._INTERNAL__LAYOUT__DATA_.children.length);
			}}
		>
			<Page0 {...props} />
			<Page1 {...props} />
			<InsuredEstablishmentsAndActivitiesPdfPage {...props} />
			<InsuredEstablishmentsPdfPage {...props} />
			<DeclarationsPdfPage {...props} />
			<GuaranteesAndOptionsPdfPage {...props} />
			<Page size="A4" style={styles.page}>
				<HeaderFooter />
				<View style={styles.container}>
					<WarrantySummary {...props} />
					<View
						style={{
							display: "flex",
							flexDirection: "column",
							gap: 10,
							marginBottom: 30,
						}}
					>
						<Text style={[styles.bodyText, styles.medium]}>*indice FFB</Text>
					</View>
					<Text style={styles.sectionHeader}>LES FRANCHISES</Text>
					<Text style={[styles.bodyText]}>
						Vous avez sélectionné la Franchise Générale :{" "}
						{addSpacingBetweenThousandsToString(
							Number(props.deductible).toFixed(2),
						)}
						{" €."}
					</Text>
					<Text style={[styles.bodyText]}>
						Toutefois, si une franchise différente figure au « Tableau
						Récapitulatif des montants de garanties et de franchises » des
						Dispositions Générales ou dans une annexe jointe à votre contrat,
						c’est cette dernière qui s’applique.
					</Text>
					<Text style={[styles.bodyText]}>
						Pour les sinistres « Catastrophes naturelles », vous conservez à
						votre charge une franchise dont le montant est fixé par Arrêté.
					</Text>
					<Text style={[styles.sectionHeader, styles.mt_20]}>
						CLAUSE DE RÉVISION
					</Text>
					<Text style={[styles.bodyText]}>
						Le souscripteur s’engage à fournir annuellement à As Solutions les
						éléments de révision suivant :
					</Text>
					<Text style={[styles.bodyText]}>- La surface des bâtiments</Text>
					<Text style={[styles.bodyText]}>- Le chiffre d’affaires</Text>
					<Text style={[styles.bodyText]}>- L’effectif</Text>
					<Text style={[styles.bodyText]}>
						Le souscripteur s’engage à informer la compagnie dans les 15 jours
						suivant la modification du risque.
					</Text>
					<View break />
					<Subscription {...props} />
					<View break />
					<Text style={[styles.sectionHeader, styles.mb_10]}>
						CLAUSE AU PRESENT CONTRAT :
					</Text>
					{applicableClauses.map((clause) => (
						<Text key={clause.reference} style={[styles.bodyText]}>
							• {clause.text}
						</Text>
					))}
					<LegalMentions {...props} />
					<OtherDispositions numberOfPages={numPages} {...props} />
				</View>
			</Page>
		</Document>
	);
};

export const DevisPdfViewer = () => {
	const searchParams = new URLSearchParams(window.location.search);
	const subscriptionId = searchParams.get("subscription_id");
	const navigate = useNavigate();
	if (!subscriptionId) {
		navigate("/404");
	}
	const getSubscriptionPdf = trpc.quote.getQuoteDetails.useQuery({
		subscriptionId: subscriptionId!,
	});
	const data = getSubscriptionPdf.data;
	return (
		<PDFViewer className="h-[100vh] w-[100vw]">
			{data && <MainDocument {...data} />}
		</PDFViewer>
	);
};
export default DevisPdfViewer;
