import type { FieldValues, UseControllerProps } from "react-hook-form";
import TooltipIcon from "@/assets/TooltipIcon.svg";
import {
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "@/components/ui/form.tsx";
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "@/components/ui/select.tsx";
import { cn } from "@/utils";
import { useFormContext } from "react-hook-form";

import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from "../ui/tooltip";

export interface SelectOptionType {
	label: string;
	value: string;
}

interface SelectInputProps<T extends FieldValues, U extends SelectOptionType>
	extends UseControllerProps<T> {
	label: string;
	options: U[];
	required?: boolean;
	className?: string;
	placeholder?: string;
	labelWithTooltip?: boolean;
	tooltipDescription?: string;
	customOnChange?: (value: string) => void;
}

export function SelectInput<T extends FieldValues, U extends SelectOptionType>({
	label,
	name,
	required,
	options,
	disabled,
	className,
	placeholder,
	labelWithTooltip,
	tooltipDescription,
	customOnChange,
}: SelectInputProps<T, U>) {
	const { control } = useFormContext<T>();
	return (
		<FormField
			control={control}
			name={name}
			render={({ field }) => {
				return (
					<FormItem className={cn("flex w-full flex-col", className)}>
						<FormLabel
							className={cn(
								"flex items-end text-sm font-medium text-[#333333]",
								{ "text-black/40": disabled },
							)}
						>
							{labelWithTooltip && tooltipDescription ? (
								<TooltipProvider>
									<Tooltip>
										<span>
											{label}{" "}
											<TooltipTrigger type="button">
												<img
													src={TooltipIcon}
													className="size-3 cursor-pointer"
												/>
											</TooltipTrigger>
										</span>
										<TooltipContent>
											<div className="max-w-xs">{tooltipDescription}</div>
										</TooltipContent>
									</Tooltip>
								</TooltipProvider>
							) : (
								label
							)}
							{required && <span className="text-red-500">&nbsp;*</span>}
						</FormLabel>
						<Select
							disabled={disabled}
							onValueChange={(v) => {
								field.onChange(v);
								customOnChange?.(v);
							}}
							value={field.value?.toString()}
						>
							<FormControl>
								<SelectTrigger
									className={cn(
										"w-full rounded-md border border-gray-300 bg-white p-5 text-sm placeholder-gray-400 focus:border-transparent focus:outline-none data-[placeholder]:text-gray-400",
									)}
								>
									<SelectValue
										className="text-slate-300 placeholder:text-slate-400/20"
										placeholder={placeholder ?? ""}
									/>
								</SelectTrigger>
							</FormControl>
							<SelectContent>
								{options.map((option) => (
									<SelectItem
										className="text-black"
										key={option.value}
										value={option.value.toString()}
									>
										{option.label}
									</SelectItem>
								))}
							</SelectContent>
						</Select>
						<FormMessage />
					</FormItem>
				);
			}}
		/>
	);
}
