import type { z } from "zod";
import {
	LeaseClauses,
	OccupationStatus,
	SplittingOptionEnum,
} from "@prisma/client";

import type { OccupantTitleForPricing } from "@repos/mrp-dtos";

export const getLeaseClauseEnumValue = (leaseClause: LeaseClauses): string => {
	switch (leaseClause) {
		case LeaseClauses.NONE:
			return "Néant";
		case LeaseClauses.ACCOUNT:
			return "Assurance pour Compte";
		case LeaseClauses.MUTUAL_RENUNCIATION:
			return "Abandons de recours reciproque";
		case LeaseClauses.OWNER_RENUNCIATION:
			return "Abandons de recours envers Propriétaire";
		case LeaseClauses.TENANT_RENUNCIATION:
			return "Abandons de recours envers le locataire";
		default:
			return LeaseClauses.NONE;
	}
};

export const getOccupationStatusEnumLabel = (
	status: OccupationStatus,
): string => {
	switch (status) {
		case OccupationStatus.Owner:
			return "Propriétaire";
		case OccupationStatus.Tenant:
			return "Locataire";
		case OccupationStatus.WaiverOfRecourseLandlordVsTenant:
			return "Locataire avec renonciation à recours contre le locataire";
		case OccupationStatus.WaiverOfRecourseMutual:
			return "Locataire avec renonciation à recours réciproque";
		case OccupationStatus.AccountInsurance:
			return "Locataire avec assurance pour compte du propriétaire";
		case OccupationStatus.WaiverOfRecourseTenantVsLandlord:
			return "Locataire avec renonciation à recours contre le propriétaire";
		default:
			return OccupationStatus.Owner;
	}
};

export const getOccupantTitleForPrincing = (
	status: OccupationStatus,
): z.infer<typeof OccupantTitleForPricing> => {
	switch (status) {
		case OccupationStatus.Owner:
			return "Propriétaire";
		case OccupationStatus.Tenant:
			return "Locataire";
		case OccupationStatus.AccountInsurance:
			return "Assurance pour compte";
		case OccupationStatus.WaiverOfRecourseLandlordVsTenant:
			return "Abandon de recours - Propriétaire sur locataire";
		case OccupationStatus.WaiverOfRecourseTenantVsLandlord:
			return "Abandon de recours - Locataire sur propriétaire";
		case OccupationStatus.WaiverOfRecourseMutual:
			return "Abandon de recours - Réciproque";
		default:
			return "Propriétaire";
	}
};

export const getLegalFormLabelByCode = (code: string): string | undefined => {
	//data from business-types.ts in seedData
	switch (code) {
		case "XXXX":
			return "Micro entreprise";
		case "1000":
			return "Entreprise Individuelle (EI)";
		case "5498":
			return "Entreprise Individuelle à Responsabilité Limitée (EIRL)";
		case "54XX":
			return "Société à Responsabilité Limitée (SARL)";
		case "5720":
			return "Société par Actions Simplifiée Unipersonnelle (SASU)";
		case "57XX":
			return "Société par Actions Simplifiée (SAS)";
		case "55XX":
			return "Société Anonyme (SA)";
		case "52XX":
			return "Société en Nom Collectif (SNC)";
		case "5306":
			return "Société en Commandite Simple (SCS)";
		case "65XX":
			return "Société Civile Professionnelle (SCP)";
		case "6540":
			return "Société Civile Immobilière (SCI)";
		case "23XX":
			return "Société en Participation (SEP)";
		case "5308":
			return "Société en Commandite par Actions (SCA)";
		case "5543":
			return "Coopérative";
		case "5485":
			return "SERAL";
		case "9224":
			return "AARPI";
		default:
			return "Autre";
	}
};

export const getNumberOfMonthsFromSplittingOption = (
	splittingOption: SplittingOptionEnum,
): number => {
	switch (splittingOption) {
		case SplittingOptionEnum.Quarterly:
			return 4;
		case SplittingOptionEnum.HalfYearly:
			return 2;
		case SplittingOptionEnum.Monthly:
			return 12;
		default:
			return 1;
	}
};
