import { formStore } from "@/pages/form/stores/formStore.ts";

// If you'd like the version where you can edit the sector, see MainActivitySectorCard.tsx in the same folder, and pass the onSectorChange prop to it.
// onSectorChange={(sector) => {
// 	reset({
// 		sector: sector,
// 		principalActivity: {},
// 		secondaryActivities: [],
// 	});
// 	clearErrors();
// }}
export const MainActivitySectorWithoutEdit = () => {
	const { activityPage } = formStore((state) => ({
		activityPage: state.activityPage,
	}));

	return (
		<div className="mb-4 w-full overflow-hidden rounded-lg border border-gray-200">
			<div className="flex items-center justify-between bg-gray-50 p-4">
				<div className="flex gap-2">
					<div className="size-12 rounded-full bg-[#4EB1B7] p-2">
						<img
							src={activityPage.sector?.iconUrl}
							className="mx-auto size-7"
							alt="Sector icon"
						/>
					</div>
					<div className="flex flex-col">
						<p className="text-sm text-[#595959]">Secteur d&apos;activité</p>
						<p className="text-lg font-semibold text-[#333]">
							{activityPage.sector?.label}
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};
