import type { ClassValue } from "clsx";
import { clsx } from "clsx";
import { twMerge } from "tailwind-merge";

import type { DetailedAddressDto } from "@repos/mrp-dtos";

export function cn(...inputs: ClassValue[]) {
	return twMerge(clsx(inputs));
}

export const addSpacingBetweenThousandsToNumber = (str: number) => {
	return str.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};
export const addSpacingBetweenThousandsToString = (str: string) => {
	return str.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};
export const formatDetailedAddress = (address: DetailedAddressDto) => {
	return `${address.streetLabel}, ${address.postalCode} ${address.city}`;
};
export type WithNullableFields<T> = {
	[K in keyof T]: T[K] | undefined;
};

export const addSpacingBetweenThousandsToNumberWithDecimals = (
	str: number | null | undefined,
) => {
	if (str === null || str === undefined) return "";
	return addSpacingBetweenThousandsToString(str.toFixed(2));
};

export const formatSiret = (siret: string): string => {
	/**
	 * Formats a 14-digit SIRET number as 'XXX XXX XXX XXXX'.
	 * @param siret - A string containing exactly 14 digits.
	 * @return Formatted SIRET string.
	 * @throws Error if the input is not 14 digits.
	 */
	if (siret.length !== 14 || !/^\d{14}$/.test(siret)) {
		throw new Error("SIRET must be a string of exactly 14 digits.");
	}
	return `${siret.slice(0, 3)} ${siret.slice(3, 6)} ${siret.slice(6, 9)} ${siret.slice(9)}`;
};

export const formatSiren = (siren: string): string => {
	/**
	 * Formats a 9-digit SIREN number as 'XXX XXX XXX'.
	 * @param siren - A string containing exactly 9 digits.
	 * @return Formatted SIREN string.
	 * @throws Error if the input is not 9 digits.
	 */
	if (siren.length !== 9 || !/^\d{9}$/.test(siren)) {
		throw new Error("SIREN must be a string of exactly 9 digits.");
	}
	return `${siren.slice(0, 3)} ${siren.slice(3, 6)} ${siren.slice(6)}`;
};

export const onKeyDownPreventDefaultIfEnter = (event: React.KeyboardEvent) => {
	if (event.key === "Enter") {
		event.preventDefault();
	}
};
