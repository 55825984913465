import type {
	FieldValues,
	Path,
	PathValue,
	UseControllerProps,
} from "react-hook-form";
import { useMemo, useState } from "react";
import { Badge } from "@/components/ui/badge.tsx";
import { Button } from "@/components/ui/button.tsx";
import { Checkbox } from "@/components/ui/checkbox.tsx";
import {
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "@/components/ui/form.tsx";
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "@/components/ui/popover.tsx";
import { Separator } from "@/components/ui/separator.tsx";
import { cn } from "@/utils";
import { ChevronDown, XCircle, XIcon } from "lucide-react";
import { useFormContext } from "react-hook-form";

import {
	Command,
	CommandEmpty,
	CommandInput,
	CommandItem,
	CommandList,
} from "../ui/command.tsx";

interface OptionType {
	label: string;
	value: string;
	id: string;
	categoryLabel?: string;
}

interface MultiselectComboboxProps<T extends FieldValues, U extends OptionType>
	extends UseControllerProps<T> {
	label: string;
	className?: string;
	options: U[];
	required?: boolean;
}

const MAX_COUNT = 3;

export function ActivitySelectCombobox<
	T extends FieldValues,
	U extends OptionType,
>({
	label,
	className,
	name,
	options,
	required,
	...props
}: MultiselectComboboxProps<T, U>) {
	const { control, setValue, clearErrors } = useFormContext<T>();
	const [open, setOpen] = useState(false);

	const sortedOptions = useMemo(() => {
		return options.sort((a, b) => a.label.localeCompare(b.label));
	}, [options]);

	const toggleOption = (
		selectedValues: PathValue<T, Path<T>>,
		value: string,
	) => {
		const newSelectedValues = selectedValues.includes(value)
			? selectedValues.filter((v) => v !== value)
			: [...selectedValues, value];
		setValue(name, newSelectedValues);
		clearErrors(name);
	};

	const clearExtraOptions = (selectedValues: PathValue<T, Path<T>>) => {
		const newSelectedValues = selectedValues.slice(0, MAX_COUNT);
		setValue(name, newSelectedValues);
	};
	const handleClear = () => {
		setValue(name, [] as PathValue<T, Path<T>>);
	};
	return (
		<FormField
			{...props}
			control={control}
			name={name}
			render={({ field }) => {
				const selectedValues = field.value;
				return (
					<FormItem className={cn("flex w-full flex-col", className)}>
						<FormLabel className="text-sm font-medium text-[#333333]">
							{label} {required && <span className="text-red-500">*</span>}
						</FormLabel>
						<Popover open={open} onOpenChange={setOpen}>
							<PopoverTrigger asChild>
								<FormControl>
									<Button
										variant="outline"
										role="combobox"
										className={cn(
											"w-full justify-between overflow-hidden overflow-ellipsis whitespace-nowrap rounded-lg border-gray-300 bg-white p-4 font-normal text-black",
											!selectedValues && "text-muted-foreground",
										)}
									>
										{selectedValues.length > 0 ? (
											<div className="flex w-full items-center justify-between">
												<div className="flex items-center gap-3">
													{selectedValues
														.slice(0, MAX_COUNT)
														.map((value: string) => {
															const option = options.find(
																(o) => o.value === value,
															);
															return (
																<Badge
																	variant="outline"
																	key={value}
																	className="max-w-[150px] border-gray-400 font-medium xl:max-w-[200px]"
																>
																	<div className="overflow-hidden overflow-ellipsis whitespace-nowrap">
																		{option?.label}
																	</div>
																	<XCircle
																		className="ml-2 size-4 min-h-4 min-w-4 cursor-pointer"
																		onClick={(event) => {
																			event.stopPropagation();
																			toggleOption(selectedValues, value);
																		}}
																	/>
																</Badge>
															);
														})}
													{selectedValues.length > MAX_COUNT && (
														<Badge className="p-2">
															{`+ ${selectedValues.length - MAX_COUNT} autres`}
															<XCircle
																className="ml-2 h-4 w-4 cursor-pointer"
																onClick={(event) => {
																	event.stopPropagation();
																	clearExtraOptions(selectedValues);
																}}
															/>
														</Badge>
													)}
												</div>
												<div className="flex items-center justify-between">
													<XIcon
														className="mx-2 h-4 cursor-pointer text-muted-foreground"
														onClick={(event) => {
															event.stopPropagation();
															handleClear();
														}}
													/>
													<Separator
														orientation="vertical"
														className="flex h-full min-h-6"
													/>
													<ChevronDown className="mx-2 h-4 cursor-pointer text-muted-foreground" />
												</div>
											</div>
										) : (
											<div className="mx-auto flex w-full items-center justify-between">
												<span className="mx-3 text-sm text-muted-foreground">
													Choisir une option
												</span>
												<ChevronDown className="mx-2 h-4 cursor-pointer text-muted-foreground" />
											</div>
										)}
									</Button>
								</FormControl>
							</PopoverTrigger>
							<PopoverContent
								className="w-full p-0"
								onInteractOutside={(e) => {
									if (
										e.target instanceof Element &&
										e.target.hasAttribute("cmdk-input")
									) {
										e.preventDefault();
									}
								}}
							>
								<Command>
									<CommandInput placeholder="Chercher une option..." />
									<CommandList>
										<CommandEmpty>Aucun résultat</CommandEmpty>
										{sortedOptions.map((option) => (
											<CommandItem
												value={option.label}
												key={option.value}
												className="flex items-start gap-2"
												onMouseDown={(e) => e.preventDefault()}
												onSelect={() => {
													toggleOption(selectedValues, option.value);
												}}
											>
												<Checkbox
													className="rounded-md"
													checked={selectedValues.includes(option.value)}
												/>
												{option.label}
											</CommandItem>
										))}
									</CommandList>
								</Command>
							</PopoverContent>
						</Popover>
						<FormMessage />
					</FormItem>
				);
			}}
		/>
	);
}
