import {
	LeaseClauses,
	LocalStatus,
	NatureOfClaim,
	NumberOfClaimsInLastThreeYears,
	OccupationStatus,
} from "@prisma/client";
import { z } from "zod";

import {
	brokerCommissionSchema,
	companyNameSchema,
	contractDueDateSchema,
	declarationConfirmationInputSchema,
	deductibleSchema,
	effectiveDateSchema,
	establishmentInputSchema,
	establishmentOccupationStatusSchema,
	establishmentTurnoverSchema,
	fileFeesSchema,
	localStatusSchema,
	partnershipIdSchema,
	paymentMethodSchema,
	sirenSchema,
	siretSchema,
	splitOptionSchema,
	subscriberInputSchema,
} from "../primitives";
import { CompanyInputSchema } from "./company.dto";
import {
	ActivityPricingDetails,
	PricingResponseSchema,
	relatedActivityGuaranteesInputs,
} from "./pricing.dto";

export const establishmentsInputSchema = z
	.array(establishmentInputSchema)
	.max(3);
export type EstablishmentsInputDto = z.infer<typeof establishmentsInputSchema>;
export const declarationsInputSchema = z.array(
	declarationConfirmationInputSchema,
);
export type DeclarationsInputDto = z.infer<typeof declarationsInputSchema>;

export const SubscriptionInputSchema = z.object({
	companyInfo: CompanyInputSchema,
	partnershipId: partnershipIdSchema,
	establishments: establishmentsInputSchema,
	declarations: declarationsInputSchema,
	companyLeaderInfo: subscriberInputSchema,
	pricing: PricingResponseSchema,
	desiredEffectiveDate: effectiveDateSchema,
	splittingOption: splitOptionSchema,
	paymentMethod: paymentMethodSchema,
	contractDueDate: contractDueDateSchema,
	deductible: deductibleSchema,
	fileFees: fileFeesSchema,
	brokerCommission: brokerCommissionSchema,
	totalTurnover: z.number(),
	leaseClause: z.nativeEnum(LeaseClauses),
	occupationStatus: z.nativeEnum(OccupationStatus),
	natureOfClaim: z.nativeEnum(NatureOfClaim),
	numberOfClaimsInLastThreeYears: z.nativeEnum(NumberOfClaimsInLastThreeYears),
	isDromCom: z.boolean(),
	judicialLiquidation: z.boolean(),
	falseStatementTermination: z.boolean().default(false),
	nonPaymentTermination: z.boolean().default(false),
	numberOfTheftClaims: z.number(),
	hasConfirmedDeclarations: z.boolean(),
	electricInstallationsInLocals: localStatusSchema.optional(),
	mobileExtinguishersInLocals: localStatusSchema.optional(),
	relatedActivitiesGuarantees: relatedActivityGuaranteesInputs,
});

export type SubscriptionInputDto = z.infer<typeof SubscriptionInputSchema>;

const AddressSchema = z.object({
	fullAddress: z.string().default("default full address"),
	postalCode: z.string().default("11111"),
	city: z.string().default("default city"),
});

const ActivitySchema = z.object({
	id: z.string(),
	nafCodeLabel: z.string(),
	nafCode: z.string(),
	fieldLabel: z.string(),
	tarifCode: z.string(),
	principalActivityLabel: z.string(),
	familyLabel: z.string(),
});
export type ActivityDto = z.infer<typeof ActivitySchema>;

const CompanyInfoSchema = z.object({
	companyName: companyNameSchema,
	legalRepresentativeName: z.string(),
	siren: sirenSchema,
	legalRepresentativeTitle: z
		.string()
		.default("[Legal representative title TO BE CHANGED]"),
	address: AddressSchema,
	creationDate: z.coerce.date().default(new Date()), // Adjust to z.date() if using Date objects
	legalStatusLabel: z.string(),
	principalActivity: ActivitySchema,
});

export const acitivitiesSchema = z.array(ActivitySchema);
export type ActivitiesDto = z.infer<typeof acitivitiesSchema>;
const EstablishmentSchema = z.object({
	address: AddressSchema,
	siret: siretSchema,
	activities: acitivitiesSchema,
	turnover: establishmentTurnoverSchema,
	// isPrimary: z.boolean(),
	officesArea: z.number(),
	valuePerM2: z.number(),
	professionalContent: z.number(),
	insuredStockContent: z.number(),
	numberOfEmployeesInEstablishment: z.number(),
	occupationStatus: establishmentOccupationStatusSchema,
});
export type EstablishmentDto = z.infer<typeof EstablishmentSchema>;

const CabinetSchema = z.object({
	name: z.string().default("Cabinet name"),
	orias: z.string().default("Cabinet orias"),
	reference: z.string().default("Cabinet ref"),
	vendorName: z.string().default("Cabinet vendor name"),
	address: AddressSchema,
});

export const insuredEstablishmentsSchema = z
	.array(EstablishmentSchema)
	.max(3)
	.min(1);
export type InsuredEstablishmentsDto = z.infer<
	typeof insuredEstablishmentsSchema
>;

export const SubscriptionDetailsForQuoteSchema = z.object({
	companyInfo: CompanyInfoSchema,
	insuredEstablishments: insuredEstablishmentsSchema,
	cabinet: CabinetSchema,
	contractNumber: z.string(),
	quoteNumber: z.string(),
	dueDate: z.date(),
	effectiveDate: z.date(),
	totalTurnoverWithoutTaxes: z.number(),
	numberOfClaims: z.nativeEnum(NumberOfClaimsInLastThreeYears),
	numberOfTheftClaims: z.number(),
	nonPaymentTermination: z.boolean(),
	falseStatementTermination: z.boolean(),
	judicialLiquidation: z.boolean(),
	firstWithdrawal: z.number(),
	otherWithdrawals: z.number(),
	isDROMCOM: z.boolean(),
	deductible: z.string(),
	// options related attributes
	isRisqueDiversChosen: z.boolean(),
	isEvenementClimatiquesChosen: z.boolean(),
	isEffondrementChosen: z.boolean(),
	isDommagesElectriquesChosen: z.boolean(),
	isDegatsDesEaux: z.boolean(),
	isBrisDesGlaceChosen: z.boolean(),
	isVandalismeChosen: z.boolean(),
	isBrisDeMachine: z.boolean(),
	isInstallationFrigorifiqueChosen: z.boolean(),
	isMMTChosen: z.boolean(),
	isInstallationsExterieursChosen: z.boolean(),
	isSFMChosen: z.boolean(),
	isPJChosen: z.boolean(),
	isRCEChosen: z.boolean(),
	isRCPChosen: z.boolean(),
	isPerteValeurChosen: z.boolean(),
	isPerteExpVolChosen: z.boolean(),
	// pricing related attributes
	totalInsurancePremium: z.number(),
	beforeTaxPremium: z.number(),
	fileFees: z.number(),
	catastropheNaturellePremium: z.number(),
	assistancePremium: z.number(),
	assistanceTaxes: z.number(),
	pjPremium: z.number(),
	pjTaxes: z.number(),
	releaseFees: z.number(),
	managementFees: z.number(),
	brokerSubscriptionFees: z.number(),
	attackPackage: z.number(),
	taxes: z.number(),
	ffbIndex: z.number(),
	generalProvisionsReference: z.string(),
	splittingOption: splitOptionSchema,
	mobileExtinguishersInLocals: z.nativeEnum(LocalStatus),
	electricInstallationsInLocals: z.nativeEnum(LocalStatus),
	pricingDetails: z.array(ActivityPricingDetails),
	relatedGuaranteesInputs: relatedActivityGuaranteesInputs,
});

export const ActivitiesPricingDetails = z.array(ActivityPricingDetails);

export type SubscriptionDetailsForQuoteDto = z.infer<
	typeof SubscriptionDetailsForQuoteSchema
>;
