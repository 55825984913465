import type { GuaranteeOptionalType } from "@/constants";

export const GUARANTEE_TYPE_LABELS: Record<GuaranteeOptionalType, string> = {
	// Property Damage
	INCENDIE: "Incendie",
	TGNA: "Tempête, Grêle et Neige",
	PJ: "Protection Juridique",
	ASSIST: "Assistance",
	CATNAT: "Catastrophes Naturelles",
	DDE: "Dégâts des Eaux",
	BDG: "Bris de Glace",
	VOL: "Vol",
	DPR: "Defense Penale et Recours",
	MEF: "Marchandises en Frigo",
	BDM: "Bris de machine",
	MMT: "Matériel et marchandises transportées",
	DEL: "Dommages Électriques",
	EEX: "Extensions extérieures",
	SFM: "Salons, foires et manifestations",
	EFFONDREMENT: "Effondrement",

	// Liability
	RCE: "Responsabilité Civile Exploitation",
	RCPRO: "Responsabilité Civile Professionnelle",
	PE_INCENDIE: "Pertes d'Exploitation après Incendie",
	PE_VOL: "Pertes d'Exploitation après Vol",
	PVF: "Perte Valeur du fonds",
};

/**
 * Converts a guarantee type code to its full readable name
 * @param guaranteeType - The guarantee type code
 * @returns The full readable name of the guarantee type
 */
export const getGuaranteeTypeName = (guaranteeType: string): string => {
	return GUARANTEE_TYPE_LABELS[guaranteeType] || guaranteeType;
};
