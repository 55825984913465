import type { OccupationStatus, SplittingOptionEnum } from "@prisma/client";
import { z } from "zod";

import {
	deductibleSchema,
	splitOptionSchema,
	transformedLegalStatusSchema,
} from "../primitives";
import { calculateTaxAmount } from "../utils";

export const PricingSheetInputSchema = z.object({
	ca_principal: z.number().nonnegative(),
	ca_etablissement_2: z.number().nonnegative().optional(),
	ca_etablissement_3: z.number().nonnegative().optional(),
	naf_principal: z.string(),
	is_pj: z.string(),
	is_effondrement: z.string(),
	superficie_principale: z.number().nonnegative(),
	superficie_etablissement_2: z.number().nonnegative().optional(),
	superficie_etablissement_3: z.number().nonnegative().optional(),
	statut_principal: z.string(),
	statut_etablissement_2: z.string().optional(),
	statut_etablissement_3: z.string().optional(),
	contenu_pro_exercice_principal: z.number().nonnegative(),
	contenu_pro_exercice_etablissement_2: z.number().nonnegative().optional(),
	contenu_pro_exercice_etablissement_3: z.number().nonnegative().optional(),
	lci_principal: z.number().nonnegative(),
	lci_etablissement_2: z.number().nonnegative().optional(),
	lci_etablissement_3: z.number().nonnegative().optional(),
	stock_principal: z.number().nonnegative(),
	stock_etablissement_2: z.number().nonnegative().optional(),
	stock_etablissement_3: z.number().nonnegative().optional(),
	montant_garantie_vol_principal: z.number().nonnegative(),
	montant_garantie_vol_etablissement_2: z.number().nonnegative().optional(),
	montant_garantie_vol_etablissement_3: z.number().nonnegative().optional(),
	montant_garantie_dom_principal: z.number().nonnegative(),
	montant_garantie_dom_etablissement_2: z.number().nonnegative().optional(),
	montant_garantie_dom_etablissement_3: z.number().nonnegative().optional(),
	montant_bdg_principal: z.number().nonnegative(),
	montant_bdg_etablissement_2: z.number().nonnegative().optional(),
	montant_bdg_etablissement_3: z.number().nonnegative().optional(),
	montant_garantie_bde_principal: z.number().nonnegative(),
	montant_garantie_bde_etablissement_2: z.number().nonnegative().optional(),
	montant_garantie_bde_etablissement_3: z.number().nonnegative().optional(),
	montant_march_chambre_principal: z.number().nonnegative(),
	montant_march_chambre_etablissement_2: z.number().nonnegative().optional(),
	montant_march_chambre_etablissement_3: z.number().nonnegative().optional(),
	montant_bdeM_principal: z.number().nonnegative(),
	montant_bdeM_etablissement_2: z.number().nonnegative().optional(),
	montant_bdeM_etablissement_3: z.number().nonnegative().optional(),
	marchandise_transport_principal: z.number().nonnegative(),
	marchandise_transport_etablissement_2: z.number().nonnegative().optional(),
	marchandise_transport_etablissement_3: z.number().nonnegative().optional(),
	insta_exterieur_principal: z.number().nonnegative(),
	insta_exterieur_etablissement_2: z.number().nonnegative().optional(),
	insta_exterieur_etablissement_3: z.number().nonnegative().optional(),
	depart_principal: z.number().nonnegative(),
	depart_etablissement_2: z.number().nonnegative().optional(),
	depart_etablissement_3: z.number().nonnegative().optional(),
});

export const PricingSheetResponseSchema = z.object({
	COMMISSIONS: z.number(),
	FORFAIT_ATTENTAT: z.number(),
	FRAIS_DE_DOSSIER_DISTRIBUTEUR: z.number(),
	FRAIS_GESTION: z.number(),
	FRAIS_QUITTANCEMENT: z.number(),
	PRIME_ASSURANCE_TTC: z.number(),
	PRIME_TECHNIQUE_HT_NON_CHARGEE: z.number(),
	PRIME_TOTALE_CONTRAT: z.number(),
	TAXES: z.number(),
	FRANCHISE_GENERALE: z.number(),
	FFB_SOUSCRIPTION: z.number(),
	// activity 1
	PRIME_CATASTROPHE_NATURELLE_TTC_1: z.number(), // 9% taxes
	PRIME_ASSISTANCE_TTC_1: z.number(), // 9% taxes
	PRIME_PROTECTION_JURIDIQUE_TTC_1: z.number(), // 13.4% taxes
	PRIME_INCENDIE_TTC_1: z.number().nullable(),
	PRIME_TGNA_TTC_1: z.number().nullable(),
	PRIME_DEGAT_EAUX_TTC_1: z.number().nullable(),
	PRIME_VOL_TTC_1: z.number().nullable(),
	PRIME_BRIS_GLACE_TTC_1: z.number().nullable(),
	PRIME_DOMMAGE_ELECTRIQUE_TTC_1: z.number().nullable(),
	PRIME_BRIS_DE_MACHINE_TTC_1: z.number().nullable(),
	PRIME_MIF_TTC_1: z.number().nullable(),
	PRIME_MARCHANDISE_TRASPOSTES_TTC_1: z.number().nullable(),
	PRIME_INSTALLATION_EXTERIEURE_TTC_1: z.number().nullable(),
	PRIME_SFR_TTC_1: z.number().nullable(),
	PRIME_EFFONDREMENT_TTC_1: z.number().nullable(),
	PRIME_PERTE_INCEDIE_TTC_1: z.number().nullable(),
	PRIME_PERTE_VOL_TTC_1: z.number().nullable(),
	PRIME_PERTE_VALEURS_TTC_1: z.number().nullable(),
	PRIME_RCE_TTC_1: z.number().nullable(),
	PRIME_RCP_TTC_1: z.number().nullable(),
	PRIME_DEFENSE_PENALE_TTC_1: z.number().nullable(),

	STATUS_CATASTROPHE_NATURELLE_TTC_1: z.string().nullable(),
	STATUS_ASSISTANCE_TTC_1: z.string().nullable(),
	STATUS_PROTECTION_JURIDIQUE_TTC_1: z.string().nullable(),
	STATUS_INCENDIE_TTC_1: z.string().nullable(),
	STATUS_TGNA_TTC_1: z.string().nullable(),
	STATUS_DEGAT_EAUX_TTC_1: z.string().nullable(),
	STATUS_VOL_TTC_1: z.string().nullable(),
	STATUS_BRIS_GLACE_TTC_1: z.string().nullable(),
	STATUS_DOMMAGE_ELECTRIQUE_TTC_1: z.string().nullable(),
	STATUS_BRIS_DE_MACHINE_TTC_1: z.string().nullable(),
	STATUS_MIF_TTC_1: z.string().nullable(),
	STATUS_MARCHANDISE_TRASPOSTES_TTC_1: z.string().nullable(),
	STATUS_INSTALLATION_EXTERIEURE_TTC_1: z.string().nullable(),
	STATUS_SFR_TTC_1: z.string().nullable(),
	STATUS_EFFONDREMENT_TTC_1: z.string().nullable(),
	STATUS_PERTE_INCEDIE_TTC_1: z.string().nullable(),
	STATUS_PERTE_VOL_TTC_1: z.string().nullable(),
	STATUS_PERTE_VALEURS_TTC_1: z.string().nullable(),
	STATUS_RCE_TTC_1: z.string().nullable(),
	STATUS_RCP_TTC_1: z.string().nullable(),
	STATUS_DEFENSE_PENALE_TTC_1: z.string().nullable(),

	// Activity 2
	PRIME_INCENDIE_TTC_2: z.number().nullable(),
	PRIME_ASSISTANCE_TTC_2: z.number(),
	PRIME_CATASTROPHE_NATURELLE_TTC_2: z.number(),
	PRIME_PROTECTION_JURIDIQUE_TTC_2: z.number(),
	PRIME_TGNA_TTC_2: z.number().nullable(),
	PRIME_DEGAT_EAUX_TTC_2: z.number().nullable(),
	PRIME_VOL_TTC_2: z.number().nullable(),
	PRIME_BRIS_GLACE_TTC_2: z.number().nullable(),
	PRIME_DOMMAGE_ELECTRIQUE_TTC_2: z.number().nullable(),
	PRIME_BRIS_DE_MACHINE_TTC_2: z.number().nullable(),
	PRIME_MIF_TTC_2: z.number().nullable(),
	PRIME_MARCHANDISE_TRASPOSTES_TTC_2: z.number().nullable(),
	PRIME_INSTALLATION_EXTERIEURE_TTC_2: z.number().nullable(),
	PRIME_SFR_TTC_2: z.number().nullable(),
	PRIME_EFFONDREMENT_TTC_2: z.number().nullable(),
	PRIME_PERTE_INCEDIE_TTC_2: z.number().nullable(),
	PRIME_PERTE_VOL_TTC_2: z.number().nullable(),
	PRIME_PERTE_VALEURS_TTC_2: z.number().nullable(),
	PRIME_RCE_TTC_2: z.number().nullable(),
	PRIME_RCP_TTC_2: z.number().nullable(),
	PRIME_DEFENSE_PENALE_TTC_2: z.number().nullable(),

	STATUS_CATASTROPHE_NATURELLE_TTC_2: z.string().nullable(),
	STATUS_ASSISTANCE_TTC_2: z.string().nullable(),
	STATUS_PROTECTION_JURIDIQUE_TTC_2: z.string().nullable(),
	STATUS_INCENDIE_TTC_2: z.string().nullable(),
	STATUS_TGNA_TTC_2: z.string().nullable(),
	STATUS_DEGAT_EAUX_TTC_2: z.string().nullable(),
	STATUS_VOL_TTC_2: z.string().nullable(),
	STATUS_BRIS_GLACE_TTC_2: z.string().nullable(),
	STATUS_DOMMAGE_ELECTRIQUE_TTC_2: z.string().nullable(),
	STATUS_BRIS_DE_MACHINE_TTC_2: z.string().nullable(),
	STATUS_MIF_TTC_2: z.string().nullable(),
	STATUS_MARCHANDISE_TRASPOSTES_TTC_2: z.string().nullable(),
	STATUS_INSTALLATION_EXTERIEURE_TTC_2: z.string().nullable(),
	STATUS_SFR_TTC_2: z.string().nullable(),
	STATUS_EFFONDREMENT_TTC_2: z.string().nullable(),
	STATUS_PERTE_INCEDIE_TTC_2: z.string().nullable(),
	STATUS_PERTE_VOL_TTC_2: z.string().nullable(),
	STATUS_PERTE_VALEURS_TTC_2: z.string().nullable(),
	STATUS_RCE_TTC_2: z.string().nullable(),
	STATUS_RCP_TTC_2: z.string().nullable(),
	STATUS_DEFENSE_PENALE_TTC_2: z.string().nullable(),

	// Activity 3
	PRIME_INCENDIE_TTC_3: z.number().nullable(),
	PRIME_TGNA_TTC_3: z.number().nullable(),
	PRIME_ASSISTANCE_TTC_3: z.number(),
	PRIME_CATASTROPHE_NATURELLE_TTC_3: z.number(),
	PRIME_PROTECTION_JURIDIQUE_TTC_3: z.number(),
	PRIME_DEGAT_EAUX_TTC_3: z.number().nullable(),
	PRIME_VOL_TTC_3: z.number().nullable(),
	PRIME_BRIS_GLACE_TTC_3: z.number().nullable(),
	PRIME_DOMMAGE_ELECTRIQUE_TTC_3: z.number().nullable(),
	PRIME_BRIS_DE_MACHINE_TTC_3: z.number().nullable(),
	PRIME_MIF_TTC_3: z.number().nullable(),
	PRIME_MARCHANDISE_TRASPOSTES_TTC_3: z.number().nullable(),
	PRIME_INSTALLATION_EXTERIEURE_TTC_3: z.number().nullable(),
	PRIME_SFR_TTC_3: z.number().nullable(),
	PRIME_EFFONDREMENT_TTC_3: z.number().nullable(),
	PRIME_PERTE_INCEDIE_TTC_3: z.number().nullable(),
	PRIME_PERTE_VOL_TTC_3: z.number().nullable(),
	PRIME_PERTE_VALEURS_TTC_3: z.number().nullable(),
	PRIME_RCE_TTC_3: z.number().nullable(),
	PRIME_RCP_TTC_3: z.number().nullable(),
	PRIME_DEFENSE_PENALE_TTC_3: z.number().nullable(),

	STATUS_CATASTROPHE_NATURELLE_TTC_3: z.string().nullable(),
	STATUS_ASSISTANCE_TTC_3: z.string().nullable(),
	STATUS_PROTECTION_JURIDIQUE_TTC_3: z.string().nullable(),
	STATUS_INCENDIE_TTC_3: z.string().nullable(),
	STATUS_TGNA_TTC_3: z.string().nullable(),
	STATUS_DEGAT_EAUX_TTC_3: z.string().nullable(),
	STATUS_VOL_TTC_3: z.string().nullable(),
	STATUS_BRIS_GLACE_TTC_3: z.string().nullable(),
	STATUS_DOMMAGE_ELECTRIQUE_TTC_3: z.string().nullable(),
	STATUS_BRIS_DE_MACHINE_TTC_3: z.string().nullable(),
	STATUS_MIF_TTC_3: z.string().nullable(),
	STATUS_MARCHANDISE_TRASPOSTES_TTC_3: z.string().nullable(),
	STATUS_INSTALLATION_EXTERIEURE_TTC_3: z.string().nullable(),
	STATUS_SFR_TTC_3: z.string().nullable(),
	STATUS_EFFONDREMENT_TTC_3: z.string().nullable(),
	STATUS_PERTE_INCEDIE_TTC_3: z.string().nullable(),
	STATUS_PERTE_VOL_TTC_3: z.string().nullable(),
	STATUS_PERTE_VALEURS_TTC_3: z.string().nullable(),
	STATUS_RCE_TTC_3: z.string().nullable(),
	STATUS_RCP_TTC_3: z.string().nullable(),
	STATUS_DEFENSE_PENALE_TTC_3: z.string().nullable(),
});

export const ActivityPricingDetails = z.object({
	activityId: z.string(),
	siret: z.number(),
	basic: z.object({
		incendiePremium: z.number(),
		catastrophePremium: z.number(),
		tgnaPremium: z.number(),
		assistancePremium: z.number(),
	}),
	dommageAuxBiens: z.object({
		ddePremium: z.number().nullish(),
		volPremium: z.number().nullish(),
		bdgPremium: z.number().nullish(),
		mifPremium: z.number().nullish(),
		trasnportPremium: z.number().nullish(),
		installationExterieurePremium: z.number().nullish(),
		sfrPremium: z.number().nullish(),
		effondrementPremium: z.number().nullish(),
		dommageElectriquePremium: z.number().nullish(),
		brisMachinePremium: z.number().nullish(),
	}),
	pertesActivite: z.object({
		perteIncendiePremium: z.number().nullish(),
		perteVolPremium: z.number().nullish(),
		perteValeurPremium: z.number().nullish(),
	}),
	rcDefensePj: z.object({
		rcePremium: z.number().nullish(),
		rcpPremium: z.number().nullish(),
		deffencePenalePremium: z.number().nullish(),
		pjPremium: z.number().nullish(),
	}),
});

export type ActivityPricingDetailsDto = z.infer<typeof ActivityPricingDetails>;

export const PricingResponseSchema = z.object({
	commissions: z.number(),
	brokerCommission: z.number(),
	attackPackage: z.number(),
	fileFees: z.number(),
	managementFees: z.number(),
	releaseFees: z.number(),
	totalInsurancePremium: z.number(),
	beforeTaxPremium: z.number(),
	firstWithdrawal: z.number(),
	otherWithdrawals: z.number(),
	taxes: z.number(),
	deductible: z.number(),
	ffbIndex: z.number(),
	assistancePremium: z.number(),
	assistanceTaxes: z.number(),
	catastropheNaturellePremium: z.number(),
	catastropheNaturelleTaxes: z.number(),
	pjPremium: z.number(),
	pjTaxes: z.number(),
	// TODO: We should create another schema for front to make secondary/tertiary optional
	primaryActivityPricingDetails: ActivityPricingDetails.optional(),
	secondaryActivityPricingDetails: ActivityPricingDetails.optional(),
	tertiaryActivityPricingDetails: ActivityPricingDetails.optional(),
});

export const PricingToSheetResponseSchema =
	PricingSheetResponseSchema.transform((data) => {
		const assistanceTotal =
			data.PRIME_ASSISTANCE_TTC_1 +
			data.PRIME_ASSISTANCE_TTC_2 +
			data.PRIME_ASSISTANCE_TTC_3;
		const assistanceTaxes = calculateTaxAmount(assistanceTotal, 9);

		const catastropheNaturelleTotal =
			data.PRIME_CATASTROPHE_NATURELLE_TTC_1 +
			data.PRIME_CATASTROPHE_NATURELLE_TTC_2 +
			data.PRIME_CATASTROPHE_NATURELLE_TTC_3;
		const catastropheNaturelleTaxes = calculateTaxAmount(
			catastropheNaturelleTotal,
			9,
		);

		const pjTotal =
			data.PRIME_PROTECTION_JURIDIQUE_TTC_1 +
			data.PRIME_PROTECTION_JURIDIQUE_TTC_2 +
			data.PRIME_PROTECTION_JURIDIQUE_TTC_3;
		const pjTaxes = calculateTaxAmount(pjTotal, 13.4);

		return {
			brokerCommission: 0,
			commissions: data.COMMISSIONS,
			attackPackage: data.FORFAIT_ATTENTAT,
			fileFees: 0,
			managementFees: data.FRAIS_GESTION,
			releaseFees: data.FRAIS_QUITTANCEMENT,
			totalInsurancePremium: data.PRIME_ASSURANCE_TTC,
			beforeTaxPremium: data.PRIME_TECHNIQUE_HT_NON_CHARGEE,
			firstWithdrawal: 0,
			otherWithdrawals: 0,
			taxes: data.TAXES,
			deductible: data.FRANCHISE_GENERALE,
			ffbIndex: data.FFB_SOUSCRIPTION,
			assistancePremium: assistanceTotal,
			assistanceTaxes: assistanceTaxes,
			catastropheNaturellePremium: catastropheNaturelleTotal,
			catastropheNaturelleTaxes: catastropheNaturelleTaxes,
			pjPremium: pjTotal,
			pjTaxes: pjTaxes,
			primaryActivityPricingDetails: {
				activityId: "",
				siret: 0,
				basic: {
					incendiePremium: data.PRIME_INCENDIE_TTC_1 || 0,
					catastrophePremium: data.PRIME_CATASTROPHE_NATURELLE_TTC_1 || 0,
					tgnaPremium: data.PRIME_TGNA_TTC_1 || 0,
					assistancePremium: data.PRIME_ASSISTANCE_TTC_1 || 0,
				},
				dommageAuxBiens: {
					...(data.STATUS_DEGAT_EAUX_TTC_1 === "S" && {
						ddePremium: data.PRIME_DEGAT_EAUX_TTC_1,
					}),
					...(data.STATUS_VOL_TTC_1 === "S" && {
						volPremium: data.PRIME_VOL_TTC_1,
					}),
					...(data.STATUS_BRIS_GLACE_TTC_1 === "S" && {
						bdgPremium: data.PRIME_BRIS_GLACE_TTC_1,
					}),
					...(data.STATUS_MIF_TTC_1 === "S" && {
						mifPremium: data.PRIME_MIF_TTC_1,
					}),
					...(data.STATUS_MARCHANDISE_TRASPOSTES_TTC_1 === "S" && {
						trasnportPremium: data.PRIME_MARCHANDISE_TRASPOSTES_TTC_1,
					}),
					...(data.STATUS_INSTALLATION_EXTERIEURE_TTC_1 === "S" && {
						installationExterieurePremium:
							data.PRIME_INSTALLATION_EXTERIEURE_TTC_1,
					}),
					...(data.STATUS_SFR_TTC_1 === "S" && {
						sfrPremium: data.PRIME_SFR_TTC_1,
					}),
					...(data.STATUS_EFFONDREMENT_TTC_1 === "S" && {
						effondrementPremium: data.PRIME_EFFONDREMENT_TTC_1,
					}),
					...(data.STATUS_DOMMAGE_ELECTRIQUE_TTC_1 === "S" && {
						dommageElectriquePremium: data.PRIME_DOMMAGE_ELECTRIQUE_TTC_1,
					}),
					...(data.STATUS_BRIS_DE_MACHINE_TTC_1 === "S" && {
						brisMachinePremium: data.PRIME_BRIS_DE_MACHINE_TTC_1,
					}),
				},
				pertesActivite: {
					...(data.STATUS_PERTE_INCEDIE_TTC_1 === "S" && {
						perteIncendiePremium: data.PRIME_PERTE_INCEDIE_TTC_1,
					}),
					...(data.STATUS_PERTE_VOL_TTC_1 === "S" && {
						perteVolPremium: data.PRIME_PERTE_VOL_TTC_1,
					}),
					...(data.STATUS_PERTE_VALEURS_TTC_1 === "S" && {
						perteValeurPremium: data.PRIME_PERTE_VALEURS_TTC_1,
					}),
				},
				rcDefensePj: {
					...(data.STATUS_RCE_TTC_1 === "S" && {
						rcePremium: data.PRIME_RCE_TTC_1,
					}),
					...(data.STATUS_RCP_TTC_1 === "S" && {
						rcpPremium: data.PRIME_RCP_TTC_1,
					}),
					...(data.STATUS_DEFENSE_PENALE_TTC_1 === "S" && {
						deffencePenalePremium: data.PRIME_DEFENSE_PENALE_TTC_1,
					}),
					...(data.STATUS_PROTECTION_JURIDIQUE_TTC_1 === "S" && {
						pjPremium: data.PRIME_PROTECTION_JURIDIQUE_TTC_1,
					}),
				},
			},
			secondaryActivityPricingDetails: {
				activityId: "",
				siret: 0,
				basic: {
					incendiePremium: data.PRIME_INCENDIE_TTC_2 || 0,
					catastrophePremium: data.PRIME_CATASTROPHE_NATURELLE_TTC_2 || 0,
					tgnaPremium: data.PRIME_TGNA_TTC_2 || 0,
					assistancePremium: data.PRIME_ASSISTANCE_TTC_2 || 0,
				},
				dommageAuxBiens: {
					...(data.STATUS_DEGAT_EAUX_TTC_2 === "S" && {
						ddePremium: data.PRIME_DEGAT_EAUX_TTC_2,
					}),
					...(data.STATUS_VOL_TTC_2 === "S" && {
						volPremium: data.PRIME_VOL_TTC_2,
					}),
					...(data.STATUS_BRIS_GLACE_TTC_2 === "S" && {
						bdgPremium: data.PRIME_BRIS_GLACE_TTC_2,
					}),
					...(data.STATUS_MIF_TTC_2 === "S" && {
						mifPremium: data.PRIME_MIF_TTC_2,
					}),
					...(data.STATUS_MARCHANDISE_TRASPOSTES_TTC_2 === "S" && {
						trasnportPremium: data.PRIME_MARCHANDISE_TRASPOSTES_TTC_2,
					}),
					...(data.STATUS_INSTALLATION_EXTERIEURE_TTC_2 === "S" && {
						installationExterieurePremium:
							data.PRIME_INSTALLATION_EXTERIEURE_TTC_2,
					}),
					...(data.STATUS_SFR_TTC_2 === "S" && {
						sfrPremium: data.PRIME_SFR_TTC_2,
					}),
					...(data.STATUS_EFFONDREMENT_TTC_2 === "S" && {
						effondrementPremium: data.PRIME_EFFONDREMENT_TTC_2,
					}),
					...(data.STATUS_DOMMAGE_ELECTRIQUE_TTC_2 === "S" && {
						dommageElectriquePremium: data.PRIME_DOMMAGE_ELECTRIQUE_TTC_2,
					}),
					...(data.STATUS_BRIS_DE_MACHINE_TTC_2 === "S" && {
						brisMachinePremium: data.PRIME_BRIS_DE_MACHINE_TTC_2,
					}),
				},
				pertesActivite: {
					...(data.STATUS_PERTE_INCEDIE_TTC_2 === "S" && {
						perteIncendiePremium: data.PRIME_PERTE_INCEDIE_TTC_2,
					}),
					...(data.STATUS_PERTE_VOL_TTC_2 === "S" && {
						perteVolPremium: data.PRIME_PERTE_VOL_TTC_2,
					}),
					...(data.STATUS_PERTE_VALEURS_TTC_2 === "S" && {
						perteValeurPremium: data.PRIME_PERTE_VALEURS_TTC_2,
					}),
				},
				rcDefensePj: {
					...(data.STATUS_RCE_TTC_2 === "S" && {
						rcePremium: data.PRIME_RCE_TTC_2,
					}),
					...(data.STATUS_RCP_TTC_2 === "S" && {
						rcpPremium: data.PRIME_RCP_TTC_2,
					}),
					...(data.STATUS_DEFENSE_PENALE_TTC_2 === "S" && {
						deffencePenalePremium: data.PRIME_DEFENSE_PENALE_TTC_2,
					}),
					...(data.STATUS_PROTECTION_JURIDIQUE_TTC_2 === "S" && {
						pjPremium: data.PRIME_PROTECTION_JURIDIQUE_TTC_2,
					}),
				},
			},
			tertiaryActivityPricingDetails: {
				activityId: "",
				siret: 0,
				basic: {
					incendiePremium: data.PRIME_INCENDIE_TTC_3 || 0,
					catastrophePremium: data.PRIME_CATASTROPHE_NATURELLE_TTC_3 || 0,
					tgnaPremium: data.PRIME_TGNA_TTC_3 || 0,
					assistancePremium: data.PRIME_ASSISTANCE_TTC_3 || 0,
				},
				dommageAuxBiens: {
					...(data.STATUS_DEGAT_EAUX_TTC_3 === "S" && {
						ddePremium: data.PRIME_DEGAT_EAUX_TTC_3,
					}),
					...(data.STATUS_VOL_TTC_3 === "S" && {
						volPremium: data.PRIME_VOL_TTC_3,
					}),
					...(data.STATUS_BRIS_GLACE_TTC_3 === "S" && {
						bdgPremium: data.PRIME_BRIS_GLACE_TTC_3,
					}),
					...(data.STATUS_MIF_TTC_3 === "S" && {
						mifPremium: data.PRIME_MIF_TTC_3,
					}),
					...(data.STATUS_MARCHANDISE_TRASPOSTES_TTC_3 === "S" && {
						trasnportPremium: data.PRIME_MARCHANDISE_TRASPOSTES_TTC_3,
					}),
					...(data.STATUS_INSTALLATION_EXTERIEURE_TTC_3 === "S" && {
						installationExterieurePremium:
							data.PRIME_INSTALLATION_EXTERIEURE_TTC_3,
					}),
					...(data.STATUS_SFR_TTC_3 === "S" && {
						sfrPremium: data.PRIME_SFR_TTC_3,
					}),
					...(data.STATUS_EFFONDREMENT_TTC_3 === "S" && {
						effondrementPremium: data.PRIME_EFFONDREMENT_TTC_3,
					}),
					...(data.STATUS_DOMMAGE_ELECTRIQUE_TTC_3 === "S" && {
						dommageElectriquePremium: data.PRIME_DOMMAGE_ELECTRIQUE_TTC_3,
					}),
					...(data.STATUS_BRIS_DE_MACHINE_TTC_3 === "S" && {
						brisMachinePremium: data.PRIME_BRIS_DE_MACHINE_TTC_3,
					}),
				},
				pertesActivite: {
					...(data.STATUS_PERTE_INCEDIE_TTC_3 === "S" && {
						perteIncendiePremium: data.PRIME_PERTE_INCEDIE_TTC_3,
					}),
					...(data.STATUS_PERTE_VOL_TTC_3 === "S" && {
						perteVolPremium: data.PRIME_PERTE_VOL_TTC_3,
					}),
					...(data.STATUS_PERTE_VALEURS_TTC_3 === "S" && {
						perteValeurPremium: data.PRIME_PERTE_VALEURS_TTC_3,
					}),
				},
				rcDefensePj: {
					...(data.STATUS_RCE_TTC_3 === "S" && {
						rcePremium: data.PRIME_RCE_TTC_3,
					}),
					...(data.STATUS_RCP_TTC_3 === "S" && {
						rcpPremium: data.PRIME_RCP_TTC_3,
					}),
					...(data.STATUS_DEFENSE_PENALE_TTC_3 === "S" && {
						deffencePenalePremium: data.PRIME_DEFENSE_PENALE_TTC_3,
					}),
					...(data.STATUS_PROTECTION_JURIDIQUE_TTC_3 === "S" && {
						pjPremium: data.PRIME_PROTECTION_JURIDIQUE_TTC_3,
					}),
				},
			},
		};
	});

export type PricingResponseDto = z.infer<typeof PricingResponseSchema>;
export type SplittingOption = SplittingOptionEnum;

// Enums for French conversion

export enum SubscriptionEnum {
	Subscribed = "souscrit",
	NotSubscribed = "non souscrit",
}

const guaranteeTarificationInputSchema = z.object({
	subscribed: z.boolean(),
	insuredCapital: z.number().optional().nullable(),
});

export const relatedActivityGuarantees = z.object({
	waterDamage: guaranteeTarificationInputSchema,
	theft: guaranteeTarificationInputSchema,
	glassBreakage: guaranteeTarificationInputSchema,
	electricalDamage: guaranteeTarificationInputSchema,
	machineBreakage: guaranteeTarificationInputSchema,
	refrigeratedGoods: guaranteeTarificationInputSchema,
	transportedGoods: guaranteeTarificationInputSchema,
	externalInstallation: guaranteeTarificationInputSchema,
	fairsAndEvents: guaranteeTarificationInputSchema,
	collapse: guaranteeTarificationInputSchema,
	businessInterruptionFire: guaranteeTarificationInputSchema,
	businessInterruptionTheft: guaranteeTarificationInputSchema,
	lossOfCommercialValue: guaranteeTarificationInputSchema,
	civilLiabilityOperational: guaranteeTarificationInputSchema,
	civilLiabilityProfessional: guaranteeTarificationInputSchema,
	legalDefense: guaranteeTarificationInputSchema,
	legalProtection: guaranteeTarificationInputSchema,
});
export type RelatedActivityGuarantyDto = z.infer<
	typeof relatedActivityGuarantees
>;
export const relatedActivityGuaranteesInputs = z.array(
	relatedActivityGuarantees,
);
export type RelatedActivityGuaranteesDto = z.infer<
	typeof relatedActivityGuaranteesInputs
>;

export const OccupantTitleForPricing = z.enum([
	"Propriétaire",
	"Locataire",
	"Assurance pour compte",
	"Abandon de recours - Propriétaire sur locataire",
	"Abandon de recours - Locataire sur propriétaire",
	"Abandon de recours - Réciproque",
]);

const establishmentPricingInputSchema = z.object({
	postalCode: z.number(),
	city: z.string(),
	siret: z.number(),
	legalStatus: transformedLegalStatusSchema,
	nafCode: z.string().optional(),
	creationDate: z.string().optional(),
	activityId: z.string(),
	turnover: z.number(),
	occupantTitle: OccupantTitleForPricing,
	area: z.number(),
	valuePerM2: z.number(),
	insuredPropertyValue: z.number(),
	insuredStockValue: z.number(),
	relatedActivityGuarantees: relatedActivityGuarantees,
});

export type ActivityPricingInputDto = z.infer<
	typeof establishmentPricingInputSchema
>;

const activityPricingSheetInputSchema =
	establishmentPricingInputSchema.transform((data) => ({
		code_postal_etablissement: data.postalCode,
		ville_etablissement: data.city,
		siret_etablissement: data.siret,
		forme_juridique: data.legalStatus.label,
		code_naf: data.nafCode,
		date_creation: data.creationDate,
		libele_activite: data.activityId,
		ca: data.turnover,
		qualite_occupant: data.occupantTitle,
		surface: data.area,
		valeur_m2: data.valuePerM2,
		valeur_bien_a_assurer: data.insuredPropertyValue,
		contenu_stock_a_assurer: data.insuredStockValue,
		degat_des_eaux: {
			status:
				data.relatedActivityGuarantees.waterDamage.subscribed == true
					? "S"
					: "NS",
			capital_assurer:
				data.relatedActivityGuarantees.waterDamage.insuredCapital,
		},
		vol: {
			status:
				data.relatedActivityGuarantees.theft.subscribed == true ? "S" : "NS",
			capital_assurer: data.relatedActivityGuarantees.theft.insuredCapital,
		},
		bris_de_glace: {
			status:
				data.relatedActivityGuarantees.glassBreakage.subscribed == true
					? "S"
					: "NS",
			capital_assurer:
				data.relatedActivityGuarantees.glassBreakage.insuredCapital,
		},
		dommage_electrique: {
			status:
				data.relatedActivityGuarantees.electricalDamage.subscribed == true
					? "S"
					: "NS",
			capital_assurer:
				data.relatedActivityGuarantees.electricalDamage.insuredCapital,
		},
		bris_de_machine: {
			status:
				data.relatedActivityGuarantees.machineBreakage.subscribed == true
					? "S"
					: "NS",
			capital_assurer:
				data.relatedActivityGuarantees.machineBreakage.insuredCapital,
		},
		marchandises_en_installation_frigorifque: {
			status:
				data.relatedActivityGuarantees.refrigeratedGoods.subscribed == true
					? "S"
					: "NS",
			capital_assurer:
				data.relatedActivityGuarantees.refrigeratedGoods.insuredCapital,
		},
		marchandise_transportees: {
			status:
				data.relatedActivityGuarantees.transportedGoods.subscribed == true
					? "S"
					: "NS",
			capital_assurer:
				data.relatedActivityGuarantees.transportedGoods.insuredCapital,
		},
		installation_exterieure: {
			status:
				data.relatedActivityGuarantees.externalInstallation.subscribed == true
					? "S"
					: "NS",
			capital_assurer:
				data.relatedActivityGuarantees.externalInstallation.insuredCapital,
		},
		salons_foires_manifestations: {
			status:
				data.relatedActivityGuarantees.fairsAndEvents.subscribed == true
					? "S"
					: "NS",
		},
		effondrement: {
			status:
				data.relatedActivityGuarantees.collapse.subscribed == true ? "S" : "NS",
		},
		perte_exploitation_incendie: {
			status:
				data.relatedActivityGuarantees.businessInterruptionFire.subscribed ==
				true
					? "S"
					: "NS",
		},
		perte_exploitation_vol_vandalisme: {
			status:
				data.relatedActivityGuarantees.businessInterruptionTheft.subscribed ==
				true
					? "S"
					: "NS",
		},
		perte_valeur_venale_fonds_de_commerce: {
			status:
				data.relatedActivityGuarantees.lossOfCommercialValue.subscribed == true
					? "S"
					: "NS",
		},
		responsabilite_civile_exploitation: {
			status:
				data.relatedActivityGuarantees.civilLiabilityOperational.subscribed ==
				true
					? "S"
					: "NS",
		},
		responsabilite_civile_pro: {
			status:
				data.relatedActivityGuarantees.civilLiabilityProfessional.subscribed ==
				true
					? "S"
					: "NS",
		},
		defense_penale_recours: {
			status:
				data.relatedActivityGuarantees.legalDefense.subscribed == true
					? "S"
					: "NS",
		},
		protection_juridique: {
			status:
				data.relatedActivityGuarantees.legalProtection.subscribed == true
					? "S"
					: "NS",
		},
	}));

export type ActivityPricingSheetInputDto = z.infer<
	typeof activityPricingSheetInputSchema
>;
export const pricingInputEstablishmentSchema = z.array(
	establishmentPricingInputSchema,
);
export type PricingInputEstablishmentDto = z.infer<
	typeof pricingInputEstablishmentSchema
>;

export const PricingInputSchema = z.object({
	nafCode: z.string(),
	companyCreationDate: z.string(),
	fileFees: z.number().max(300).min(0),
	splittingOption: splitOptionSchema,
	deductible: deductibleSchema,
	establishments: pricingInputEstablishmentSchema,
});

export type PricingInputDto = z.infer<typeof PricingInputSchema>;

// Conversion function for status and subscription
export function convertStatusToFrench(status: OccupationStatus): string {
	return status;
}

export function convertSubscriptionToFrench(
	subscription: SubscriptionEnum,
): string {
	return subscription;
}

export const PricingToSheetInputSchema = PricingInputSchema.transform(
	(data: PricingInputDto) => {
		const activities: ActivityPricingInputDto[] = data.establishments;

		for (const activity of activities) {
			activity.nafCode = data.nafCode;
			activity.creationDate = data.companyCreationDate;
		}

		const transformedActivities: ActivityPricingSheetInputDto[] = [];

		activities.forEach((activity) => {
			transformedActivities.push(
				activityPricingSheetInputSchema.parse(activity),
			);
		});

		return {
			deductible: parseInt(data.deductible),
			risks: transformedActivities,
		};
	},
);
