import type { GuaranteeOptionalType } from "@/constants";
import type { EstablishmentDetailsPageDto } from "@/pages/form/schemas";
import { useEffect, useMemo, useState } from "react";
import PropertyDamageIcon from "@/assets/PropertyDamageIcon.svg";
import { CustomSpinner } from "@/components/CustomSpinner";
import {
	Accordion,
	AccordionContent,
	AccordionItem,
	AccordionTrigger,
} from "@/components/ui/accordion";
import { Card, CardContent, CardHeader } from "@/components/ui/card";
import { PROPERTY_DAMAGE_AMOUNT_TYPES } from "@/constants";
import { useCustomFormNavigate } from "@/hooks/customNavigate";
import { calculatePJGuaranteeValue } from "@/lib/CalculatePJ";
import { categorizeGuarantees } from "@/lib/CategorizeGuarantees";
import { CreateSchemasGuaranteesAmount } from "@/lib/CreateSchemasGuaranteesAmount";
import { getPlaceholder } from "@/lib/GuaranteePlaceholder";
import { formStore } from "@/pages/form/stores/formStore";
import { cn, onKeyDownPreventDefaultIfEnter, trpc } from "@/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormProvider, useForm } from "react-hook-form";

import type {
	ActivityPageData,
	ActivityWithPrincipal,
	GuaranteeAmountsPageDto,
} from "@repos/mrp-dtos/src/dtos/guarantees.dto.ts";

import GuaranteePriceInput from "../../components/GuaranteePriceInput";

const LIABILITY_TYPES = [
	"RCE",
	"RCPRO",
	"PE_INCENDIE",
	"PVF",
	"VANDALISME",
	"PE_VOL",
] as const;

export const GuaranteeAmountsPage = () => {
	const [openItems, setOpenItems] = useState<string[]>(["item-1"]);
	const [isLoading, setIsLoading] = useState(true);

	const navigate = useCustomFormNavigate();

	const { activityPage, chosenOptions, setChosenOptions, establishments } =
		formStore((state) => ({
			activityPage: state.activityPage as ActivityPageData,
			chosenOptions: state.chosenOptions,
			setChosenOptions: state.setChosenOptions,
			establishments: state.establishments,
		}));

	const turnover = establishments[0]?.turnover || 0;

	const { principalActivity, secondaryActivities } = activityPage;

	const allActivities = useMemo<ActivityWithPrincipal[]>(() => {
		const activities: ActivityWithPrincipal[] = [];
		activities.push({ ...principalActivity, isPrincipal: true });
		if (secondaryActivities) {
			secondaryActivities.forEach((activity) => {
				activities.push({
					...activity,
					isPrincipal: false,
				});
			});
		}
		return activities;
	}, [principalActivity, secondaryActivities]);

	const schema = useMemo(
		() =>
			CreateSchemasGuaranteesAmount(
				establishments as EstablishmentDetailsPageDto,
			),
		[chosenOptions, establishments],
	);

	const defaultValues: GuaranteeAmountsPageDto = useMemo(() => {
		return {
			chosenOptions: {
				chosenOptions:
					chosenOptions.chosenOptions?.map((currentActivityOption) => {
						const { propertyDamage, liability } = categorizeGuarantees(
							currentActivityOption.chosenOptions,
						);

						const existingLiabilityOptions =
							currentActivityOption.chosenOptions.filter((opt) =>
								LIABILITY_TYPES.includes(
									opt.guaranteeType as (typeof LIABILITY_TYPES)[number],
								),
							);

						return {
							activityId: currentActivityOption.activityId,
							establishmentId: currentActivityOption.establishmentId,
							chosenOptions: [
								...propertyDamage.map((guarantee) => ({
									guaranteeType:
										guarantee.guaranteeType as GuaranteeOptionalType,
									guaranteeValue:
										guarantee.guaranteeType === "PJ"
											? calculatePJGuaranteeValue(turnover)
											: guarantee.guaranteeType === "SFM"
												? "6 fois l'indice FFB"
												: guarantee.guaranteeType === "EFFONDREMENT"
													? "1500000"
													: (guarantee.guaranteeValue ?? ""),
								})),
								...liability
									.filter((guarantee) => {
										const existing = existingLiabilityOptions.find(
											(opt) => opt.guaranteeType === guarantee.guaranteeType,
										);
										return !existing;
									})
									.map((guarantee) => ({
										guaranteeType:
											guarantee.guaranteeType as GuaranteeOptionalType,
										guaranteeValue: guarantee.guaranteeValue,
									})),
							],
						};
					}) ?? [],
			},
		};
	}, [chosenOptions, turnover]);

	const methods = useForm<GuaranteeAmountsPageDto>({
		resolver: zodResolver(schema),
		defaultValues,
	});

	const { errors } = methods.formState;
	useEffect(() => {
		const hasMatchingGuarantee = chosenOptions.chosenOptions?.some((activity) =>
			activity.chosenOptions.some((option) =>
				PROPERTY_DAMAGE_AMOUNT_TYPES.includes(option.guaranteeType),
			),
		);

		if (!hasMatchingGuarantee) {
			navigate("QuotesPage");
		} else {
			setIsLoading(false);
		}
	}, []);
	const onSubmit = (data: GuaranteeAmountsPageDto) => {
		const updatedOptions = {
			chosenOptions: data.chosenOptions.chosenOptions.map((activityOption) => {
				const currentActivityData = chosenOptions.chosenOptions?.find(
					(opt) =>
						opt.activityId === activityOption.activityId &&
						opt.establishmentId === activityOption.establishmentId,
				);

				const propertyDamageOptions = activityOption.chosenOptions;
				const liabilityOptions =
					currentActivityData?.chosenOptions.filter((opt) =>
						LIABILITY_TYPES.includes(
							opt.guaranteeType as (typeof LIABILITY_TYPES)[number],
						),
					) ?? [];

				return {
					activityId: activityOption.activityId,
					establishmentId: activityOption.establishmentId,
					chosenOptions: [
						...propertyDamageOptions.map((option) => ({
							guaranteeType: option.guaranteeType,
							guaranteeValue: Number(option.guaranteeValue) || 0,
						})),
						...liabilityOptions.map((option) => ({
							guaranteeType: option.guaranteeType,
							guaranteeValue: Number(option.guaranteeValue) || 0,
						})),
					],
				};
			}),
		};
		setChosenOptions(updatedOptions);
		navigate("QuotesPage");
	};

	const activitiesQuery =
		trpc.activities.getMultipleActivityGuaranteesDetails.useQuery({
			activityIds: allActivities.map((activity) => activity.id),
		});

	const getGuaranteeLabel = (
		activityId: string,
		guaranteeType: string,
	): string => {
		if (!activitiesQuery.data) return guaranteeType;
		const activityData = activitiesQuery.data.find(
			(data) => data.id === activityId,
		);

		if (!activityData?.guaranteeRiskClass) return guaranteeType;

		const guarantee = activityData.guaranteeRiskClass.find(
			(g) => g.guaranteeType === guaranteeType,
		);
		return guarantee?.Guarantee.label || guaranteeType;
	};

	useEffect(() => {
		// If there are form state errors, open all accordion items
		if (Object.keys(errors).length > 0) {
			const allItemKeys = allActivities.map((_, index) => `item-${index + 1}`);
			setOpenItems(allItemKeys);
			window.scroll(0, 200);
		}
	}, [errors, allActivities]);

	if (isLoading || activitiesQuery.isLoading) {
		return <CustomSpinner />;
	}

	return (
		<FormProvider {...methods}>
			<form
				onSubmit={methods.handleSubmit(onSubmit)}
				className="flex w-full flex-col gap-12"
				id="form"
				onKeyDown={onKeyDownPreventDefaultIfEnter}
			>
				<div className="flex flex-col items-start justify-between">
					<h1>Montants des garanties pour chaque activité</h1>
					<p>Veuillez renseigner les montants des garanties à souscrire.</p>
				</div>
				<Card className="flex w-full flex-col gap-4 rounded-2xl border-none bg-white shadow-none">
					<Accordion
						type="multiple"
						className="w-full p-0"
						value={openItems}
						onValueChange={setOpenItems}
					>
						{chosenOptions.chosenOptions?.map(
							(currentActivityOption, activityIndex) => {
								const activity = allActivities.find(
									(a) => a.id === currentActivityOption.activityId,
								);
								const establishmentId = currentActivityOption.establishmentId;
								const activityId = currentActivityOption.activityId;
								if (!activity || !currentActivityOption.chosenOptions.length)
									return null;

								const { propertyDamageAmount, liability } =
									categorizeGuarantees(currentActivityOption.chosenOptions);

								return (
									<AccordionItem
										key={activityId + establishmentId}
										value={`item-${activityIndex + 1}`}
										className="mb-4 w-full border-none"
									>
										<AccordionTrigger
											className={cn(
												"flex w-full flex-row items-center justify-between rounded-2xl rounded-b-none py-0 no-underline",
												activity.isPrincipal ? "bg-[#023048]" : "bg-[#F8F5F5]",
											)}
										>
											<CardHeader
												className={cn(
													"flex w-full flex-row items-center justify-between rounded-2xl rounded-b-none no-underline",
													activity.isPrincipal
														? "bg-[#023048]"
														: "bg-[#F8F5F5]",
												)}
											>
												<div className="company-infos flex items-center gap-4 no-underline">
													<div className="flex flex-col gap-1 text-left no-underline">
														<p
															className={cn(
																"text-lg font-bold no-underline",
																activity.isPrincipal
																	? "text-white"
																	: "text-[#333333]",
															)}
														>
															{activity.isPrincipal
																? "Activité Principale "
																: `Activité Secondaire `}
															: {activity.label}
														</p>
														<p
															className={cn(
																"text-sm no-underline",
																activity.isPrincipal
																	? "text-white"
																	: "text-[#595959]",
															)}
														>
															{activity.code} - CA :{" "}
															{activity.turnover.toLocaleString()}€
														</p>
													</div>
												</div>
											</CardHeader>
										</AccordionTrigger>
										<AccordionContent className="p-0">
											<CardContent className="border border-[#E4E4E4] p-0">
												{propertyDamageAmount.length > 0 && (
													<>
														<div className="flex w-full flex-row gap-3 border-b border-[#E4E4E4] bg-[#FCFCFC] px-4 py-6">
															<img src={PropertyDamageIcon} alt="" />
															<p className="text-lg font-semibold">
																Dommages aux biens
															</p>
														</div>
														<div className="flex w-full flex-wrap bg-white px-4 py-6">
															{propertyDamageAmount.map(
																(guarantee, guaranteeIndex) => {
																	const isPJGuarantee =
																		guarantee.guaranteeType === "PJ";
																	const isSFMGuarantee =
																		guarantee.guaranteeType === "SFM";
																	const isEFFONDREMENTGuarantee =
																		guarantee.guaranteeType === "EFFONDREMENT";

																	const isDisabled =
																		isPJGuarantee ||
																		isSFMGuarantee ||
																		isEFFONDREMENTGuarantee;

																	return (
																		<div
																			key={guarantee.guaranteeType}
																			className="mb-4 flex w-full items-center space-x-2 lg:w-1/2"
																		>
																			<div className="flex w-full flex-col pr-4">
																				<GuaranteePriceInput
																					name={`chosenOptions.chosenOptions.${activityIndex}.chosenOptions.${guaranteeIndex}.guaranteeValue`}
																					label={getGuaranteeLabel(
																						currentActivityOption.activityId,
																						guarantee.guaranteeType,
																					)}
																					control={methods.control}
																					placeholder={getPlaceholder(
																						guarantee.guaranteeType,
																					)}
																					required
																					defaultValue={
																						guarantee.guaranteeValue
																					}
																					disabled={isDisabled}
																					endorment="€"
																					isOnlyPlaceholder={
																						isPJGuarantee || isSFMGuarantee
																					}
																				/>
																			</div>
																		</div>
																	);
																},
															)}
														</div>
													</>
												)}
												{liability.filter(
													(guarantee) => guarantee.guaranteeType === "PJ",
												).length > 0 && (
													<>
														<div className="flex w-full flex-row gap-3 border-b border-[#E4E4E4] bg-[#FCFCFC] px-4 py-6">
															<img src={PropertyDamageIcon} alt="" />
															<p className="text-lg font-semibold">
																Responsabilité civile – Défense et Protection
																Juridique
															</p>
														</div>
														<div className="flex w-full flex-wrap bg-white px-4 py-6">
															{liability
																.filter(
																	(guarantee) =>
																		guarantee.guaranteeType === "PJ",
																)
																.map((guarantee, guaranteeIndex) => {
																	const isPJGuarantee =
																		guarantee.guaranteeType === "PJ";

																	const isDisabled = isPJGuarantee;

																	return (
																		<div
																			key={guarantee.guaranteeType}
																			className="mb-4 flex w-full items-center space-x-2 lg:w-1/2"
																		>
																			<div className="flex w-full flex-col pr-4">
																				<GuaranteePriceInput
																					name={`chosenOptions.chosenOptions.${activityIndex}.chosenOptions.${guaranteeIndex}.guaranteeValue`}
																					label={getGuaranteeLabel(
																						currentActivityOption.activityId,
																						guarantee.guaranteeType,
																					)}
																					control={methods.control}
																					placeholder={getPlaceholder(
																						guarantee.guaranteeType,
																					)}
																					required
																					defaultValue={
																						guarantee.guaranteeValue
																					}
																					disabled={isDisabled}
																					endorment="€"
																					isOnlyPlaceholder={isPJGuarantee}
																				/>
																			</div>
																		</div>
																	);
																})}
														</div>
													</>
												)}
											</CardContent>
										</AccordionContent>
									</AccordionItem>
								);
							},
						)}
					</Accordion>
				</Card>
			</form>
		</FormProvider>
	);
};

export default GuaranteeAmountsPage;
