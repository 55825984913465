import { HeaderFooter } from "@/components/quotePdf/HeaderFooter.tsx";
import { styles } from "@/components/quotePdf/styles.ts";
import { Page, Text, View } from "@react-pdf/renderer";
import { formatDate } from "date-fns";

import type { SubscriptionDetailsForQuoteDto } from "@repos/mrp-dtos";

import { formatSiren } from "../../../utils/utils";

export const Page1 = ({ ...props }: SubscriptionDetailsForQuoteDto) => {
	return (
		<Page size="A4" style={styles.page}>
			<HeaderFooter />
			<View style={styles.container}>
				<Text style={styles.sectionHeader}>
					DEVIS MRP ACCELERANT N° {props.quoteNumber}
				</Text>
				<View style={{ display: "flex", flexDirection: "column", gap: 10 }}>
					<Text style={[styles.bodyText, styles.medium]}>
						L’offre qui vous est proposée est valable pour une durée de 2 mois
						sous réserve que les informations fournies ne soient pas modifiées
						lors de la souscription du contrat
					</Text>
					<Text style={[styles.bodyText, styles.medium]}>
						Contrat d’un an avec tacite reconduction. Il peut être résilié
						chaque année moyennant un préavis de trois mois avant le{" "}
						{formatDate(props.dueDate, "dd/MM")}, date anniversaire du contrat.
					</Text>
					<Text style={[styles.bodyText, styles.medium]}>
						Date d’effet : {formatDate(props.effectiveDate, "dd/MM/yyyy")}
					</Text>
				</View>
				<Text style={[styles.sectionHeader, styles.mt_20]}>
					DEVIS SOUSCRIT PAR L’INTERMÉDIAIRE DU CABINET DE COURTAGE
				</Text>
				<View style={{ display: "flex", flexDirection: "column", gap: 10 }}>
					<View>
						<Text style={[styles.bodyText, styles.max1Line]}>
							<Text style={[styles.bodyText, styles.bold]}>• Cabinet :</Text>{" "}
							{props.cabinet.name}
						</Text>
						<Text style={[styles.bodyText, styles.max1Line]}>
							<Text style={[styles.bodyText, styles.bold]}>• N° ORIAS :</Text>{" "}
							{props.cabinet.orias}
						</Text>
						<Text style={[styles.bodyText, styles.max1Line]}>
							<Text style={[styles.bodyText, styles.bold]}>
								• Référence cabinet :
							</Text>{" "}
							{props.cabinet.orias}
						</Text>
						<Text style={[styles.bodyText, styles.max1Line]}>
							<Text style={[styles.bodyText, styles.bold]}>
								• Nom du vendeur :
							</Text>{" "}
							{props.cabinet.vendorName}
						</Text>
						<Text style={[styles.bodyText, styles.max1Line]}>
							<Text style={[styles.bodyText, styles.bold]}>• Adresse :</Text>{" "}
							{`${props.cabinet.address.fullAddress} ${props.cabinet.address.fullAddress.length > 0 ? "- " : ""}${props.cabinet.address.postalCode} ${props.cabinet.address.city} `}
						</Text>
					</View>
					<Text style={[styles.bodyText, styles.mt_10]}>
						{`Le courtier intermédiaire s'engage à satisfaire aux obligations légales et conserve la charge de l'obligation d'information et de conseils auprès des prospects ou assurés.`}
					</Text>
				</View>
				<Text style={[styles.sectionHeader, styles.mt_20]}>SOUSCRIPTEUR</Text>
				<View>
					<Text style={[styles.bodyText, styles.max1Line]}>
						<Text style={[styles.bodyText, styles.bold]}>
							• Raison sociale :
						</Text>{" "}
						{props.companyInfo.companyName}
					</Text>
					<Text style={[styles.bodyText, styles.max1Line]}>
						<Text style={[styles.bodyText, styles.bold]}>• SIREN :</Text>{" "}
						{formatSiren(props.companyInfo.siren)}
					</Text>
					<Text style={[styles.bodyText, styles.max1Line]}>
						<Text style={[styles.bodyText, styles.bold]}>
							• Date de création de l’entreprise :
						</Text>{" "}
						{formatDate(props.companyInfo.creationDate, "dd/MM/yyyy")}
					</Text>
					<Text style={[styles.bodyText, styles.max1Line]}>
						<Text style={[styles.bodyText, styles.bold]}>
							• Forme juridique :
						</Text>{" "}
						{props.companyInfo.legalStatusLabel}
					</Text>
					<Text style={[styles.bodyText, styles.max1Line]}>
						<Text style={[styles.bodyText, styles.bold]}>
							• Représentant légal :
						</Text>{" "}
						{props.companyInfo.legalRepresentativeName}
					</Text>
					<Text style={[styles.bodyText, styles.max1Line]}>
						<Text style={[styles.bodyText, styles.bold]}>
							• En qualité de :
						</Text>{" "}
						{props.companyInfo.legalRepresentativeTitle}
					</Text>
					<Text style={[styles.bodyText, styles.max1Line]}>
						<Text style={[styles.bodyText, styles.bold]}>• Adresse :</Text>{" "}
						{`${props.companyInfo.address.fullAddress} ${props.companyInfo.address.postalCode} ${props.companyInfo.address.city} `}
					</Text>
					<Text style={[styles.bodyText, styles.max1Line]}>
						<Text style={[styles.bodyText, styles.bold]}>• Activité :</Text>
					</Text>
					<View style={{ paddingLeft: 20 }}>
						<Text style={[styles.bodyText, styles.max1Line]}>
							<Text style={[styles.bodyText, styles.bold]}>- Code Naf :</Text>{" "}
							{props.companyInfo.principalActivity.nafCode} |{" "}
							{props.companyInfo.principalActivity.nafCodeLabel}
						</Text>
						<Text style={[styles.bodyText, styles.max1Line]}>
							<Text style={[styles.bodyText, styles.bold]}>
								- Domaine d’activité :
							</Text>{" "}
							{props.companyInfo.principalActivity.familyLabel}
						</Text>
						<Text style={[styles.bodyText, styles.max1Line]}>
							<Text style={[styles.bodyText, styles.bold]}>
								- Activité principale déclarée :
							</Text>{" "}
							{props.companyInfo.principalActivity.principalActivityLabel}
						</Text>
					</View>
					<Text style={[styles.bodyText, styles.mt_10]}>
						Pour l’assurance Multirisque professionnelle de votre société, vous
						reconnaissez avoir apporté les réponses ci-dessous aux questions qui
						vous ont été posées, préalablement à la souscription.
					</Text>
				</View>
			</View>
		</Page>
	);
};
