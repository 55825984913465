import { useMemo } from "react";
import { addSpacingBetweenThousandsToNumberWithDecimals } from "@/utils";

import type { PricingResponseDto, SplittingOption } from "@repos/mrp-dtos";

interface PricingSummaryTableProps {
	pricingData?: PricingResponseDto;
	splittingOption: SplittingOption;
	splittingOptionDivisor: 12 | 2 | 4 | 1;
}

function splittingOptionToString(splittingOption: SplittingOption) {
	switch (splittingOption) {
		case "Monthly":
			return "Mensuelle";
		case "Quarterly":
			return "Trimestrielle";
		case "HalfYearly":
			return "Semestrielle";
		case "Yearly":
			return "Annuelle";
		default:
			return "Mensuelle";
	}
}

export const PricingSummaryTable = ({
	pricingData,
	splittingOption,
}: PricingSummaryTableProps) => {
	const rows = useMemo(() => {
		const sourceData = pricingData;
		if (!sourceData) return [];

		const allRows = [
			{
				label: "Prime Annuelle HT :",
				value: addSpacingBetweenThousandsToNumberWithDecimals(
					sourceData.beforeTaxPremium,
				),
			},
			{
				label: "Frais de Quittancement :",
				value: addSpacingBetweenThousandsToNumberWithDecimals(
					sourceData.releaseFees,
				),
			},
			{
				label: "Frais de Gestion As Solutions :",
				value: addSpacingBetweenThousandsToNumberWithDecimals(
					sourceData.managementFees,
				),
			},
			{
				label: "Dont Frais Contribution Attentat :",
				value: addSpacingBetweenThousandsToNumberWithDecimals(
					sourceData.attackPackage,
				),
			},
			{
				label: "Prime Annuelle TTC :",
				value: addSpacingBetweenThousandsToNumberWithDecimals(
					sourceData.totalInsurancePremium +
					sourceData.managementFees +
					sourceData.releaseFees,
				),
			},
			{
				label: `Prime ${splittingOptionToString(splittingOption)} TTC :`,
				value: addSpacingBetweenThousandsToNumberWithDecimals(
					sourceData.otherWithdrawals,
				),
				id: "conditional",
			},
			{
				label: "Commission Courtier :",
				value: addSpacingBetweenThousandsToNumberWithDecimals(
					sourceData.brokerCommission,
				),
			},
			{
				label: "Frais de Dossier Courtier à la Souscription (non récurrent) :",
				value: addSpacingBetweenThousandsToNumberWithDecimals(
					sourceData.fileFees,
				),
			},
			{
				label: "Prime à payer à la Souscription :",
				value: addSpacingBetweenThousandsToNumberWithDecimals(
					sourceData.firstWithdrawal,
				),
			},
		];
		return allRows.filter(
			(row) =>
				!(splittingOption === "Yearly" && row.id?.includes("conditional")),
		);
	}, [pricingData, splittingOption]);

	return (
		<table className="mt-4 w-full rounded-sm">
			<tbody>
				{rows.map((row, index) => (
					<tr
						key={index}
						className={index % 2 === 0 ? "bg-[#F2F4F6]" : "bg-white"}
					>
						<td className="p-3">{row.label}</td>
						<td className="p-3 text-right font-semibold tracking-wide">
							{row.value} €
						</td>
					</tr>
				))}
			</tbody>
		</table>
	);
};
